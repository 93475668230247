/**************************\
  Basic modal Styles
\**************************/

@use 'config' as *;
@use 'utility' as *;

.micromodal {
    display: none;

    &.is-open {
        display: block;
    }
}

.micromodal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba($colour-black, 0.6);
}

.micromodal__container {
    min-width: 80vw;
    max-width: 760px;
    max-height: 90vh;
    overflow-y: auto;
    background-color: $colour-white;
    border-radius: $button-border-radius;
}

.micromodal__header {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: $spacing-s;
    padding: $spacing-m;
    background-color: $colour-dark-chambray;
    color: $colour-white;
}

.micromodal__title {
    @include heading-small;
    display: flex;
    align-items: center;
    gap: $spacing-m;
}

.micromodal__close {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 0;
    height: 44px;
    width: 44px;
}

.micromodal__content {
    padding: $spacing-m;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.8);
}

.micromodal__footer {
    display: flex;
    justify-content: space-between;
    padding: 0 $spacing-m $spacing-m;
    flex-wrap: wrap;
}

/**************************\
    Animation Style
\**************************/

@keyframes mmfadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes mmfadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}



.micromodal[aria-hidden="false"] .micromodal__overlay {
    animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal[aria-hidden="false"] .micromodal__container {
    animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal[aria-hidden="true"] .micromodal__overlay {
    animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal[aria-hidden="true"] .micromodal__container {
    animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal .micromodal__container,
.micromodal .micromodal__overlay {
    will-change: transform;
}
