@use 'config' as *;
@use 'utility' as *;

$input-label-colour: $colour-black;

.o-label {
    @include label-text;

    color: $input-label-colour;
}

.o-label__required-flag {
    font-weight: normal;
    color: $colour-mine-shaft;
}
