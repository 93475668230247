//
// 2fa Status
// ==========================================
@use 'config' as *;
@use 'utility' as *;

@media (max-width: $screen-xs) {
    .c-2fa-status {
        padding-top: 1rem;
    }
}
