//
// Trusted device list
// ==========================================

@use 'config' as *;
@use 'utility' as *;

.c-2fa-trusted-device-list {
    padding: 0 1rem;
}

@media (max-width: $screen-sm) {
    .c-2fa-trusted-device-list {
        padding: 0 0.5rem;
    }
}

.c-2fa-trusted-device-list > div {
    border-bottom: solid 1px #dfdfdf;
}

.c-2fa-trusted-device-list div > div {
    padding: 1rem 0;
}

.c-2fa-trusted-device-list div:last-child {
    border-bottom: none;
}
