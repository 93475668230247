@use 'config' as *;
@use 'utility' as *;

.product-content {
    background-color: $colour-white;

    @include breakpoint(md-plus) {
        width: 66%;
    }
}

.product-content__purchase {
    @include breakpoint(md-plus) {
        width: 33%;
    }
}

.product-content__header {
    padding: $spacing-m 0;
    margin: 0 $spacing-m;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: $spacing-s;

    @include breakpoint(sm-plus) {
        padding: $spacing-l 0;
        min-height: 200px;
    }
}

.product-content__header-title {
    @include heading-large;

    margin-top: 0;
}

.product-content__header_subheader {
    @include heading-small;
}

.product-content__favourite-button > span {
    margin-right: 10px;
}

.product-content__categories-items {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-s;
}

.product-content__where-to-spend-container {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    @include breakpoint(md-plus) {
        gap: 16px;
    }
}

