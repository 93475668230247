//
// Cashback Accordion
// ==========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.c-additional-info-accordion {
    &__body {
        display: none;
        overflow: hidden;
        font-size: 0.875rem;
    }

    &__body ol {
        padding-left: 30px;
    }

    &__link {
        text-decoration: none;
        font-size: 0.875rem;
        position: relative;
        outline: 0;
        cursor: pointer;
    }
    /* stylelint-disable max-nesting-depth */
    .new-app & {
        .c-additional-info-accordion__link {
            @include body-text-medium;
            position: relative;
            display: block;
            font-weight: 600;
            color: $colour-mine-shaft;
            padding-left: $spacing-m;

            &::before {
                position: absolute;
                left: 4px;
                top: 0;
                content: '\203A';
                transform: rotate(90deg);
                font-size: 24px;
            }

            &.is-active {
                &::before {
                    transform: rotate(-90deg);
                }
            }
        }
    }
    /* stylelint-enable */
}

