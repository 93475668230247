@use 'config' as *;
@use 'utility' as *;

.out-of-stock-widget {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: $font-size-xl;
  gap: $spacing-s;
}

.out-of-stock-widget__text {
  font-weight: $semi-bold-font-weight;
  font-size: $font-size-large;
}

.out-of-stock-widget__icon {
  color: $colour-error-text;
  top: -1px;
  margin-right: $spacing-xs;
  padding-top: $spacing-s;
  padding-bottom: $spacing-s;
}
