/* Table */

@use 'config' as *;
@use 'utility' as *;

.c-e-wallet-table {
    display: flex;
    flex-wrap: wrap;

    background-color: $colour-concrete;
    margin-bottom: 1rem;
}

/* Headers */

.c-e-wallet-table__header-retailer {
    color: #565a5c;
    display: flex;
    flex-direction: column;
    width: 51%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;

    cursor: pointer;

    &:hover {
        background-color: rgba(36, 115, 154, 0.1);
        box-shadow: -8px 0 0 0 rgba(36, 115, 154, 0.1);
        text-decoration: none;
    }

    &:focus {
        text-decoration: none;
    }

    @media (min-width: $screen-xs-min) {
        display: inline;
        width: 37%;
    }
}

.c-e-wallet-table__header-type {
    color: #565a5c;
    display: none;
    width: 20%;
    padding-top: 0.5rem;
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;

    cursor: pointer;

    &:hover {
        background-color: rgba(36, 115, 154, 0.1);
        text-decoration: none;
    }

    &:focus {
        text-decoration: none;
    }

    @media (min-width: $screen-xs-min) {
        display: inline;
        width: 26%;
    }
}

.c-e-wallet-table__header-date-added {
    color: #565a5c;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 49%;
    padding-top: 0.5rem;
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;

    cursor: pointer;

    &:hover {
        background-color: rgba(36, 115, 154, 0.1);
        text-decoration: none;
    }

    &:focus {
        text-decoration: none;
    }

    @media (min-width: $screen-xs-min) {
        display: inline;
        width: 37%;
    }
}

.c-e-wallet-table__header-title-retailer {
    color: #565a5c;
    font-size: $font-size-small;
    font-weight: bold;
}

.c-e-wallet-table__header-title {
    color: #565a5c;
    font-size: $font-size-small;
    font-weight: normal;

    @media (min-width: $screen-xs-min) {
        font-weight: bold;
    }
}

.c-e-wallet-table__header-subtitle {
    color: #565a5c;
    font-size: $font-size-small;
    font-weight: normal;

    @media (min-width: $screen-xs-min) {
        display: none;
    }
}

.c-ewallet-table__header-icon {
    display: none;

    @media (min-width: $screen-xs-min) {
        display: inline;
        margin-left: 0.5rem;
    }
}

/* Mixin */

@mixin u-e-wallet-table-cell {
    display: flex;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 1px solid rgb(216, 216, 216);
    border-bottom: 1px solid rgb(216, 216, 216);
}

@mixin u-e-wallet-table-cell-title {
    margin: 0;
    margin-bottom: 0.25rem;

    font-size: $font-size-base;
    color: #565a5c;
}

/* Retailer cell */

.c-e-wallet-table__cell-retailer {
    @include u-e-wallet-table-cell;
    width: 51%;

    @media (min-width: $screen-xs-min) {
        width: 37%;
    }
}

.c-e-wallet-table__media {
    display: flex;
    align-items: center;
    width: 100%;
}

.c-e-wallet-table__media-img-container {
    width: 24px;
    height: 24px;
    margin-right: 1rem;

    background-color: $colour-white;

    &.compat-object-fit {
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;

        .c-e-wallet-table__media-img {
            opacity: 0;
        }
    }

    @media (min-width: $screen-xs-min) {
        width: 40px;
        height: 40px;
    }

    @media (min-width: $screen-sm-min) {
        width: 60px;
        height: 60px;
    }
}

.c-e-wallet-table__media-img {
    width: 24px;
    height: 24px;

    object-fit: contain;

    @media (min-width: $screen-xs-min) {
        width: 40px;
        height: 40px;
    }

    @media (min-width: $screen-sm-min) {
        width: 60px;
        height: 60px;
    }
}

.c-e-wallet-table__media-body {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.c-e-wallet-table__cell-retailer-title {
    @include u-e-wallet-table-cell-title;
    font-weight: bold;
}

.c-e-wallet-table__cell-subtitle {
    margin: 0;

    font-size: $font-size-small;
    font-weight: normal;
    color: #565a5c;
}

.c-e-wallet-table__cell-subtitle-retailer {
    display: block;
    margin: 0;

    font-size: $font-size-small;
    font-weight: normal;
    color: #565a5c;

    @media (min-width: $screen-xs-min) {
        display: none;
        width: 33.33%;
    }
}

.c-e-wallet-table__cell-subtitle--link {
    color: $link-color;
    border-color: red;
    text-decoration: underline;
}

/* Type cell */

.c-e-wallet-table__cell-type {
    display: none;
    width: 20%;
    padding-left: 0.5rem;

    @media (min-width: $screen-xs-min) {
        @include u-e-wallet-table-cell;
        width: 26%;
    }
}

.c-e-wallet-table__cell-type-title {
    margin: 0;

    font-weight: normal;
    font-size: $font-size-small;
    color: #565a5c;

    @media (min-width: $screen-xs-min) {
        font-size: $font-size-base;
    }
}

.c-e-wallet-table-cell-type-icon {
    display: none;

    @media (min-width: $screen-sm-min) {
        display: inline;
        margin-right: 0.5rem;
    }
}

/* Date added cell */

.c-e-wallet-table__cell-date-added {
    @include u-e-wallet-table-cell;
    width: 49%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-left: 0.5rem;

    @media (min-width: $screen-xs-min) {
        width: 37%;
        align-items: center;
    }
}

.c-e-wallet-table__cell-title-text {
    margin: 0;

    font-weight: normal;
    font-size: $font-size-small;
    color: #565a5c;

    @media (min-width: $screen-xs-min) {
        font-size: $font-size-base;
    }
}

.c-e-wallet-table__cell-date-added-icon {
    align-self: center;
    font-size: $font-size-xl;
    padding: 0.625rem;

    @media (min-width: $screen-sm-min) {
        display: none;
    }
}

.c-e-wallet-table__buttons {
    display: none;

    @media (min-width: $screen-sm-min) {
        display: block;
    }
}

.e-wallet-table__usage-form {
    display: inline-block;
}

.c-e-wallet-table__button {
    min-width: auto;

    // Add a border to primary buttons
    // so they are the same height as transparent buttons.
    &.btn.btn-primary {
        border-width: 1px;
        border-style: solid;
    }

    // Remove margins from button icons.
    &.btn i {
        margin: 0;
    }
}

.c-e-wallet-table__button + .c-e-wallet-table__button {
    margin-left: 1rem;
}

.c-e-wallet-table__button-view-text {
    display: none;

    @media (min-width: $screen-lg-min) {
        display: inline;
        margin-right: 1em;
    }
}
