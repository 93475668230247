@use 'config' as *;
@use 'utility' as *;

.product-retailer {
    display: flex;
    align-items: flex-start;
    gap: $spacing-m;
    flex-direction: column;
    padding: $spacing-m 0;
    margin: 0 $spacing-m;
    border-top: 1px solid $colour-dark-grey;

    @include breakpoint(sm-plus) {
        flex-direction: row;
        padding: $spacing-l 0;
    }
}

.product-retailer__title {
    @include heading-small;

    margin-bottom: $spacing-xs;
}

.product-retailer__image {
    border: 1px solid $colour-dark-grey;

    @include breakpoint(sm-neg) {
        width:200px;
    }

    @include breakpoint(sm-plus) {
        width: 100px;
    }

    @include breakpoint(md-plus) {
        width: 200px;
    }
}
