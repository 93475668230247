
/**
 * headings
 *
 * To be used as follows:
 * @include u-heading-small;
 *
 */

@use 'config' as *;
@use 'breakpoint' as *;

@mixin heading-small {
    font-family: $font-family-base;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin: 0;

    @include breakpoint(md-plus) {
        font-size: 28px;
        line-height: 32px;
    }
}

@mixin heading-medium {
    font-family: $font-family-base;
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    margin: 0;

    @include breakpoint(md-plus) {
        font-size: 36px;
        line-height: 40px;
    }
}

@mixin heading-large {
    font-family: $font-family-base;
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;
    margin: 0;

    @include breakpoint(md-plus) {
        font-size: 40px;
        line-height: 54px;
    }
}

@mixin heading-xlarge {
    font-family: $font-family-base;
    font-weight: bold;
    font-size: 56px;
    line-height: 64px;
    margin: 0;

    @include breakpoint(md-plus) {
        font-size: 64px;
        line-height: 72px;
    }
}
