@use 'config' as *;
@use 'utility' as *;

.password-input-icon {
  display: flex;
  align-items: center;
  float:right;
  background-color: transparent;
  justify-content: flex-end;
  line-height: 0;
  border: none;

  @include breakpoint(sm-neg) {
    right: 7px;
  }
}

.show-hide-button-text {
  font-size: medium;
  padding-left: 5px;
}
