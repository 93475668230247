//
// Login form
// =========================================

@use 'config' as *;
@use 'utility' as *;

.c-login-form {
    background: #f3f3f3;
    padding: 2rem;
}

.c-login-form__inner {
    margin: 0 auto;
}

.c-login-form__submit-section {
    margin: 2rem 0;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 550px) {
        flex-direction: row;
    }
}

.c-login-form__forgotten-password-section {
    font-size: 0.9rem;
    text-align: center;
}

@media screen and (min-width: $screen-xs-min) {
    .c-login-form__inner {
        max-width: 500px;
    }
}
