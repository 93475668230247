//
// Link Pill
// ==========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.w-link-pill {
    display: flex;
    gap: $spacing-xs;
    flex-wrap: wrap;
}

.o-link-pill {
    @include body-text-medium;

    padding: $spacing-xs $spacing-s;
    background: $colour-light-grey;
    border-radius: $border-radius;
    color: $colour-black;
    font-weight: $semi-bold-font-weight;
    border: 1px solid transparent;

    &:hover {
        text-decoration: none;
        background: $colour-darker-grey;
        color: $colour-white;
    }
}

.o-link-pill:active {
    border: 1px solid $colour-black;
}
