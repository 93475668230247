@use 'config' as *;
@use 'utility' as *;

.s-basket-page {
    padding: 20px 0;
    width: 100%;
    background-color: $colour-white;
}

.d-basket-page__content {
    margin: 0 auto;
    width: 100%;

    @include breakpoint(sm-plus) {
        display: flex;
        gap: $spacing-m;
        max-width: 970px; // taken from bootstrap, ideally should be variables
    }

    @include breakpoint(lg-plus) {
        max-width: 1170px; // taken from bootstrap, ideally should be variables
    }
}

.d-basket-page__table {
    @include breakpoint(md-plus) {
        flex: 1;
    }
}

.d-basket-page__summary {
    margin-top: 20px;

    @include breakpoint(md-plus) {
        margin-top: 0;
        width: 33%;
    }
}

.d-basket-page__table-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    width: 100%;

    @include breakpoint(md-plus) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.d-basket-page__payment-cards {
    width: 50%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 8px;

    @include breakpoint(md-plus) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}
