
/**
 * cards
 *
 * To be used as follows:
 * @include card;
 *
 */
@use 'config' as *;

@mixin card {
    color: $colour-mine-shaft;
    background: $colour-grey-card-background;
    overflow: hidden;
    border: 1px solid $colour-grey;
    border-radius: $spacing-xs;
    box-shadow: 0 2px 8px 0 $colour-grey-drop-shadow;

    &:hover,
    &:focus {
        color: $colour-mine-shaft;
        text-decoration: none;
        box-shadow: 0 8px 15px 0 $colour-grey-drop-shadow;
    }
}
