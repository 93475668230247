/**
 * Vendor Styles
 */
@use './legacy/boostrap.min.css';
@use './legacy/font-awesome.min.css';
@use './legacy/_styles.scss';
@use './vendor/micromodal/micromodal';
@use '@splidejs/splide/css';
@use './common/hide-show-password';
@use './common/external-link-icon';
// @use '/node_modules/pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'; //@todo

/**
 * Layouts
 */
@use 'component/layout/containers/containers';
@use 'component/layout/grid/grid';

/**
 * Global @TODO -> should be objects
 */

@use 'component/global/bullet-list/bullet-list';
@use 'component/global/button/btn';
@use 'component/objects/button/_button';

/**
 * Objects
 */

// @use 'component/objects/button/button';
@use 'component/objects/logo-box/logo-box';
@use 'component/objects/form-elements';
@use 'component/objects/alert/alert';

/**
 * Blocks
 */

@use 'component/blocks/product-panel/_product-panel';
@use 'component/blocks/product-panel-list/_product-panel-list';
@use 'component/blocks/product-list-sort/_product-list-sort';
@use 'component/blocks/autocomplete/_autocomplete';
@use 'component/blocks/bulk-upload-error-list/_bulk-upload-error-list';
@use 'component/blocks/header/_header';
@use 'component/blocks/checkout-address-form/_checkout-address-form';
@use 'component/blocks/checkout-complete-addresses/_checkout-complete-addresses';
@use 'component/blocks/checkout-complete-header/_checkout-complete-header';
@use 'component/blocks/checkout-payment-details-form/_checkout-payment-details-form';
@use 'component/blocks/checkout-progress-indicator/_checkout-progress-indicator';
// sass didn't like the fact it's named 2fa', adding a namespace to fix it.
@use 'component/blocks/2fa-status/_2fa-status' as fa-status;
@use 'component/blocks/2fa-trusted-devices-list/_2fa-trusted-devices-list.scss' as fa-trusted-devices-list;
@use 'component/blocks/2fa-progress-indicator/_2fa-progress-indicator' as fa-progress-indicator;
@use 'component/blocks/2fa-disable-modal/_2fa-disable-modal' as fa-disable-modal;
@use 'component/blocks/copyable-input/_copyable-input';
@use 'component/blocks/copyable-container/_copyable-container';
@use 'component/blocks/file-upload-widget/_file-upload-widget';
@use 'component/blocks/footer/_footer';
@use 'component/blocks/footer-links/_footer-links';
@use 'component/blocks/error-message-splash/_error-message-splash';
// @use 'component/blocks/evoucher-amount-selector/_evoucher-amount-selector';
@use 'component/blocks/generic-table/_generic-table';
@use 'component/blocks/hero-banner/_hero-banner';
@use 'component/blocks/landing-deals/_landing-deals';
@use 'component/blocks/landing-new-deals/_landing-new-deals';
@use 'component/blocks/landing-savings/_landing-savings';
@use 'component/blocks/login-form/_login-form';
@use 'component/blocks/2fa-login-form/_2fa-login-form' as fa-login-form;
@use 'component/blocks/offer-list/_offer-list';
@use 'component/blocks/panel-list/_panel-list';
@use 'component/blocks/panel-list-title/_panel-list-title';
@use 'component/blocks/search-box/_search-box';
@use 'component/blocks/benefit-box/_benefit-box';
@use 'component/blocks/ways-to-save/_ways-to-save';
@use 'component/blocks/categories/_categories';
@use 'component/blocks/user-account-header/_user-account-header';
@use 'component/blocks/onboarding-popup/_onboarding-popup';
@use 'component/blocks/favourite-shop/_favourite-shop';
@use 'component/blocks/title-favourites/_title-favourites';
@use 'component/blocks/my-preferences/_my-preferences';
@use 'component/blocks/flash-message/_flash-message';
@use 'component/blocks/page-intro/_page-intro';
@use 'component/blocks/products-box/_products-box';
@use 'component/blocks/product-view-claim-box/_product-view-claim-box';
@use 'component/blocks/product-view-header/_product-view-header';
@use 'component/blocks/product-view-hero/_product-view-hero';
// @use 'component/blocks/product-widget-container/_product-widget-container';
// @use 'component/blocks/product-widget-costs-summary/_product-widget-costs-summary';
@use 'component/blocks/pill-nav-menu/_pill-nav-menu';
@use 'component/blocks/pagination/_pagination';
@use 'component/blocks/cashback-enquiry-summary-status/_cashback-enquiry-summary-status';
@use 'component/blocks/cashback-enquiry-summary-layout/_cashback-enquiry-summary-layout';
@use 'component/blocks/cashback-enquiry-summary-glossary/_cashback-enquiry-summary-glossary';
@use 'component/blocks/cashback-enquiry-summary-claim/_cashback-enquiry-summary-claim';
@use 'component/blocks/cashback-enquiry-activity/_cashback-enquiry-activity';
@use 'component/blocks/cashback-enquiry-bank-details/_cashback-enquiry-bank-details';
@use 'component/blocks/cashback-enquiry-raise-claim/_cashback-enquiry-raise-claim';
@use 'component/blocks/recaptcha-button/_recaptcha-button';
// @use 'component/blocks/reloadable-card-amount-selector/_reloadable-card-amount-selector';
@use 'component/blocks/terms-and-conditions/_terms-and-conditions';
@use 'component/blocks/marketing-preferences-form/marketing-preferences-form';
@use 'component/blocks/user-my-account/_user-my-account';
@use 'component/blocks/additional-info-accordion/additional-info-accordion';
@use 'component/blocks/checkout-basket-summary/_checkout-basket-summary';
@use 'component/blocks/shopping-voucher-amount-selector/_shopping-voucher-amount-selector';
@use 'component/blocks/simple-accordion/_simple-accordion';
@use 'component/blocks/e-wallet-table/_e-wallet-table';
@use 'component/blocks/e-wallet-modal/_e-wallet-modal';
@use 'component/blocks/faq-list/_faq-list';
@use 'component/blocks/search-bar/_search-bar';
@use 'component/blocks/favourites-bar/_favourites-bar';
@use 'fragments/search-favourites-bar/_search-favourites-bar-fragment';

// NEW AND OVERRIDE STYLES FOR 2022
@use 'component/objects/add-to-favourite/add-to-favourite';
@use 'component/objects/link-pill/_link-pill';
@use 'component/blocks/product-footer/product-footer';
@use 'component/blocks/product-hero/_product-hero';
@use 'component/blocks/product-content/_product-content';
@use 'component/blocks/product-info/product-info';
@use 'component/blocks/product-retailer/product-retailer';
@use 'component/blocks/product-claim-box/_product-claim-box';
@use 'component/blocks/product-widget-container/_product-widget-container-2022';
@use 'component/blocks/evoucher-amount-selector/_evoucher-amount-selector-2022';
@use 'component/blocks/shopping-voucher-amount-selector/_shopping-voucher-amount-selector-2022';
@use 'component/blocks/product-widget-costs-summary/_product-widget-costs-summary-2022';
@use 'component/blocks/where-to-spend/_where-to-spend';
@use 'component/blocks/reloadable-card-amount-selector/_reloadable-card-amount-selector-2022';
@use 'component/blocks/out-of-stock-widget/_out-of-stock-widget';
@use 'component/blocks/product-carousel/_product-carousel';
@use 'component/blocks/skip-to-content/_skip-to-content';
@use 'component/blocks/basket-table/_basket-table';
@use 'component/blocks/basket-summary/_basket-summary';
@use 'component/blocks/payment-cards/_payment-cards';
@use 'component/blocks/user-saved-payment-cards/_saved-payment-cards';
@use 'component/blocks/order-history/_order-history';
@use 'component/blocks/order-history-detail/_order-history-detail';
@use 'component/blocks/order-details/_order-details';
@use 'component/blocks/sso-user-update/sso-user-update';
@use 'component/blocks/slider/slider';
@use 'component/blocks/hot-offers/hot-offers';
@use 'component/blocks/dashboard-carousel/dashboard-carousel';

/**
 * Structure
 */
@use 'component/structures/forms/forms';
@use 'component/structures/product-page/product-page';
@use 'component/structures/basket-page/basket-page';

/**
 * Utility
 */

@use 'component/utility/colour/colour';
@use 'component/utility/text/text';
@use 'component/utility/displaying/displaying';
