@use 'config' as *;
@use 'utility' as *;

.product-footer {
    display: flex;
    gap: $spacing-s;
    align-items: flex-start;
    flex-direction: column;
    padding: $spacing-m;
    background-color: #f6f6f6;

    @include breakpoint(sm-plus) {
        flex-direction: row;
        gap: $spacing-m;
    }

    @include breakpoint(md-plus) {
        padding: $spacing-l 0;
    }
}

.product-footer__header {
    @include heading-small;
}

.product-footer__retailer-image {
    border: 1px solid $colour-dark-grey;

    @include breakpoint(sm-plus) {
        width: 100px;
    }

    @include breakpoint(md-plus) {
        width: 200px;
    }
}

.product-footer__content {
    display: flex;
    gap: $spacing-s;
    flex-direction: column;
}
