@use 'config' as *;
@use 'utility' as *;

$input-help-text-colour: $colour-mine-shaft;
$input-error-text-colour: $colour-error-text;
$input-help-link: $input-help-text-colour;

.o-form-msg {
    @include body-text-small;
}

.o-form-msg--help {
    color: $input-help-text-colour;
    text-align: left;

    a {
        color: $input-help-link;
        text-decoration: underline;
    }
}

.o-form-msg--error {
    position: relative;
    color: $input-error-text-colour;
}
