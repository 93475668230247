//
// Mt preferences
// ==========================================

@use 'config' as *;
@use 'utility' as *;

.c-checkbox {
    margin-bottom: 5px;

    input[type=checkbox] {
        box-shadow: none;
        margin-top: 0;
    }

    label {
        margin-left: 30px;
    }
}
