//
// Copyable container
// ==========================================

@use 'config' as *;
@use 'utility' as *;

.c-copyable-container {
    position: relative;
    display: inline-block;
    background-color: lightgray;
    padding: 0.5rem 10rem 0.5rem 0.5rem;
    cursor: pointer;
}

.c-copyable-container__icon {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    color: transparent;
    display: inline-block;
}

.c-copyable-container__icon::after {
    position: absolute;
    right: 0.5rem;
    font-family: FontAwesome;
    color: #333333;
    content: '\f0c5';
    cursor: pointer;
}

.c-copyable-container__icon.is-copied {

}

.c-copyable-container__icon.is-copied::after {
    position: absolute;
    right: 0.5rem;
    content: 'Copied';
    background-color: #009966;
    border-radius: 5px;
    color: $colour-white;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 0.9rem;
    padding: 0.1rem 0.5rem;
    top: 0;
}

@media (max-width: $screen-sm) {
    .c-copyable-container {
        width: 100%;
    }

    .c-copyable-container__icon::after {

    }

    .c-copyable-container__icon.is-copied::after {

    }
}
