
/**
 * headings
 *
 * To be used as follows:
 * @include u-heading-small;
 *
 */

@use 'config' as *;
@use 'breakpoint' as *;

@mixin body-text-large {
    font-family: $font-family-base;
    font-size: 20px;
    line-height: 28px;
    font-weight: normal;
    margin: 0;

    @include breakpoint(md-plus) {
        font-size: 24px;
        line-height: 32px;
    }
}

@mixin body-text-large-bold {
    @include body-text-large;

    font-weight: bold;
}


@mixin body-text-medium {
    font-family: $font-family-base;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    margin: 0;

    @include breakpoint(md-plus) {
        font-size: 18px;
        line-height: 28px;
    }
}

@mixin body-text-medium-bold {
    @include body-text-medium;

    font-weight: bold;
}

@mixin body-text-small {
    font-family: $font-family-base;
    font-size: 14px;
    line-height: 21px;
    font-weight: normal;
    margin: 0;
}

@mixin body-text-small-bold {
    @include body-text-small;

    font-weight: bold;
}

@mixin button-text {
    font-family: $font-family-base;
    font-size: 18px;
    line-height: 18px;
    font-weight: bold;
}

@mixin button-text-large {
    font-family: $font-family-base;
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
}

@mixin input-text {
    font-family: $font-family-base;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
}

@mixin label-text {
    font-family: $font-family-base;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
}
