//
// 2FA Turn Off Modal
// ==========================================

@use 'config' as *;
@use 'utility' as *;

.c-2fa-disable-modal__page-mask {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    z-index: 9;
}

.c-2fa-disable-modal {
    display: none;
    position: absolute;
    top: 20%;
    left: 50%;
    z-index: 10;

    .btn-cancel {
        margin-right: 2rem;
        color: $colour-mine-shaft;
        background-color: #f2f2f2;
    }

    &__container-top-bar {
        position: relative;
        left: -50%;
        padding: 1rem 2rem;
    }

    &__container {
        position: relative;
        left: -50%;
        width: 600px;
        background-color: white;
        padding: 2rem;
    }

    &__container-control-buttons {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-top: 2rem;

        &--mobile {
            display: none;
        }
    }
}

.is-open {
    display: block;
}

@media (max-width: $screen-sm) {
    .c-2fa-disable-modal__container {
        width: 320px;
    }

    .c-2fa-disable-modal__container-control-buttons {
        display: none;
    }

    .c-2fa-disable-modal__container-top-bar {
        h4 {
            font-size: 1rem;
        }
    }

    .c-2fa-disable-modal__container-control-buttons--mobile {
        margin-top: 1rem;
        display: block;

        .btn {
            width: 100%;
            margin-top: 1rem;
        }
    }
}

