//
// Forms
// ==========================================

@use 'config' as *;
@use 'utility' as *;

$_spacing: $spacing-xs;
$_spacing-row: $spacing-m;

.s-form {
    display: flex;
    flex-direction: column;
    gap: $_spacing-row;
}

.s-form__row {
    display: flex;
    flex-direction: column;
    gap: $_spacing;
}

.s-form__label {}

.s-form__legend {
    width: 100%;
    border: 0;
}

.s-form__fieldset {
    display: flex;
    flex-direction: column;
    gap: $_spacing-row;
}
