//
// Login form
// =========================================

@use 'config' as *;
@use 'utility' as *;

.c-2fa-login-form {
    background: #f3f3f3;
    padding: 2rem;
}

.c-2fa-login-form__inner {
    margin: 0 auto;
    max-width: 400px;

    label {
        margin-top: 0.15rem;
    }

    input[type=checkbox] {
        margin-right: 0.5rem;
    }
}

.c-2fa-login-form__submit-section {
    margin: 2rem 0;
    text-align: center;
}

.c-2fa-login-form__help-text-section {
    font-size: 0.9rem;
    text-align: center;
}

@media screen and (max-width: $screen-sm) {
    .c-2fa-login-form__inner {
        h1 {
            font-size: 1.4rem;
        }
    }

    .c-2fa-login-form__submit-section {
        width: 100%;
        margin-left: 0;
        margin-right: 0;

        button {
            width: 100%;
        }
    }
}
