//
// Search box
// =========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.c-search-box__inner {
    border: 2px solid $colour-white;
    display: flex;
}

.c-search-box__autocomplete {
    flex-grow: 2;
}

.c-search-box__query {
    background-color: transparent;
    border-width: 0;
    color: $colour-white;
    width: 100%;
}

.c-search-box__button {
    background-color: transparent;
    border-width: 0;
    color: $colour-white;
    padding: 0.5rem 1rem;
}
