//
// ShoppingVoucher amount selector
// ==========================================

@use 'config' as *;
@use 'utility' as *;

.c-shopping-voucher-amount-selector {

    // Structure.

    &__denomination-controls,
    &__quantity-controls,
    &__costs-summary,
    &__submit-container {
        padding: 0 1em;
    }

    // Headings.

    &__heading {
        font-size: 1em;
        font-weight: normal;
    }

    // Hide items that should only be present for the non-JS fallback.

    &__form-row {
        display: none;
    }

    // Messages.

    &__main-error-message,
    &__denomination-error-message {
        color: $colour-punch;
        font-size: 0.85em;
        line-height: 1;
    }

    &__main-error-message {
        margin-top: 1em;
        padding: 0 1em;
    }

    &__denomination-error-message {
        margin-bottom: 0.8em;
    }

    // Denominations.

    &__denomination-list {
        flex-wrap: wrap;
        display: flex;
        padding: 0;
    }

    &__denomination-list-item {
        list-style-type: none;
        margin-right: 1em;
    }

    &__denomination-link {
        border: 1px solid $colour-turquoise;
        border-radius: 5px;
        display: block;
        padding: 0.5em 1em;
    }

    &__denomination-link {
        &:hover,
        &:focus {
            text-decoration: none;
        }

        &--disabled {

            border-color: $colour-grey;
            color: $colour-grey;

            &:hover,
            &:focus {
                color: $colour-grey;
            }

        }

    }

    &__denomination-link--active {
        background-color: $colour-light-grey;
    }

    // Quantity selection.

    &__heading--quantity {
        margin-bottom: 1em;
    }

    &__quantity-indicator {
        margin: 0 1em;
    }

    &__quantity-button {

        border: 1px solid $colour-turquoise;
        border-radius: 5px;
        display: inline-block;
        font-size: 1.4em;
        height: 2rem;
        text-align: center;
        width: 2rem;

        &--disabled {
            color: $colour-grey;
            border-color: $colour-grey;

            &:hover,
            &:focus {
                color: $colour-grey;
            }

        }

        &:hover,
        &:focus {
            text-decoration: none;
        }

    }

    &__submit-container {
        padding-bottom: 1em;
    }

    &__submit {
        width: 100%;

        &--disabled {
            background-color: $colour-grey;
            color: $colour-lighter-grey;
        }

    }

    // Non-JS fallback.

    &--no-js {
        .c-shopping-voucher-amount-selector__form-elements {
            padding: 1em;
        }

        .c-shopping-voucher-amount-selector__form-row {
            display: block;
        }

        .c-shopping-voucher-amount-selector__quantity-indicator,
        .c-shopping-voucher-amount-selector__costs-summary {
            display: none;
        }

        .c-shopping-voucher-amount-selector__denomination-select {
            margin-bottom: 1em;
            padding: 0 0.5em;
        }

        .c-shopping-voucher-amount-selector__quantity-input {
            height: 2em;
            padding: 0 0.5em;
        }

    }
}
