//
// Checkout Address Form
// ==========================================

@use 'config' as *;
@use 'utility' as *;

.c-checkout-address-form {

    margin: 0 2rem;
    max-width: 700px;

    &__title {
        font-size: 2.25rem;
        margin: 2rem 0;
    }

    &__sub-title {
        font-size: 1.5rem;
    }

    &__sub-title--delivery-address {
        margin-top: 1.5em;
    }

    &__small-title {
        font-size: 1rem;
    }

    &__contact-information {
        margin-bottom: 2em;
    }

    &__edit-contact-information-link, &__edit-contact-information-link:visited {
        color: $colour-dark-chambray;
    }

    &__address-incomplete-error {
        color: $colour-punch;
    }

    &__contact-information {
        background-color: $colour-grey;
        padding: 1em;
    }

    &__delivery-same-as-billing-address-fields input,
    &__use-saved-contact-information-fields input {
        float: none;
    }

    &__postcode-lookup-button {
        position: relative;
        display: inline-block;
        height: 50px;
        top: -1px;
    }

    &__terms-and-conditions {
        margin-bottom: 1em;
    }

    &__address-lookup-fields,
    &__delivery-same-as-billing-address-fields,
    &__use-saved-contact-information-fields,
    &__manual-address-fields {
        margin-bottom: 0.5em;
    }

    &__edit-contact-information {
        margin: 10px 0 0 0;
    }

    &__form-row {
        margin-bottom: 1em;
    }

    &__billing-address-postcode-field,
    &__delivery-address-postcode-field {
        width: 50%;
        display: inline-block;
    }

    &__billing-address-postcode-label,
    &__delivery-address-postcode-label {
        display: block;
    }

    &__billing-address-select,
    &__delivery-address-select {
        font-size: 16px;
        padding: 0 16px;
        height: 50px;
        line-height: 50px;
    }

    &__choice-row {
        display: flex;
        align-items: center;
    }

    &__choice-row input[type=radio] {
        margin-top: 0;
    }

    &__choice-row-label {
        margin-left: $spacing-xs;
    }

    // Form errors.

    &__form-errors {
        margin-top: 0.5em;
        padding: 0;
    }

    &__form-error {
        color: $colour-punch;
        list-style-type: none;
    }

    // Non-JS styling.

    &--no-js {
        .c-checkout-address-form__use-address-lookup-link,
        .c-checkout-address-form__use-manual-address-link {
            display: none;
        }

        .c-checkout-address-form__address-lookup-fields {
            display: none;
        }

    }

}

.c-checkout-address-form__submit-button {
    color: $colour-white;
    background-color: $colour-success-button;
    border-color: $colour-success-border;
}

.c-checkout-address-form__submit-button:hover {
    color: $colour-white;
    background-color: $colour-success-button-hover;
    border-color: $colour-success-button-hover-border;
}

@media screen and (max-width: 768px) {
    .c-checkout-address-form {
        margin: 0 1rem;
    }

    .c-checkout-address-form__submit {
        width: 100%;
    }
}
