//
// Cashback Enquiry Activity
// ==========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.c-cea {
}

// == Elements
//

.c-cea__table {
    font-family: $font-family-base;
}

.c-cea__table-header-row {

    border-bottom: 1px solid $colour-mine-shaft;

    & th {
        padding: 0.5rem;
    }
}

.c-cea__table-data-row {
    & td {
        padding: 0.5rem;
    }

    &:nth-child(even) {
        background-color: $colour-light-grey;
    }

    &:hover {
        background-color: $colour-grey;
    }
}

.c-cea__table-row-date {
    min-width: 115px;
}
