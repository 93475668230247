//
// Cashback Enquiry Summary Status
// ==========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.c-ces-status {
    display: flex;
    flex-direction: column;

    justify-content: flex-end;

    padding: 1rem 0;

    font-family: $font-open-sans;
}

// == Elements
//

.c-ces-status__row {
    display: flex;

    padding: 1rem 0;

    justify-content: space-between;
    align-items: center;
    font-size: $line-height-base;

    &-sub-content {
        margin: 0;

        font-size: $font-size-small;
    }
}

.c-ces-status__button {
    margin: 0 auto;

    // == Modifiers
    //

    &--disabled,
    &--disabled:hover,
    &--disabled:focus,
    &--disabled:active {
        background-color: $colour-grey;
        color: $colour-mine-shaft;

        outline: none;
        opacity: 0.65;
        cursor: not-allowed;
    }
}
