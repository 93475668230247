//
// Landing savings
// ==========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

@media (min-width:768px) {
    .savings-promos {
        margin-top: 2.5em;
    }

    .landing-savings .savings-promo {
        padding-top: 0;
        min-height: 340px;
        border-top: none;
    }

    .landing-savings .savings-promo + .savings-promo {
        border-left: 1px solid #5e5e63;
    }

    .landing-savings .savings-promo img {
        margin-top: -10px;
    }
}
