//
// Simple accordion
// ==========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.c-simple-accordion {
    margin-bottom: 2rem;
}

.c-simple-accordion__title {
    align-items: center;
    border: 1px solid #eeeeee;
    border-width: 1px 0;
    display: flex;
    justify-content: space-between;
}

.c-simple-accordion__title {
    color: #6ebfff;
    cursor: pointer;
    padding: 0.5rem 0;
}

.c-simple-accordion__title::after {
    color: #333333;
    content: '\f077';
    font-family: FontAwesome;
    font-size: 0.6rem;
    margin-right: 0.5rem;
}

.c-simple-accordion__content {
    font-size: 0.8em;
    overflow: hidden;
    max-height: 0;
    transition: all 0.2s ease-in-out;
}

.c-simple-accordion.is-open .c-simple-accordion__title::after {
    content: '\f078';
}

.c-simple-accordion.is-open .c-simple-accordion__content {
    max-height: 100%;
    padding: 0.5rem 0;
}
