//
// Order History Detail
// =========================================

@use 'config' as *;
@use 'utility' as *;

.order-history-detail, {
  border: 1px solid $colour-grey;
  margin: $spacing-m;
}

.order-history-detail__header {
  padding: $spacing-m $spacing-l;
  border-bottom: 1px solid $colour-grey;
}

.order-history-detail__header {
  background-color: $colour-light-grey;

  @include breakpoint(md-plus) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.order-history-detail__information:first-of-type {
  margin-bottom: $spacing-l;

  @include breakpoint(md-plus) {
    margin: 0;
  }
}
.order-history-detail__information:last-of-type {
  @include breakpoint(md-plus) {
    text-align: right;
  }
}

.order-history-detail__information {
  display: flex;
  flex-direction: column;
}

.order-history-detail__status, .order-history-detail__wallet-link {
  margin-top: $spacing-s;
}

.order-history-detail__status {
  display: flex;
  align-items: center;
  line-height: 16px;
}

.order-history-detail__status-icon {
  margin-right: 4px;
  height: 16px;
  width: 16px;
  background-size: contain;
}

.order-history-detail__wallet-link {
  font-weight: $semi-bold-font-weight;
}

.order-history-detail__transaction-title {
  font-weight: $semi-bold-font-weight;
}

.order-history-detail__status--pending-post-transaction-status {
  color: $colour-pending;
}

.order-history-detail__status--waiting-to-be-dispatched {
  color: $colour-pending;
}

.order-history-detail__status--dispatched {
  color: $colour-turquoise;
}

.order-history-detail__status--delivered {
  color: $colour-default-card;
}

.order-history-detail__status--refunded {
  color: $colour-turquoise;
}

.order-history-detail__status--void,
.order-history-detail__status--archived {
  color: $colour-dark-grey;
}

.order-history-detail__status--error,
.order-history-detail__status--not-authorized,
.order-history-detail__status--invalid {
  color: $colour-maroon;
}

.order-history-detail__status--cancelled,
.order-history-detail__status--rejected,
.order-history-detail__status--fail {
  color: $colour-maroon;
}

.order-history-detail__total {
  font-weight: $semi-bold-font-weight;
}

.order-history-detail__savings {
  color: $colour-default-card;
}

.order-history-detail__show-more {
  padding: $spacing-s $spacing-l;
  border-bottom: 1px solid $colour-grey;
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-history-detail__show-more-icon {
  transition: rotate 500ms ease-in-out;
}

.order-history-detail__show-more-icon.has-opened {
  rotate: -180deg;
  transition: rotate 500ms ease-in-out;
}

.order-history-detail__item {
  padding: $spacing-m;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $colour-grey;

  &.is-hidden {
    display: none;
  }
}

.order-history-detail__item:last-of-type {
  border-bottom: none;
}

.order-history-detail__item-image {
  width: 33.3%;
}

.order-history-detail__item-details {
  width: 66.7%;
}

.order-history-detail__item-name {
  font-weight: $semi-bold-font-weight;
}

