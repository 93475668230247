//
// Checkout Complete Addresses
// ==========================================

@use 'config' as *;
@use 'utility' as *;

.c-checkout-complete-addresses {
    display: flex;
    justify-content: center;
    vertical-align: top;
    margin: 0 2rem;

    &__delivery-address {
        margin-right: 200px;
    }
}

@media screen and (max-width: 768px) {
    .c-checkout-complete-addresses {
        display: block;

        &__delivery-address,
        &__billing-address {
            width: 100%;
            margin-bottom: 30px;
        }

        &__delivery-address {
            margin-right: 0;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .c-checkout-complete-addresses__delivery-address,
    .c-checkout-complete-addresses__billing-address {
        width: 50%;
    }

    .c-checkout-complete-addresses__delivery-address {
        margin-right: 0;
    }
}
