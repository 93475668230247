//
// Product View Header
// ==========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.c-pv-header {
    position: relative;

    padding: 0;
    margin-bottom: 0;

    background-color: $colour-light-grey;
    text-align: center;

    @include breakpoint(sm-plus) {
        text-align: left;
    }
}

//== Elements
//

.c-pv-header__container {
    padding-top: 3rem;

    border-top: 1px solid $colour-grey;
    border-bottom: 1px solid $colour-grey;

    @include breakpoint(sm-plus) {
        padding-right: 345px;
        padding-bottom: 1.3rem;
    }

    @media (min-width: 1200px) {
        padding-right: 440px;
    }
}

.c-pv-header__like-button {
    padding: 0.4em 0.8em;
    margin-bottom: 5px;
    margin-right: 10px;
    min-width: 0;

    font-size: 12px;
    text-decoration: none;

    z-index: 1;
}

.c-pv-header__title {
    @include breakpoint(sm-plus) {
        font-size: 38px;
    }
}

.c-pv-header__sub-title {
    @include breakpoint(sm-plus) {
        font-size: 30px;
    }
}
//== Modifiers
//

.c-pv-header__like-button--like {
    background-color: transparent;
}
