//
// Colours utilities
// ==========================================

@use 'config' as *;
@use 'utility' as *;

.u-color-green {
    color: $colour-light-green;
}

.u-color-blue {
    color: $colour-light-blue;
}

.u-color-orange {
    color: $colour-orange;
}

.u-color-maroon {
    color: $colour-maroon;
}
