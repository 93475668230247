@use 'config' as *;
@use 'utility' as *;

$_image-height-small: 96px;
$_image-height: 204px;
$_image-height-large: 336px;
$_logo-size-large: 120px;
$_logo-size-medium: 80px;
$_logo-size-small: 64px;
$_spacing: 20px;
$_button-size: 52px;

.dashboard-carousel__container {
    padding-top: $spacing-l;
    padding-bottom: $spacing-l;
}

.dashboard-carousel-main {
    padding-bottom: $spacing-l;

    @include breakpoint(sm-plus) {
        padding-bottom: 0;
    }

    // pagination is added by plugin so targeting the splide classes
    .splide__pagination {
        bottom: 0;
        position: relative; // With new bigger icons they overlap
        padding-top: 0;     // the cards without these 2
    }

    .splide__pagination__page {
        opacity: 1;
        background-color: $colour-grey;
        height: 24px;
        width: 24px;
        margin: 4px;
        transform: none;

        &.is-active {
            background-color: $colour-sodexo-dark-blue;
        }
    }
}

.dashboard-carousel-main__slide {
    padding: $spacing-m $spacing-s; // for drop shadow

    @include breakpoint(md-plus) {
        display: grid;
        gap: $spacing-m;
        grid-template-columns: calc(65% - $spacing-m*.5) calc(35% - $spacing-m*.5);
        grid-template-rows: calc(50% - $spacing-m*.5) calc(50% - $spacing-m*.5);
    }

    @include breakpoint(lg-plus) {
        grid-template-columns: calc(75% - $spacing-m) 25%;
    }
}

.dashboard-carousel-card {
    @include card;

    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto; // to stretch to height of slide
    color: $colour-mine-shaft;

    &:hover,
    &:focus {
        color: $colour-mine-shaft;
    }

    @include breakpoint(sm-plus) {
        grid-row-start: 1;
        grid-row-end: span 2;
    }

    &.dashboard-carousel-card--small {
        grid-row-start: auto;
        grid-row-end: auto;

        @include breakpoint(md-neg) {
            display: none;
        }
    }
}

.dashboard-carousel-card__image {
    height: $_image-height;
    width: 100%;
    object-fit: cover;

    @include breakpoint(sm-plus) {
        height: $_image-height-large;
    }

    .dashboard-carousel-card--small & {
        height: $_image-height-small;
    }
}

.dashboard-carousel-card__logo-box {
    position: absolute;
    right: $_spacing;
    top: $_image-height-small - 24px;

    .dashboard-carousel-card--large & {
        right: $_spacing;
        top: $_spacing;

        @include breakpoint(sm-plus) {
            width: $_logo-size-large;
            height: $_logo-size-large;
            right: auto;
            left: $_spacing;
        }
    }
}

.dashboard-carousel-card__body {
    padding: 12px $spacing-s;
    flex: 1 1 auto;

    @include breakpoint(sm-plus) {
        display: flex;
        gap: 12px;
        flex-direction: column;
    }

    .dashboard-carousel-card--small & {
        padding-right: ($_logo-size-medium + $spacing-s * 2);
    }

    .dashboard-carousel-card--large & {
        gap: $_spacing;
        padding: $spacing-s $_spacing $_spacing;

        @include breakpoint(sm-plus) {
            flex-direction: row;
            align-items: flex-end;
        }
    }
}

.dashboard-carousel-card__copy {
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex: 1 1 auto;

    .dashboard-carousel-card--large & {
        gap: $spacing-s;
    }
}

.dashboard-carousel-card__title {
    @include lineClamp(2);
    @include body-text-medium;

    .dashboard-carousel-card--large & {
        @include heading-small;
        @include lineClamp(2);

        @include breakpoint(sm-plus) {
            @include lineClamp(1);
        }
    }
}

.dashboard-carousel-card__description {
    display: none;

    .dashboard-carousel-card--large & {
        display: block;
        @include body-text-medium;
        @include lineClamp(2);
        margin: 0;

        @include breakpoint(sm-plus) {
            @include lineClamp(1);
        }
    }

}

.dashboard-carousel-card__button {
    @include button-text;
    display: flex;
    align-items: center;
    gap: $spacing-xs * .5;
    margin: 0;

    .dashboard-carousel-card--small & {
        margin-top: auto; // to push to the bottom of the card
    }

    .dashboard-carousel-card--large & {
        flex: 0 0 auto;

        @include breakpoint(sm-neg) {
            display: none;
        }
    }
}

.dashboard-carousel-card__button-icon {
    .dashboard-carousel-card--large & {
        fill: $colour-white;
    }
    padding: 0 10px;
    height: 12.5px;
}


.dashboard-carousel-pagination {
    display: none;

    @include breakpoint(sm-plus) {
        display: block;

        margin: 0 $spacing-s;
    }

    @include breakpoint(md-plus) {
        max-width: 96px * 8 + 8px + ($_button-size + $spacing-xs) * 3 + $spacing-m; // thumbnails + padding + buttons
        margin: 0 auto;
    }

    .splide__slide {
        padding: 0 0 $spacing-m;
        border: none !important;
    }

    .splide__slide.is-active {
        .o-logo-box {
            box-shadow: 0 8px 15px 0 $colour-grey-drop-shadow;
        }
    }

    .slider__arrow--left {
        left: 0;
        top: calc(50% - 10px);
    }
    .slider__arrow--right {
        right: 0;
        top: calc(50% - 10px);
    }
}

.dashboard-carousel-pagination__track {
    margin: 0  $_button-size + $spacing-xs; // space for arrows

    @include breakpoint(sm-plus) {
        margin-left: ($_button-size + $spacing-xs) * 2; // space for arrow and play button
    }
}

.dashboard-carousel-pagination__list {
    padding-top: 15px !important;
    padding-bottom: 10px !important;
}

.dashboard-carousel-pagination__logo-box {
    width: $_logo-size-small;
    height: $_logo-size-small;
    margin: 0 auto;

    @include breakpoint(sm-plus) {
        width: $_logo-size-medium;
        height: $_logo-size-medium;
    }
}

.dashboard-carousel-pagination__toggle {
    position: absolute;
    top: 30px;
    left: $_button-size + $spacing-xs;

    @include breakpoint(sm-neg) {
        display: none;
    }
}
