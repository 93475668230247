//
// Product widget container
// ==========================================

@use 'config' as *;
@use 'utility' as *;

// Structure.

.c-product-widget-container {
    width: 100%;

    @include breakpoint(sm-plus) {
        position: sticky;
        top: 1em;
    }

    @include breakpoint(h-sm-max) {
        position: initial;
    }
}

.c-product-widget-container__inner {
    background-color: $colour-white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    position: relative;

    @include breakpoint(sm-neg) {
        position: fixed;
        bottom: 0;
        height: 100%;
        width: 100%;
        z-index: 31;
    }

    &.c-product-widget-container__inner--mobile-closed {
        @include breakpoint(sm-neg) {
            display: none;
        }
    }
}

.c-product-widget-container__top-bar {
    background-color: $colour-darker-grey;
    color: $colour-white;
    padding: 1em 0;
    text-align: center;

    @include breakpoint(sm-plus) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-height: 200px;
    }
}

.c-product-widget-container__discount {
    @include heading-large;

    display: inline-block;
    position: relative;
}

.c-product-widget-container__discount-value {
    margin-right: -7px;
}

.c-product-widget-container__product-type {
    @include body-text-large-bold;
}

// Mobile-specific.

.c-product-widget-container__mobile-open-link-container {
    background-color: $colour-white;
    bottom: 0;
    border-top: 1px solid $colour-dark-grey;
    left: 0;
    padding: 2em 0;
    position: fixed;
    width: 100%;
    z-index: 1;
    overflow-y: scroll;

    @include breakpoint(sm-plus) {
        display: none;
    }
}

.c-product-widget-container__mobile-open-link-container--hidden {
    display: none;
}

.c-product-widget-container__mobile-open-link {
    background-color: $colour-turquoise;
    color: $colour-white;
    display: block;
    margin: 0 1em;
    padding: 0.5em 0;
    text-align: center;
    border-radius: 8px;
}

.c-product-widget-container__mobile-open-link--disabled {
    background-color: $colour-grey;
    color: $colour-lighter-grey;
}

.c-product-widget-container__mobile-open-link:hover,
.c-product-widget-container__mobile-open-link:focus {
    color: $colour-white;
    text-decoration: none;
}

.c-product-widget-container__mobile-close-link-container {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5em 1em;
    text-align: right;


    @include breakpoint(sm-plus) {
        display: none;
    }
}

.c-product-widget-container__mobile-close-link {
    @include heading-small;

    color: $colour-white;
}

.c-product-widget-container__mobile-close-link:hover,
.c-product-widget-container__mobile-close-link:focus {
    text-decoration: none;
}
