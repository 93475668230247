//
// Evoucher amount selector
// ==========================================

@use 'config' as *;
@use 'utility' as *;

// Structure.

.c-evoucher-amount-selector__form {
    padding: $spacing-s;
}

.c-evoucher-amount-selector__quantity-controls {
    margin-bottom: 1em;
}

// Headings.

.c-evoucher-amount-selector__heading {
    @include body-text-large;
    margin-bottom: $spacing-m;
}

.c-evoucher-amount-selector__heading--quantity {
    margin-bottom: 1em;
}

// Hide items that should only be present for the non-JS fallback.

.c-evoucher-amount-selector__form-row {
    display: none;

    &--no-js {
        display: block;
    }
}

// Messages.

.c-evoucher-amount-selector__main-error-message,
.c-evoucher-amount-selector__denomination-error-message {
    color: $colour-punch;
    font-size: 0.85em;
    line-height: 1;
}

.c-evoucher-amount-selector__main-error-message {
    margin-top: 1em;
    padding: 0 1em;
}

.c-evoucher-amount-selector__denomination-error-message {
    margin-bottom: 0.8em;
    margin-bottom: 1em;
}

// Denominations.
.c-evoucher-amount-selector__denomination-controls {
    border-bottom: 1px solid $colour-mine-shaft;
}

.c-evoucher-amount-selector__denomination-list {
    flex-wrap: wrap;
    display: flex;
    padding: 0;
}

.c-evoucher-amount-selector__denomination-list-item {
    list-style-type: none;
    margin-right: 1em;
}

.c-evoucher-amount-selector__denomination-link {
    @include body-text-large-bold;

    border: 1px solid $colour-darker-grey;
    border-radius: 5px;
    display: block;
    padding: 0.5em 1em;
    color: $colour-black;
}

.c-evoucher-amount-selector__denomination-link {
    &:hover,
    &:focus {
        text-decoration: none;
    }

    &--disabled {

        border-color: $colour-grey;
        color: $colour-grey;

        &:hover,
        &:focus {
            color: $colour-grey;
        }

    }

}

.c-evoucher-amount-selector__denomination-link--active {
    background-color: $colour-light-grey;
}

.c-evoucher-amount-selector__quantity-indicator {
    margin: 0 1em;
    font-size: $font-size-extreme;

    &--no-js {
        display: none;
    }
}

.c-evoucher-amount-selector__quantity-button {
    display: inline-block;
    font-size: 2em;
    height: 2rem;
    text-align: center;
    width: 2rem;
    color: $colour-darker-grey;

    &--disabled {
        color: $colour-grey;
        border-color: $colour-grey;

        &:hover,
        &:focus {
            color: $colour-grey;
        }

    }

    &:hover,
    &:focus {
        text-decoration: none;
    }

}

.c-evoucher-amount-selector__submit-container {
    padding-bottom: 1em;
}

.c-evoucher-amount-selector__submit {
    width: 100%;

    &--disabled,
    &--disabled:hover {
        background-color: $colour-grey !important;
        color: $colour-darker-grey !important;
        cursor: default;
    }

}

// Nondenominated vouchers.

.c-evoucher-amount-selector__form-row--quantity-nondenominated {
    align-items: center;
    border: 1px solid $colour-darker-grey;
    display: flex;
    height: 3em;
    flex-grow: 1;
    margin: 0 1em;
}

.c-evoucher-amount-selector__quantity-label--nondenominated {
    display: inline-block;
    font-size: 1.5em;
    font-weight: bold;
    padding: 0 0.8em;

    &-disabled {
        background-color: $colour-grey;
    }
}

.c-evoucher-amount-selector__quantity-input--nondenominated {
    border: 1px solid $colour-darker-grey;
    border-width: 0 1px;
    display: inline-block;
    font-size: 1.05em !important; // Required to override legacy CSS.
    flex-grow: 2;
    height: 100% !important; // Required to override legacy CSS.
    width: 50%;
}

.c-evoucher-amount-selector__quantity-pence {
    background-color: $colour-grey;
    display: inline-block;
    flex-grow: 1;
    height: 100%;
    line-height: 3em;
    padding: 0 0.8em;
}

// Non-JS fallback.

.c-evoucher-amount-selector--no-js {
    .c-evoucher-amount-selector__form-elements {
        padding: 1em;
    }

    .c-evoucher-amount-selector__costs-summary {
        display: none;
    }

    .c-evoucher-amount-selector__denomination-select {
        margin-bottom: 1em;
        padding: 0 0.5em;
    }

    .c-evoucher-amount-selector__quantity-input {
        height: 2em;
        padding: 0 0.5em;
    }

}
