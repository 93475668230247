@use 'config' as *;
@use 'utility' as *;

.s-product-page {
    margin: 0 auto;
    width: 100%;

    @include breakpoint(sm-plus) {
        display: flex;
        gap: $spacing-m;
        margin-top: -200px;
        margin-bottom: 100px;
        max-width: 970px; // taken from bootstrap, ideally should be variables
    }

    @include breakpoint(lg-plus) {
        max-width: 1170px; // taken from bootstrap, ideally should be variables
    }
}

.d-product-page__content {
    @include breakpoint(sm-plus) {
        flex: 0 0 450px;
    }

    @include breakpoint(md-plus) {
        flex: 0 0 66%;
    }
}

.d-product-page__widget {
    @include breakpoint(sm-plus) {
        flex: 1 1 auto;
    }
}
