/**
 * Line clamp
 *
 * To be used as follows:
 * @include line-clamp(int number-of-lines);
 *
 */

@mixin lineClamp($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
