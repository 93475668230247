/**
  Payment Cards
 */

@use 'config' as *;
@use 'utility' as *;

.c-payment-cards__container {
  max-width: 700px;
  margin: 2em 0 2em 0;
}

.c-payment-cards__sub-title {
  font-size: 1.5rem;
}

.c-payment-cards__hide-saved-cards {
  display: none;
}

.c-payment-cards__card-selection-label {
  padding: 10px;
  background-color: $colour-grey;
}

.c-payment-cards__saved-card-selection {
  margin: 10px 0 0 0;
  width: 100%;
  padding: 5px;
}

/**
  Saved Payment Cards
 */

.c-payment-cards__save-payment-card {
  font-weight: 600;
}

.c-payment-cards__hide {
  display: none;
}

.c-payment-cards__save-card-details {
  margin: 2em 0 2em 0;
}

.c-payment-cards__save-card-details-disabled-text {
  color: $colour-error-text;
}

.c-payment-cards__default-card-expired-text {
  color: $colour-error-text;
}

.c-payment-cards__add-card-details-label {
  margin-left: 5px;

  @include breakpoint('sm-neg') {
    margin-left: 35px;
  }

  @include breakpoint('sm-plus') {
    margin-left: 37px;
  }
}

.c-payment-cards__add-card-details {
  padding-left: 2px;

  @include breakpoint('sm-neg') {
    margin-top: 5px;
    position: absolute;
    left: 36px;
  }

  @include breakpoint('sm-plus') {
    margin-top: 5px;
    position: absolute;
    left: 45px;
  }
}




