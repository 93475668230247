//
// offer list panel - legacy code
// ==========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.offer-list-panel {
    margin-top: 11px;
    border: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.offer-list-panel .panel-heading {
    height: 155px;
    line-height: 155px;
    padding: 0;
    position: relative;
    background-color: $colour-white;
    overflow: hidden;
}

.offer-list-panel .panel-heading img {
    max-width: 220px;
    max-height: 120px;
}

.offer-list-panel .favourite-button {
    position: absolute;
    top: 10px;
    right: 10px;
}

.offer-list-panel .favourite-button .fa {
    display: block;
    font-size: 1.375em;
}

.offer-list-panel .panel-body {
    background-color: #f6f6f6;
    padding: 10px 25px;
}

.offer-list-panel .panel-body h2 {
    font-size: 0.75em;
    text-transform: uppercase;
    color: #6d6d6d;
    margin-top: 0;
    margin-bottom: 14px;
}

.offer-list-panel .available-offers li {
    width: 50px;
    font-size: 0.75em;
    margin: 0;
    text-align: center;
    padding: 0;
}

.offer-list-panel .available-offers li a {
    display: inline-block;
    text-decoration: underline;
}

.offer-list-panel .available-offers .svg {
    background-position: center center;
    margin: 0 6px 5px;
}

@media (min-width:$screen-md) and (max-width:$screen-lg) {
    .panel.offer-list-panel .panel-body {
        padding: 15px 12px;
    }
}

@media (min-width:$screen-md) {
    .offer-list-panel:hover,
    .offer-list-panel:focus {
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
        transition: box-shadow 0.1s ease-in;
    }

    .panel-body {
        min-height: 60px;
    }

}
