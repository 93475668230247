//
// Checkout Payment Details Form
// ==========================================

@use 'config' as *;
@use 'utility' as *;

.c-checkout-payment-details-form {

    margin: 0 2rem;
    max-width: 700px;

    &__title {
        font-size: 2.25rem;
        margin: 2rem 0;
    }

    &__input {
        font-size: 16px;
        padding: 0 16px;
        height: 50px;
        line-height: 50px;
    }

    &__errors {
        padding: 0;
    }

    &__error {
        color: $colour-punch;
        list-style-type: none;
        margin-bottom: 0.5em;
    }

}

@media screen and (max-width: 768px) {
    .c-checkout-payment-details-form {
        margin: 0 1rem;
    }

    .c-checkout-payment-details-form__input--submit {
        width: 100%;
    }

    .c-checkout-payment-details-form__title {
        margin-top: 10px;
        font-size: 28px;
    }
}
