//== Typography
//
//## Font, line-height, and color for body text, headings, and more.
$font-nunito: 'Nunito', sans-serif;
$font-open-sans: 'Open Sans', sans-serif;

$font-family-base: $font-open-sans;
$font-family-headings: $font-nunito;
$font-family-icon: 'FontAwesome';

$font-size-base: 16px;
$line-height-base: 22px;
$base-font-weight: 400;
$semi-bold-font-weight: 600;
$headings-font-weight: 700;

$font-size-h1: 40px;
$font-size-h2: 35px;
$font-size-h3: 25px;
$font-size-h4: 35px;
$font-size-h5: 35px;

$font-size-small: 14px;
$font-size-medium: 18px;
$font-size-large: 20px;
$font-size-xl: 24px;
$font-size-xxl: 28px;
$font-size-extreme: 40px;

$font-size-small-line-height: 18px;

$font-size-h1-line-height: 46px;
$font-size-h2-line-height: 41px;
$font-size-h3-line-height: 31px;

