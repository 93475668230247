@use 'config' as *;
@use 'utility' as *;

.o-add-to-favourite {
    @include body-text-medium;

    border: none;
    background-color: initial;
    padding: 4px;

    &::before {
        font-family: $font-family-icon;
        content: '\f08a';
        transition: $animation;
    }

    &:hover {
        &::before {
            content: '\f004';
        }
    }
}

.o-add-to-favourite--added {
    &::before {
        content: '\f004';
    }
}

