//
// Basket Table
// =========================================

@use 'config' as *;
@use 'utility' as *;

.basket-table {
    width: 100%;
}

.basket-table__item,
.basket-table__header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
}

.basket-table__header {
    background-color: $colour-concrete;
    text-transform: uppercase;
    font-weight: bold;
}

.basket-table__body,
.basket-table__info {
    background-color: $colour-white;
}

.basket-table__saving-image {
    width: 15px;
    height: 15px;

    @include breakpoint(md-plus) {
        margin-right: 8px;
    }

}

.basket-table__header-description,
.basket-table__header-quantity,
.basket-table__header-rrp,
.basket-table__header-saving,
.basket-table__header-cost {
    padding: 12px 12px;
    border-left: solid 1px $colour-grey;
}

.basket-table__header-remove,
.basket-table__header-description {
    display: none;

    @include breakpoint(md-plus) {
        display: initial;
    }
}

.basket-table__header-remove {
    @include breakpoint(md-plus) {
        width: 5%;
    }
}

.basket-table__header-description {
    @include breakpoint(md-plus) {
        flex: 1
    }
}

.basket-table__header-quantity,
.basket-table__header-rrp,
.basket-table__header-saving,
.basket-table__header-cost {
    width: 25%;

    @include breakpoint(md-plus) {
        width: 12%;
    }
}

.basket-table__header-quantity,
.basket-table__header-rrp,
.basket-table__header-cost,
.basket-table__footer-totals-title,
.basket-table__footer-totals-rrp,
.basket-table__footer-totals-cost {
    display: flex;
    flex-direction: column-reverse;
}

.basket-table__header-saving,
.basket-table__footer-totals-saving {
    background-color: $colour-peppermint;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;

    @include breakpoint(md-plus) {
        flex-direction: row;
    }
}

.basket-table__header-quantity {
    text-align: center;
}

.basket-table__header-rrp,
.basket-table__header-saving,
.basket-table__header-cost {
    text-align: right;
}

.basket-table__item {
    border-bottom: 1px solid $colour-grey;
}

.basket-table__item-quantity,
.basket-table__item-description,
.basket-table__item-rrp,
.basket-table__item-saving,
.basket-table__item-cost {
    padding: 12px;
    border-left: 1px solid $colour-grey;
}

.basket-table__item-quantity,
.basket-table__item-rrp,
.basket-table__item-saving,
.basket-table__item-cost {

    width: 25%;

    @include breakpoint(md-plus) {
        width: 12%;
    }
}

.basket-table__item-remove {
    order: 0;
    width: 10%;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(md-plus) {
        order: initial;
        width: 5%;
    }
}

.basket-table__footer-remove-all-link {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: baseline;
    justify-content: flex-end;

    @include breakpoint(md-plus) {
        flex-direction: row-reverse;

    }
}

.basket-table__item-remove-icon {
    color: $colour-black;
}

.basket-table__item-description {
    order: -1;
    width: 90%;

    @include breakpoint(md-plus) {
        order: initial;
        flex: 1;
    }
}

.basket-table__item-image {
    width: 80px;
    padding-bottom: 4px;
}

.basket-table__item-link {
    color: $colour-black;
    text-decoration: none;
}

.basket-table__item-link:hover {
    text-decoration: underline;
}

.basket-table__item-link:focus {
    color: $colour-endeavour;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.basket-table__item-quantity {
    text-align: center;
}

.basket-table__item-rrp,
.basket-table__item-saving,
.basket-table__item-cost {
    text-align: right;
}

.basket-table__info {
    border-bottom: 1px solid $colour-grey;
    padding: 12px 12px;

    @include breakpoint(md-plus) {
        padding-left: 12px;
    }
}

.basket-table__footer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    background-color: $colour-concrete;
}

.basket-table__footer-remove-all {
    width: 100%;
    text-align: right;
    padding: 12px 12px;
    background-color: $colour-white;

    @include breakpoint(md-plus) {
        flex: 1;
        text-align: left;
        padding-left: 12px;
    }
}

.basket-table__footer-remove-all-link {
    color: $colour-black;
    text-transform: uppercase;
    gap: 5px;
}

.basket-table__footer-remove-all-link:hover {
    text-decoration: none;
}

.basket-table__footer-totals {
    width: 100%;
    display: flex;
    flex-direction: row;
    border-top: 3px solid $colour-black;

    @include breakpoint(md-plus) {
        width: 48%;
        border-left: 3px solid $colour-black
    }
}

.basket-table__footer-totals-title,
.basket-table__footer-totals-rrp,
.basket-table__footer-totals-saving,
.basket-table__footer-totals-cost {
    padding: 12px 12px;
    width: 25%;
}

.basket-table__footer-totals-rrp,
.basket-table__footer-totals-saving,
.basket-table__footer-totals-cost {
    border-left: 1px solid $colour-grey;
}

.basket-table__footer-totals-rrp,
.basket-table__footer-totals-saving,
.basket-table__footer-totals-cost {
    text-align: right;
}
