//
// Products box
// ==========================================

@use 'config' as *;
@use 'utility' as *;

.c-products-box {
    float: left;
    margin-bottom: 40px;
    width: 100%;

    &__favourite-form {
        display: inline-block;
    }

    &__favourite-button {
        background-color: transparent;
        border-width: 0;
        color: #d9d9d9;
        display: inline-block;
    }
}
