//
// Basket Summary
// =========================================

@use 'config' as *;
@use 'utility' as *;

.basket-summary {
  width: 100%;
  background-color: $colour-white;
  border: 1px solid $colour-light-grey;
}

.basket-summary__header {
  display: none;
  padding: 30px;
  text-align: center;
  background-color: $colour-concrete;
  font-weight: $semi-bold-font-weight;
  font-size: $font-size-xxl;
  justify-content: center;

  @include breakpoint(md-plus) {
    display: flex;
  }
}

.basket-summary__body {
  display: none;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;

  @include breakpoint(md-plus) {
    display: flex;
  }
}

.basket-summary__body-item {
  width: 75%;
  display: flex;
  flex-direction: row;
  padding: 6px 0;
  border-bottom: 1px solid $colour-light-grey;
}

.basket-summary__body-item:last-of-type {
  font-weight: $semi-bold-font-weight;
  border-bottom: none;
}

.basket-summary__body-item-header {
  flex: 1;
}

.basket-summary__body-item-value {
  width: 25%;
  text-align: right;
}

.basket-summary__button {
  text-align: center;
  padding: 24px;
  margin: 10px;
}

.basket-summary__footer {
  padding: 30px;
  background-color: $colour-concrete;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: $font-size-small;
}
