@use 'config' as *;
@use './body-text' as *;

$_input-text-colour: $colour-text-colour;
$_input-padding: $spacing-xs*.5 $spacing-s;
$_input-border-colour: $colour-mine-shaft;
$_input-border-colour-focus: $colour-black;
$_input-border-radius: 0;
$_input-background-colour: $colour-white;
$_input-background-colour-disabled: $colour-light-grey;
$_input-error-colour: $colour-error-text;
$_input-focus-colour: $colour-endeavour;
$_placeholder-text-colour: $colour-light-grey;
$_input-border-width: 1px;

/**
 * Fields
 *
 * To be used as follows:
 * @include fields;
 * Applies default styling to basic form field elements such as input, textarea, select etc.
 *
 */

@mixin fields {
    @include input-text;

    width: 100%;
    padding: $_input-padding;
    border-radius: $_input-border-radius;
    border: $_input-border-width solid $_input-border-colour;
    color: $_input-text-colour;
    background-color: $_input-background-colour;
    height: auto; // needed to overwrite bootstrap :-(

    &:disabled {
        background-color: $_input-background-colour-disabled;
        cursor: not-allowed;
    }

    &.has-error {
        border-color: $_input-error-colour;
    }

    &::placeholder {
        color: $_placeholder-text-colour;
    }
}
