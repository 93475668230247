//
// Title favourites
// ==========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.title-favourites-container {
    display: grid;

    text-align: center;

    @media (min-width: $screen-sm) {
        grid-template-columns: 1fr 3fr 1fr;
    }
}

.title-favourites {
    grid-column: 2/3;
    grid-column: 2;
    -ms-grid-column-span: 1;

    &__description {
        font-size: $font-size-small;
        font-weight: $base-font-weight;
    }
}
