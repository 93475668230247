//
// Checkout Basket Summary panel
// ==========================================

@use 'config' as *;
@use 'utility' as *;

.c-checkout-basket-summary {

    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    background-color: $colour-white;
    margin: 0 2rem;
    position: relative;

    &__heading {

        background-color: $colour-turquoise;
        color: $colour-white;
        padding: 3em 0 1em;
        text-align: center;

        &::before {
            background: $colour-turquoise url('../../../../assets/images/svgs/sprite.png') -107px -1132px no-repeat;
            content: '';
            display: block;
            height: 13px;
            margin-left: -11px;
            position: absolute;
            top: 17px;
            left: 50%;
            width: 23px;
        }

    }

    &__body {
        padding: 30px 10%;
    }

    &__item-count {
        margin: 0 0 20px;
    }

    &__item {
        border-bottom: 1px solid $colour-grey;
        list-style-type: none;
        margin-bottom: 15px;
    }

    &__item-total-container {
        justify-content: flex-end;
        padding: 15px 0 15px 15px;
    }

    &__item-amount-container,
    &__item-amount-container,
    &__saving-total-container {
        color: $colour-alternative-gray;
    }

    &__retailer {
        font-weight: bold;
    }

    &__item-totals {
        display: flex;
        justify-content: space-between;
        padding: 1em 0;
    }

    &__item-saving-total-value {
        font-weight: bold;
        color: $colour-alternative-gray;
    }

    &__item-saving-total-label {
        color: $colour-alternative-gray;
    }

    &__item-cost-total {
        background-color: $colour-concrete;
        padding: 0.3em 0.75em;
    }

    &__item-cost-total-value {
        font-weight: bold;
    }

    &__basket-total-value {
        font-size: 1.5em;
        font-weight: bold;
    }

    &__basket-total-label {
        font-size: 1.5em;
    }

    &__basket-saved-total {
        margin-top: 5px;
    }

}

@media screen and (max-width: 768px) {
    .c-checkout-basket-summary {
        margin: 0 1rem;
    }

    .c-checkout-basket-summary__saving-total-container {
        padding: 20px 15px 15px 5px;
    }
}
