//
// Favourite shop
// ==========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.c-favourite-shop {
    margin: 3rem 0;

    @media (min-width: 550px) {
        display: flex;
        flex-wrap: wrap;
    }
}

// == Elements
//

.c-favourite-shop__item {
    display: block;

    margin: 1rem 0;

    @media (min-width: 550px) {
        flex-basis: calc(50% - 1rem);
        max-width: calc(50% - 1rem);
    }

    @media (min-width: $screen-md) {
        flex-basis: calc(33.33% - 1rem);
        max-width: calc(33.33% - 1rem);
    }

    input[type='checkbox'] {
        display: none;
    }
}

@media (min-width: 550px) and (max-width: $screen-md) {
    .c-favourite-shop__item:nth-child(odd) {
        margin-right: 2rem;
    }
}

@media (min-width: $screen-md) {
    .c-favourite-shop__item:nth-child(3n-1) {
        margin: 1rem 1.5rem;
    }
}

.c-favourite-shop__item-presentation {
    display: flex;

    margin-bottom: 0.5rem;
    align-items: center;
}

.c-favourite-shop__item-presentation-label {
    flex: 1;
    min-width: 0;

    font-weight: $base-font-weight;

    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.c-favourite-shop__item-checkbox {
    display: flex;
    position: relative;

    height: 12rem;
    padding: 1rem;
    justify-content: center;
    align-items: center;

    background: $colour-white;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 $colour-grey;

    &-image {
        height: 3.8rem;
    }
}

.c-favourite-shop__item-checkbox::after,
.c-favourite-shop__item-checkbox::before {
    content: '';
    display: inline-block;
    position: absolute;
}

.c-favourite-shop__item-checkbox::before {
    right: 4px;
    bottom: 3px;

    height: 1.5rem;
    width: 1.5rem;

    border-radius: $border-radius-large;
    background: $colour-grey;
}

.c-favourite-shop__item-checkbox::after {
    right: 9px;
    bottom: 13px;

    height: 0.5rem;
    width: 0.9rem;

    border-left: 3px solid $colour-white;
    border-bottom: 3px solid $colour-white;

    transform: rotate(-45deg);
}

.c-favourite-shop__item input[type='checkbox']:checked + .c-favourite-shop__item-checkbox {
    box-shadow: 0 0 10px 2px $colour-light-green;
}

.c-favourite-shop__item input[type='checkbox']:checked + .c-favourite-shop__item-checkbox::before {
    background: $colour-light-green;
}

.c-favourite-shop__item-checkbox:hover {
    box-shadow: 0 0 10px 2px $colour-lighter-green;
}
