@use 'config' as *;
@use 'utility' as *;

/**
 * Card logo object
 */

$logo-size: 60px;
$logo-size-medium: 80px;
$logo-size-large: 120px;

.o-logo-box {
    width: $logo-size;
    height: $logo-size;
    border-radius: $button-border-radius;
    background-color: $colour-white;
    box-shadow: 0 2px 8px 0 $colour-grey-drop-shadow;
    text-align: center;
    overflow: hidden;
}

.o-logo-box--medium {
    width: $logo-size-medium;
    height: $logo-size-medium;
}

.o-logo-box--large {
    width: $logo-size-large;
    height: $logo-size-large;
}


.o-logo-box__logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

