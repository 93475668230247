@use 'config' as *;
@use 'utility' as *;

// needed to overwrite bootstrap :-(
input[type=text],
input[type=email],
input[type=password],
input[type=date] {
    &.o-input {
        @include fields;
    }
}

// set width input
// number of characters + extra for allowing padding or 3rd party icons
// 2 characters
.o-input--width-2 {
    max-width: 5.4ex;
}

// 3 characters
.o-input--width-3 {
    max-width: 7.2ex;
}

// 4 characters
.o-input--width-4 {
    max-width: 9ex;
}

// 5 characters
.o-input--width-5 {
    max-width: 10.8ex;
}

// 10 characters
.o-input--width-10 {
    max-width: 23ex;
}

// 20 characters
.o-input--width-20 {
    max-width: 41ex;
}

.o-input--error {
    color: $colour-punch;
    text-align: left;
    padding: 0;
    margin: 0;
}
