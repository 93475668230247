//
// Product list sort
// =========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.c-page-intro {
    display: flex;
    align-items: center;
    flex-direction: column;

    @include breakpoint(md-plus) {
        flex-direction: row;
        gap: 16px;
    }
}

.c-page-intro__text-container {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.c-page-intro__icon {
    margin-right: 1em;
}

.c-page-intro__text-title {
    font-family: 'Open Sans',sans-serif;
    font-weight: 200;
}

.c-page-intro__button-container {
    flex: 0 0 auto;
    text-align: right;
}
