//
// Autocomplete
// =========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.c-autocomplete {
    position: relative;
}

.c-autocomplete__list {
    background-color: $colour-white;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    color: $colour-mine-shaft;
    position: absolute;
    left: 0;
    list-style-type: none;
    margin: 0;
    padding: 0;
    top: 3rem;
    width: 100%;
    z-index: 99999;
}

.c-autocomplete__list-item {
    padding: 0.5rem;
    margin: 0;

    &:hover {
        color: $colour-white;
        background-color: $colour-light-blue;
    }
}

.c-autocomplete__list-item--active {
    color: $colour-white;
    background-color: $colour-light-blue;
}
