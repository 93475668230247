//
// Copyable input
// ==========================================

@use 'config' as *;
@use 'utility' as *;

.c-copyable-input {
    border: 2px solid #7e7e7e;
    border-radius: 2px;
    display: flex;
    padding: 0.3rem 0.4rem;
}

.c-copyable-input__label {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.c-copyable-input__input {
    border-width: 0;
    flex-grow: 2;
}

.c-copyable-input__button {
    color: transparent;
    display: inline-block;
    margin-left: 0.5rem;
    position: relative;
}

.c-copyable-input__button::after {
    color: #333333;
    content: '\f0c5';
    cursor: pointer;
    font-family: FontAwesome;
    margin-right: 0.2rem;
    position: absolute;
    right: 0;
    top: 2px;
}

.c-copyable-input__button.is-copied::after {
    content: 'Copied';
    background-color: #009966;
    border-radius: 5px;
    color: $colour-white;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 0.9rem;
    padding: 0.2rem 0.5rem;
    top: 0;
}
