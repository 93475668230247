//
// Grid
// =========================================

@use 'sass:math';
@use 'config' as *;
@use 'utility' as *;

//== Variables
//
$grid-columns: 12;
$gutter: 0.5rem;

// Container Mixin
@mixin grid($width: $container) {

    $container: $width;

    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    max-width: $container;
    margin: 0 -0.5rem;
}

// Column Mixin
@mixin column($columns, $breakpoint) {

    @include breakpoint($breakpoint) {
        $width: math.div(100%, $grid-columns) * $columns;

        flex: 1 1 $width;
        max-width: $width;
    }
}

//== Block
//

.l-grid {
    @include grid(1280px);
}

//== Elements
//

.l-grid__col {
    flex: 1 1 100%;
    padding-right: $gutter;
    padding-left: $gutter;
}

//create xsmall device + grid
/* stylelint-disable */
@for $i from 1 through $grid-columns {
    .l-grid__col--xs-#{$i} { @extend .l-grid__col; @include column($i, 'xs-plus'); };
}

//create small device + grid
@for $i from 1 through $grid-columns {
    .l-grid__col--sm-#{$i} { @extend .l-grid__col; @include column($i, 'sm-plus'); };
}

//create medium device + grid
@for $i from 1 through $grid-columns {
    .l-grid__col--md-#{$i} { @extend .l-grid__col; @include column($i, 'md-plus'); };
}

//create lg device + grid
@for $i from 1 through $grid-columns {
    .l-grid__col--lg-#{$i} { @extend .l-grid__col; @include column($i, 'lg-plus'); };
}

//create xl device + grid
@for $i from 1 through $grid-columns {
    .l-grid__col--xl-#{$i} { @extend .l-grid__col; @include column($i, 'xl-plus'); };
}
/* stylelint-enable */

//== Modifier
//

.l-grid--left {
    justify-content: flex-start;
}

.l-grid--right {
    justify-content: flex-end;
}

.l-grid--center {
    margin: 0 auto;
}

.l-grid--hover:hover {
    background-color: $colour-light-grey;
}
