//
// Alert
// ==========================================

@use 'config' as *;
@use 'utility' as *;
@use 'sass:color';

$_border-radius: 0;
$_border: 1px;
$_padding: $spacing-xs $spacing-xs $spacing-xs 40px;
$_bg-colour: $colour-light-grey;
$_border-colour: $colour-grey;
$_colour: $colour-text-colour;
$_success-colour: $colour-success-text;
$_warning-colour: $colour-warning-text;
$_error-colour: $colour-error-text;

.o-alert {
    display: flex;
    gap:  $_padding;
    margin-bottom: $spacing-m;
    padding: $_padding;
    background: $_bg-colour url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M1 21L12 2L23 21H1ZM11.35 15.3H12.85V9.7H11.35V15.3ZM12.1 18.075C12.3167 18.075 12.4958 18.0042 12.6375 17.8625C12.7792 17.7208 12.85 17.5417 12.85 17.325C12.85 17.1083 12.7792 16.9292 12.6375 16.7875C12.4958 16.6458 12.3167 16.575 12.1 16.575C11.8833 16.575 11.7042 16.6458 11.5625 16.7875C11.4208 16.9292 11.35 17.1083 11.35 17.325C11.35 17.5417 11.4208 17.7208 11.5625 17.8625C11.7042 18.0042 11.8833 18.075 12.1 18.075ZM3.6 19.5H20.4L12 5L3.6 19.5Z" fill="#{url-friendly-colour($_colour)}"/></svg>') no-repeat left 8px center;
    background-color: $_bg-colour;
    border: $_border solid $_border-colour;
    border-radius: $_border-radius;
    color: $_colour;
    text-align: left;

    p {
        margin-bottom: 0;;
    }

    a {
        color: inherit;
        text-decoration: underline;
    }
}

.o-alert--success {
    background: color.scale($_success-colour, $lightness: 95%) url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M1 21L12 2L23 21H1ZM11.35 15.3H12.85V9.7H11.35V15.3ZM12.1 18.075C12.3167 18.075 12.4958 18.0042 12.6375 17.8625C12.7792 17.7208 12.85 17.5417 12.85 17.325C12.85 17.1083 12.7792 16.9292 12.6375 16.7875C12.4958 16.6458 12.3167 16.575 12.1 16.575C11.8833 16.575 11.7042 16.6458 11.5625 16.7875C11.4208 16.9292 11.35 17.1083 11.35 17.325C11.35 17.5417 11.4208 17.7208 11.5625 17.8625C11.7042 18.0042 11.8833 18.075 12.1 18.075ZM3.6 19.5H20.4L12 5L3.6 19.5Z" fill="#{url-friendly-colour($_success-colour)}"/></svg>') no-repeat left 8px center;
    color: $_success-colour;
    border-color: $_success-colour;
}

.o-alert--validation {
    background: color.scale($_warning-colour, $lightness: 80%) url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M1 21L12 2L23 21H1ZM11.35 15.3H12.85V9.7H11.35V15.3ZM12.1 18.075C12.3167 18.075 12.4958 18.0042 12.6375 17.8625C12.7792 17.7208 12.85 17.5417 12.85 17.325C12.85 17.1083 12.7792 16.9292 12.6375 16.7875C12.4958 16.6458 12.3167 16.575 12.1 16.575C11.8833 16.575 11.7042 16.6458 11.5625 16.7875C11.4208 16.9292 11.35 17.1083 11.35 17.325C11.35 17.5417 11.4208 17.7208 11.5625 17.8625C11.7042 18.0042 11.8833 18.075 12.1 18.075ZM3.6 19.5H20.4L12 5L3.6 19.5Z" fill="#{url-friendly-colour($_warning-colour)}"/></svg>') no-repeat left 8px center;
    color: $_warning-colour;
    border-color: $_warning-colour;
}

.o-alert--error {
    background: $colour-error-background url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M1 21L12 2L23 21H1ZM11.35 15.3H12.85V9.7H11.35V15.3ZM12.1 18.075C12.3167 18.075 12.4958 18.0042 12.6375 17.8625C12.7792 17.7208 12.85 17.5417 12.85 17.325C12.85 17.1083 12.7792 16.9292 12.6375 16.7875C12.4958 16.6458 12.3167 16.575 12.1 16.575C11.8833 16.575 11.7042 16.6458 11.5625 16.7875C11.4208 16.9292 11.35 17.1083 11.35 17.325C11.35 17.5417 11.4208 17.7208 11.5625 17.8625C11.7042 18.0042 11.8833 18.075 12.1 18.075ZM3.6 19.5H20.4L12 5L3.6 19.5Z" fill="#{url-friendly-colour($_error-colour)}"/></svg>') no-repeat left 8px center;
    color: $_error-colour;
    border-color: $_error-colour;
}
