//
// User My Account
// ==========================================

@use 'config' as *;
@use 'utility' as *;

.c-my-account-block__title {
    color: #333333;
    font-size: 1.29em;
    font-weight: 400;
    margin-bottom: 10px;
}

.c-my-account-block {
    margin-left: auto;
    margin-right: auto;
}
