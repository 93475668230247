/**
 * Used to create a background colour for part of the page
 */

@use 'config' as *;
@use 'utility' as *;

.l-grey-background {
    padding: pixel-converter(30) 0;
    background: $colour-concrete;
}

.c-box {
    padding: 0 2rem;
}

.l-checkout-page {
    background-color: $colour-concrete;
    float: left;
    margin-bottom: 2rem;
    width: 100%;

    &__primary-column {
        float: left;
        margin-bottom: 2rem;
        width: 66.666%;
    }

    &__secondary-column {
        float: left;
        margin-bottom: 2rem;
        width: 33.332%;
    }

    @media screen and (max-width: 1200px) {
        &__primary-column {
            width: 50%;
        }

        &__secondary-column {
            width: 50%;
        }
    }

    @media screen and (max-width: 768px) {
        &__primary-column {
            float: none;
            width: 100%;
        }

        &__secondary-column {
            float: none;
            width: 100%;
        }
    }
}

.l-2fa-page {
    width: 100%;

    .btn-cancel {
        margin-right: 2rem;
        color: $colour-mine-shaft;
        background-color: #f2f2f2;
    }

    .btn-cancel:hover {
        margin-right: 2rem;
        color: inherit;
        background-color: #c9c9c9;
    }

    &__control-buttons {
        margin-top: 4rem;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    &__control-buttons-mobile {
        display: none;
    }

    .l-2fa-page__title {
        margin-top: 2rem;
    }

    &__container,
    &__setup-container {
        position: relative;
        border: 1px solid #c9c9c9;
        background-color: #f6f6f6;
        margin: 2rem 0;

        p {
            font-size: 1.2rem;
        }

        ul {
            margin-top: 1rem;
        }

        h4 {
            font-size: 1.4rem;
        }

        .mobile-button {
            display: none;
        }

        &--no-bottom-space {
            margin-bottom: 0;
        }

        &--no-bottom-border {
            border-bottom: 1px solid #f6f6f6; // work around edge cases with border rendering
        }

        &--no-top-space {
            margin-top: 0;
        }
    }

    &__setup-container-qr-code {
        margin-bottom: 2rem;
    }

    &__setup-container-qr-code svg {
        width: 400px;
        height: 400px;
    }

    &__container-top {
        margin: 1rem 2rem;
        padding-bottom: 1rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        vertical-align: center;
        justify-content: space-between;
        border-bottom: 1px solid #c9c9c9;

        h2 {
            margin: 0;
        }

        h4 {
            font-size: 1.2rem;
            max-width: 70%;
        }

        &--no-bottom-border {
            padding-bottom: 0;
            border-bottom: none;
        }
    }

    &__container-left {
        float: left;
        width: 50%;
        padding: 2rem;

        .back-button {
            position: absolute;
            bottom: 2rem;
            left: 2rem;
        }
    }

    &__container-full-width {
        width: 100%;
        padding: 1rem 2rem;
    }

    &__container-right {
        float: left;
        width: 50%;
        padding: 0 2rem 0 0;

        &-background-wrap {
            background-color: #f2f2f2;
            padding: 2rem 0 2rem 2rem;
            min-height: 310px;
        }
    }

    &__setup-container-right {
        float: left;
        width: 50%;

        &-background-wrap {
            background-color: #f2f2f2;
            padding: 2rem;
        }
    }

    &__setup-container-verification-form {
        padding: 2rem 0 2.5rem;

        &-error-message {
            color: $colour-maroon;
        }
    }

    &__setup-completed-icon {
        margin-top: 2rem;
        text-align: center;

        i {
            font-size: 250px;
        }
    }

    @media screen and (max-width: $screen-lg) {
        &__container-top h2 {
            font-size: 1.4rem;
        }
    }

    @media screen and (max-width: $screen-md) {
        &__container-left {
            float: none;
            width: 100%;
        }

        &__container-right {
            display: none;
        }

        &__setup-container-right {
            float: none;
            width: 100%;
        }
    }

    @media screen and (max-width: $screen-sm) {
        .l-2fa-page__title {
            font-size: 1.2rem;
        }

        &__container .mobile-button {
            display: block;
            width: 100%;
        }

        &__container-top h2 {
            font-size: 1.4rem;
        }

        &__container-top h4 {
            max-width: 100%;
        }

        &__container-top .btn {
            display: none;
        }

        &__container-left {
            float: none;
            width: 100%;
        }

        &__container-right {
            display: none;
        }

        &__setup-container-right {
            float: none;
            width: 100%;
        }

        &__control-buttons {
            display: none;
        }

        &__container-left .back-button {
            display: none;
        }

        &__control-buttons-mobile {
            display: block;

            .btn {
                margin-top: 2rem;
                width: 100%;
            }
        }
    }

    @media screen and (max-width: $screen-xs) {
        &__title {
            font-size: 1.2rem;
        }

        &__setup-container-qr-code svg {
            width: 200px;
            height: 200px;
        }
    }
}

.l-row {
    float: left;
    width: 100%;
    margin-bottom: 40px;
}

.e-wallet-page__container {
    margin: 0 auto;

    @include breakpoint(sm-plus) {
        width: 45rem;
    }

    @include breakpoint(md-plus) {
        width: 58rem;
    }

    @include breakpoint(lg-plus) {
        width: 71rem;
    }
}

.l-product-list-page__area--contents {
    margin: 0 auto;
    padding: 1rem;
    max-width: 1170px;
}

.l-product-list-page__area--controls,
.l-product-list-page__area--header {
    margin: 0 auto;
    padding: 1rem 1rem 0;
    max-width: 1170px;
}

.l-client-admin-page {
    margin: 0 auto;

    @include breakpoint(sm-plus) {
        width: 45rem;
    }

    @include breakpoint(md-plus) {
        width: 58rem;
    }

    @include breakpoint(lg-plus) {
        width: 71rem;
    }
}

.l-client-admin-page-row {
    margin-bottom: 2em;
}

/*
    Will become the standard layout container to be used
    to many containers are being with the same css.
    TODO: remove containers which has the same css properties below and apply
    this class as standard practice
*/

.l-container {
    @include container;
}
