//
// Cashback Enquiry Summary Glossary
// ==========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.c-ces-glossary {
    margin-bottom: 1rem;
    padding: 0.5rem;

    background-color: $colour-grey;
    font-family: $font-open-sans;

    @include breakpoint(sm-plus) {
        margin: 1rem 0 0;
    }

    // == Elements
    //

    &__domain {
        margin-bottom: 0.5rem;
    }

    &__term {
        font-weight: $headings-font-weight;
    }

    &__content-list {
        margin-top: 0.5em;
    }

    &__content-list-item {
        margin-bottom: 0.5em;
    }
}
