//
// Bulk upload error list
// ==========================================

@use 'config' as *;
@use 'utility' as *;

.c-bulk-upload-error-list__errors-for-row {
    list-style-type: none;
    padding-left: 0;
}

.c-bulk-upload-error-list__row-error {
    margin-bottom: 0;
}
