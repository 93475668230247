/**
 * Bullet list
 */

@use 'config' as *;
@use 'utility' as *;

//== Block
//

//== Elements
//

.bullet-list__title {
    margin-bottom: pixel-converter(10);

    &--small {
        font-size: pixel-converter(22);
    }
}

.bullet-list__items {
    padding: 0 0 0 pixel-converter(15);
}

.bullet-list__item {
    font-size: $font-size-base;
}

//== Modifier
//

.bullet-list--bg {
    background-color: $colour-light-grey;
    padding: 1.5rem;

    .bullet-list__title {
        margin-top: 0;
    }
}
