@use 'config' as *;
@use 'utility' as *;

.c-generic-table {
    margin-bottom: 1rem;
    width: 100%;
}

.c-generic-table__header {
    padding: 0.5rem;
    text-align: left;
}

.c-generic-table__row:nth-of-type(even) {
    background-color: #eeeeee;
}

.c-generic-table__row--header {
    border-bottom: 2px solid #333333;
}

.c-generic-table__cell {
    overflow: hidden;
    padding: 0.5rem;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.c-generic-table__cell--success {
    color: $colour-success-text;
}

.c-generic-table__cell--warning {
    color: $colour-warning-text;
}

.c-generic-table__cell--danger {
    color: $colour-punch;
}

.c-generic-table__cell--strong {
    font-weight: bold;
}
