//
// Cashback Enquiry Summary Claim Box
// ==========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.c-ces-claim {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 1rem;
    height: calc(100% - 1rem);

    background-color: $colour-sky;
    font-family: $font-open-sans;
    text-align: center;

    @include breakpoint(sm-plus) {
        margin: 1rem 0;
    }
}

// == Elements
//

.c-ces-claim__description {
    font-size: $font-size-small;
}

.c-ces-claim__button {
    align-self: center;
}
