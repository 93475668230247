//
// Reloadable card amount selector
// ==========================================

@use 'config' as *;
@use 'utility' as *;

// Headings.

.c-reloadable-card-amount-selector__heading {
    font-size: 1em;
    font-weight: normal;
}

.c-reloadable-card-amount-selector__nav {
    border: 1px solid $colour-darker-grey;
    border-radius: 5px;
    display: flex;
    font-size: 0.9rem;
    list-style-type: none;
    margin: 1em;
    padding: 0;
}

.c-reloadable-card-amount-selector__nav-item {
    border-right: 1px solid $colour-darker-grey;
    flex-grow: 1;
    margin: 0;
    padding: 0;
}

.c-reloadable-card-amount-selector__nav-item:last-of-type {
    border-right-width: 0;
}

.c-reloadable-card-amount-selector__nav-link {
    display: block;
    margin: 0;
    padding: 0.5em;
    text-align: center;
    color: $colour-darker-grey;
}

.c-reloadable-card-amount-selector__nav-link:hover,
.c-reloadable-card-amount-selector__nav-link:focus {
    text-decoration: none;
}

.c-reloadable-card-amount-selector__nav-link--active {
    background-color: $colour-darker-grey;
    color: $colour-white;
}

.c-reloadable-card-amount-selector__nav-link--active:hover,
.c-reloadable-card-amount-selector__nav-link--active:focus {
    color: #eeeeee;
}

.c-reloadable-card-amount-selector__top-up-section {
    display: none;
}

.c-reloadable-card-amount-selector__top-up-section--active {
    display: block;
    padding: 0 1em 1em;
}

.c-reloadable-card-amount-selector__top-up-card-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.c-reloadable-card-amount-selector__top-up-card-list-item {
    margin-bottom: 1em;
}

.c-reloadable-card-amount-selector__top-up-card-list-item:last-of-type {
    margin-bottom: 0;
}

.c-reloadable-card-amount-selector__top-up-card {
    border: 2px solid $colour-darker-grey;
    border-radius: 5px;
    display: block;
    padding: 0.8rem 1.8rem 0.8rem 0.8rem;
    position: relative;
    color: $colour-darker-grey;
}

.c-reloadable-card-amount-selector__top-up-card::after {
    content: '\f054';
    font-family: FontAwesome;
    position: absolute;
    right: 1rem;
    top: 1.5rem;
}

.c-reloadable-card-amount-selector__top-up-card:hover {
    text-decoration: none;
}

.c-reloadable-card-amount-selector__top-up-card-number {
    font-weight: $semi-bold-font-weight;
    display: block;
}

.c-reloadable-card-amount-selector__top-up-card-activation-date {
    color: $colour-mine-shaft;
    display: block;
    font-size: 0.9rem;
}

.c-reloadable-card-amount-selector__form {
    display: none;
}

.c-reloadable-card-amount-selector__form--active {
    display: block;
}

// Messages.

.c-reloadable-card-amount-selector__main-error-message {
    color: $colour-punch;
    font-size: 0.85em;
    line-height: 1;
}

.c-reloadable-card-amount-selector__main-error-message {
    margin-top: 1em;
}

.c-reloadable-card-amount-selector__form {
    padding: 0 1em 1em;
}

.c-reloadable-card-amount-selector__select-another-card-link {
    display: none;
    color: $colour-darker-grey;
}

.c-reloadable-card-amount-selector__select-another-card-link--active {
    display: block;
    padding-left: 1.25rem;
    position: relative;
}

.c-reloadable-card-amount-selector__select-another-card-link--active::after {
    content: '\f053';
    display: block;
    font-family: FontAwesome;
    position: absolute;
    left: 0;
    top: 0;
}

.c-reloadable-card-amount-selector__memorable-date-section {
    display: none;
}

.c-reloadable-card-amount-selector__memorable-date-section--active {
    display: block;
}

.c-reloadable-card-amount-selector__memorable-date-input {
    width: 100%;
}

.c-reloadable-card-amount-selector__top-up-card-number-heading {
    display: none;
}

.c-reloadable-card-amount-selector__top-up-card-number-heading--active {
    display: block;
    font-size: 1em;
    font-weight: bold;
}

.c-reloadable-card-amount-selector__submit-container {
    padding-bottom: 1em;
}

.c-reloadable-card-amount-selector__submit {
    width: 100%;
}

// using !important to overwrite the custom css
.c-reloadable-card-amount-selector__submit--disabled {
    background-color: $colour-grey !important;
    color: $colour-darker-grey !important;
    cursor: default;

    &:hover {
        background-color: $colour-grey !important;
        color: $colour-darker-grey !important;
        cursor: default;
    }
}

.c-reloadable-card-amount-selector__form-row {
    margin-bottom: 1em;
}

.c-reloadable-card-amount-selector__form-row--denomination {
    align-items: center;
    border: 1px solid $colour-darker-grey;
    display: flex;
    height: 3em;
    flex-grow: 1;
}

.c-reloadable-card-amount-selector__denomination-label {
    display: inline-block;
    font-size: 1.5em;
    font-weight: 700;
    padding: 0 0.8em;
}

.c-reloadable-card-amount-selector__denomination-input {
    border: 1px solid $colour-darker-grey;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-width: 0 1px;
    display: inline-block;
    font-size: 1.05em !important;
    flex-grow: 2;
    height: 100% !important;
    width: 50%;
}

.c-reloadable-card-amount-selector__amount-pence {
    background-color: $colour-grey;
    display: inline-block;
    flex-grow: 1;
    height: 100%;
    line-height: 3em;
    padding: 0 0.8em;
}

.c-reloadable-card-amount-selector__memorable-date-label {
    display: block;
}

.c-reloadable-card-amount-selector__memorable-date-help {
    font-size: 0.8rem;
}

// Non-JS fallback.

.c-reloadable-card-amount-selector--no-js {
    .c-reloadable-card-amount-selector__costs-summary {
        display: none;
    }

}
