//
// Header - legacy
// ==========================================
@use 'config' as *;
@use 'utility' as *;

.navbar li {
    margin: 0;
}
/* stylelint-disable selector-max-compound-selectors */
.navbar > .container .navbar-brand,
.navbar > .container-fluid .navbar-brand,
.container-fluid > .navbar-header,
.container > .navbar-container,
.container > .navbar-header {
    padding: 0;
    margin: 0;
}

.navbar-brand {
    background-color: $colour-white;
    font-family: 'Open Sans',  sans-serif;
    line-height: 89px;
    text-align: center;
    padding: 0;
    min-height: 92px;
    height: auto;
}

.navbar-brand img {
    display: inline;
}

.navbar-default {
    background-color: $colour-primary;
}

.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
    background-color: $colour-white;
}

.navbar-default .navbar-nav > li > a:hover {
    color: $colour-white;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover {
    background-color: #f6f6f6;
}

.navbar {
    color: $colour-white;
    border-radius: 0;
    border: 0;
    margin-bottom: 0;
}

.navbar .container,
.navbar .container-fluid {
    padding: 0;
}

.navbar .glyphicon-menu-down {
    font-size: 0.5em;
    line-height: 16px;
}

.nav-home,
.navbar-nav > li > a {
    font-size: 16px;
}

// mobile nav default colour
.navbar-default .navbar-nav > li > a {
    color: $colour-white;
}

.logged-out.navbar-default .navbar-nav > li > a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $spacing-xxs;
    height: 92px;
    padding-left: $spacing-l;
    padding-right: $spacing-l;
    color: $colour-sodexo-blue;
}

// for login mobile links
.logged-out.navbar-default .navbar-nav > li.nav-home > a {
    color: $colour-sodexo-blue;

    &:hover {
        background-color: $colour-sodexo-dark-blue;
        color: $colour-white;
    }
}

@media screen and (min-width:768px) {
    .logged-out.navbar-default .navbar-nav > li:not(.nav-home) > a {
        color: $colour-white;
    }
}

@media screen and (max-width:767px) {
    .logged-out .navbar-nav {
        background-color: $colour-white;
    }
}

.navbar-nav > li > a {
    padding: 0 $spacing-s;
}

// target icons
.navbar-nav  .fa {
    font-size: $font-size-xl;
}

.nav-home.bg-secondary:hover {
    background-color: $colour-sodexo-dark-blue;
    text-decoration: none;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
    background-color: transparent;
    color: inherit;
}

.navbar-nav ul {
    list-style: none;
}

.navbar .label-info {
    @include button-text-large;
    color: $colour-sodexo-dark-blue;
    margin-left: $spacing-xs;
    padding: $spacing-xs;
    background-color: $colour-secondary;
}

// to cater for the login/out and dashboard button
a.nav-home,
.nav-home a {
    position: relative;
    background-color: $colour-secondary;
    color: $colour-sodexo-dark-blue;
    padding: 0 $spacing-m;
    gap: $spacing-xs;

    &:hover {
        background-color: $colour-sodexo-dark-blue;
        color: $colour-white;
        text-decoration: none;
    }

    .fa {
        font-size: $font-size-xl;
    }
}

@media screen and (min-width:1025px) {
    .navbar {
        background-color: $colour-primary;
    }

    .navbar-default .navbar-nav > li > a,
    .nav-home {
        display: flex;
        align-items: center;
        gap: $spacing-xxs;
        height: 92px;
    }

    // for login/join mobile links
    .logged-out.navbar-default .navbar-nav > li > a {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: $spacing-xxs;
        height: 92px;
        color: $colour-white;
    }

    // for login mobile links
    .logged-out.navbar-default .navbar-nav > li.nav-home > a:hover {
        color: $colour-white;
    }

    .logged-out .nav-home a {
        padding-left: $spacing-l;
        padding-right: $spacing-l;
        color: white;
    }

    .nav-current {
        background-color: $colour-white !important;
        color: #555555 !important;
    }

    .navbar .open {
        position: relative;
        z-index: 10;
    }

    .navbar-default .navbar-nav > li > a:focus {
        color: $colour-white;
        text-decoration: underline;
        outline: thin dotted;
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
    }

    .navbar .dropdown-menu a:hover {
        color: #333333;
        text-decoration: none;
    }

    .navbar-nav > li > .dropdown-menu {
        margin-top: -10px;
    }

    .navbar .dropdown-menu {
        margin-left: -35%;
    }

    .navbar .dropdown-menu::before {
        content: '\f0d8';
        font: normal normal normal 30px/1 FontAwesome;
        position: absolute;
        top: -21px;
        left: 44%;
    }

    .nav-current + .dropdown-menu::before {
        display: none;
    }

    .navbar .dropdown-menu li a {
        color: $colour-primary;
        line-height: 1.39;
        display: block;
        padding: 0 20px;
    }

    .navbar .dropdown-menu .item {
        display: block;
        padding: 10px 12px;
    }

    .navbar .dropdown-menu li + li .item,
    .navbar .dropdown-menu .item + .item {
        border-top: 1px solid #f8f8f8;
    }

    .dropdown--account .dropdown-menu {
        min-width: 260px;
        margin-left: -60px;
    }

    .navbar .fa-angle-right {
        color: #b8b8b8;
    }

    .dropdown--categories .dropdown-menu {
        min-width: 300px;
        margin-left: -80px;
    }

    .dropdown-menu .fa-angle-right {
        line-height: 23px;
        float: right;
        margin-top: -30px;
    }

    .sub-dropdown-toggle {
        position: relative;
    }

    .navbar ul.dropdown-submenu {
        display: none;
        background-color: #333333;
        padding: 7px 10px;
        min-width: 265px;
        position: absolute;
        top: -2px;
        left: 98%;
    }

    .navbar ul.dropdown-submenu::before {
        content: '\f0d9';
        color: #333333;
        font: normal normal normal 30px/1 FontAwesome;
        position: absolute;
        top: 8px;
        left: -9px;
    }

    .navbar .open > .dropdown-submenu {
        display: block;
    }

    .navbar .dropdown-submenu li a {
        color: $colour-sodexo-light-blue;
        padding: 7px 20px;
    }

    .dropdown--retailers .dropdown-menu {
        padding: 0;
        min-width: 430px;
        margin-left: -140px;
    }

    .dropdown--retailers .dropdown-title {
        margin-top: 13px;
        margin-bottom: 0;
    }

    .dropdown--retailers .dropdown-menu ul {
        min-width: 160px;
        padding: 5px 0;
        margin: 2px 0 0;
    }

    .dropdown-title {
        color: #333333;
        font-size: 11px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 0 20px;
        margin-bottom: 5px;
    }

    .navbar .dropdown--retailers .item {
        padding: 5px 0;
    }

    .navbar .dropdown--retailers strong.item {
        display: block;
        padding-top: 15px;
        width: 100%;
    }

    .dropdown--retailers .fa-angle-right {
        margin-top: -25px;
    }

    .dropdown--retailers a:hover strong {
        text-decoration: underline;
    }

    .navbar .dropdown--a-z,
    .navbar .dropdown-submenu {
        background-color: #333333;
        padding: 10px;
    }

    .navbar .dropdown--a-z a,
    .navbar .dropdown-submenu a {
        color: $colour-sodexo-light-blue;
        display: block;
    }

    .navbar .dropdown--a-z a:hover,
    .navbar .dropdown-submenu a:hover {
        color: $colour-white;
    }

    .dropdown--a-z a {
        font-weight: bold;
        text-align: center;
        width: 33%;
        padding: 11px 20px 10px;
        float: left;
    }

    .dropdown--a-z a:focus {
        color: $colour-white;
    }

    .dropdown--icons .dropdown-menu {
        padding: 0;
        min-width: 265px;
        margin-left: -67px;
    }

    .navbar .dropdown--icons li a {
        font-family: 'Open Sans',  sans-serif;
        padding-left: 0;
        overflow: hidden;
    }

    .dropdown--icons .svg-wrapper {
        background-color: #f7f7f7;
        float: left;
        padding-top: 10px;
        padding-bottom: 10px;
        width: 26%;
    }

    .dropdown--icons .svg {
        display: block;
        width: 30px;
        height: 30px;
        margin: auto;
    }

    .navbar .dropdown--icons .item {
        padding-top: 15px;
        width: 74%;
        float: right;
    }

    .dropdown--benefits .dropdown-menu {
        margin-left: -50px;
    }

    .navbar .dropdown--benefits .item {
        padding: 10px 0 0;
        margin: 0 20px;
    }

    .navbar .dropdown--benefits .item p,
    .navbar .dropdown--benefits .item a {
        padding-right: 15px;
        padding-left: 15px;
    }

    .dropdown--benefits li a:hover {
        background: transparent;
    }

    .item a {
        margin-bottom: 10px;
    }

    li.dropdown--logout {
        display: none;
    }
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: $colour-white;
}

.navbar-default .navbar-toggle {
    border: none;
    margin-top: 1.7em;
    margin-right: 20px; // to match menu
}

// changing this to contain:paint so the widget can be sticky.
.page-wrapper {
    @include breakpoint(sm-neg) {
        overflow-x: hidden;
    }

    // commenting that out as it is breakign the popup
    // @include breakpoint(sm-plus) {
    //     contain: paint;

    //     .modal-open & {
    //         contain: unset;
    //     }
    // }
}

@media screen and (max-width:1024px) {
    .navbar {
        position: static;
        position: initial;
    }

    .logged-in .navbar-container {
        background-color: #1f1f1f;
        color: $colour-white;
        padding-top: 70px;
        padding-right: 30px;
        padding-bottom: 70px;
        width: 70%;
        max-width: 300px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: -71%;
        z-index: 20;
        transition: all 0.5s;
    }

    .navbar-container.active {
        right: 0;
    }

    .navbar-toggle {
        position: relative;
        z-index: 30;
    }

    .navbar-toggle span {
        transition: all 0.5s;
    }

    .navbar-toggle .bar-1 {
        transform-origin: 11px 5px;
        transform-origin: 11px 5px;
    }

    .navbar-toggle .bar-3 {
        transform-origin: 11px -2px;
        transform-origin: 11px -2px;
    }

    .navbar-toggle.active .bar-1 {
        transform: rotate(-226deg);
        transform: rotate(-226deg);
    }

    .navbar-toggle.active .bar-2 {
        opacity: 0;
    }

    .navbar-toggle.active .bar-3 {
        transform: rotate(226deg);
        transform: rotate(226deg);
    }

    .navbar-default .navbar-toggle:focus,
    .navbar-default .navbar-toggle:hover {
        background-color: transparent;
        border-color: transparent;
    }

    .nav-home {
        color: $colour-white;
    }

    .nav-home,
    .navbar-nav {
        font-size: 20px;
        margin: 0;
    }

    .logged-in .nav-home {
        padding: 0;
    }

    .logged-in .navbar-nav > li > a,
    .logged-in .nav-home {
        line-height: 50px;
    }

    .logged-in .nav-home:hover,
    .logged-in .navbar-nav > li > a:hover {
        color: $colour-white;
    }

    .logged-in .nav-home,
    .logged-in .navbar-nav {
        text-align: right;
        display: block;
    }

    .logged-in .nav-current {
        font-weight: bold;
    }

    .logged-in .navbar-header {
        float: none;
    }

    .logged-in .navbar-toggle {
        display: block;
    }

    .logged-in .navbar-nav {
        float: none !important;
        margin: 0 -15px;
    }

    .logged-in .navbar-nav > li {
        float: none;
    }

    .logged-in .navbar-nav > li > a {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .logged-in .navbar-container .sm-nofloat {
        float: none !important;
    }

    .logged-in .navbar-nav .glyphicon-menu-down,
    .logged-in .open > .dropdown-menu {
        display: none;
    }

    .navbar-default .navbar-nav > li > a:focus {
        color: $colour-white;
    }

}
