/**
 * Button
 */

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.btn {
    @include breakpoint(md-plus) {
        width: auto;
    }

    &:hover {
        color: $colour-white;
    }

    //== Modifiers
    //

    &--lg {
        min-height: pixel-converter(45);
        border-radius: $border-radius-large;
        font-size: pixel-converter(18);
        font-weight: 900;
    }

    &--full {
        width: 100%;
    }

    &--tertiary {
        &:hover {
            background: $colour-light-yellow;
        }
    }

    &--blue {
        background-color: $colour-light-blue;
    }

    &--user-account {
        background-color: $colour-turquoise;

        &:hover {
            background-color: $colour-maroon;
            border-color: $colour-maroon;
        }
    }
}
