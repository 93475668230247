//
// Landing deals
// ==========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.landing-deals {
    background-color: #edeae6;
    padding: 40px 0 65px;
}

.landing-deals h2 {
    margin-bottom: 0.6em;
}

.deal-items {
    padding: 20px 0 40px;
}

.deal-item {
    background-color: $colour-white;
    line-height: 140px;
    text-align: center;
    display: inline-block;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    border-radius: 50%;
    box-shadow: 0 0 8px #cccccc;
    margin: 10px 0;
    position: relative;
    overflow: hidden;
}

.deal-item .image-wrapper {
    text-align: center;
    width: 100%;
    position: absolute;
    position: relative\9;
    top: 50%;
    transform: translateY(-50%);
    transform: translateY(-50%);
    transform: translateY(-50%);
}

.deal-item img {
    max-width: 80%;
}
