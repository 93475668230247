//
// Product panel
// =========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.c-product-panel {
    background: $colour-white;
    border: 1px solid #ececec;
    display: flex;
    justify-content: flex-start;
    list-style-type: none;
    margin-bottom: 0.75rem;
    padding: 0.8rem;
}

.c-product-panel__image-section {
    margin-right: 1rem;
    max-height: 100px;
    max-width: 135px;
    min-width: 135px;
    position: relative;
}

.c-product-panel__product-image {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.c-product-panel__retailer-image {
    border-radius: 2px;
    object-fit: contain;
    position: absolute;
    left: 0.5rem;
    bottom: 0.5rem;
    max-width: 100px;
    max-height: 90px;
}

.c-product-panel__image-link:focus {
    width: 100%;
    height: 100%;
    display: block;
    border-color: $colour-black;
}

.c-product-panel__content-section {
    flex-grow: 2;
    margin-right: 1rem;
}

.c-product-panel__favourite-section {
    justify-self: flex-end;
}

.c-product-panel__title {
    font-size: 1.2rem;
    margin: 0 0 1rem;
}

.c-product-panel__title-link {
    color: #333333;
    margin: 0 0 1rem;
}

.c-product-panel__title-link:hover {
    text-decoration: none;
}

.c-product-panel__icons {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.c-product-panel__icon {
    display: inline-block;
    font-weight: bold;
    margin-right: 1rem;
}

.c-product-panel__retailer-link {
    display: block;
    margin-bottom: 1rem;
}

.c-product-panel__icon::before {
    color: #237399;
    font-family: FontAwesome;
    font-weight: normal;
    margin-right: 0.5rem;
}

.c-product-panel__icon--featured::before {
    content: '\f005';
}

.c-product-panel__icon--type-cashback::before {
    content: '\f0d6';
}

.c-product-panel__icon--type-evoucher::before {
    content: '\f02a';
}

.c-product-panel__icon--type-reloadable-card::before {
    content: '\f09d';
}

.c-product-panel__icon--type-shopping-voucher::before {
    content: '\f290';
}

.c-product-panel__icon--type-cinema-ticket::before {
    content: '\f008';
}

.c-product-panel__icon--type-promotion::before {
    content: '\f06b';
}

.c-product-panel__icon--type-physical-product::before {
    content: '\f288';
}

.c-product-panel__icon--type-application-form::before {
    content: '\f298';
}

.c-product-panel__icon--type-voucher-code::before {
    content: '\f029';
}

.c-product-panel__icon--type-flash-sale::before {
    content: '\f133';
}

.c-product-panel__icon--type-third-party::before {
    content: '\f152';
}

.c-product-panel__tags {
    list-style-type: none;
    padding: 0;
}

.c-product-panel__tag {
    color: #77c4ff;
    display: inline-block;
    margin: 0 1rem 0 0;
}

.c-product-panel__favourite-button {
    background-color: transparent;
    border-width: 0;
    color: #d9d9d9;
}

@media screen and (max-width: $screen-xs-min) {
    .c-product-panel {
        display: block;
        position: relative;
    }

    .c-product-panel__image-section {
        margin: 0 0 1rem;
        max-width: 100%;
        min-height: 100px;
        width: 100%;
    }

    .c-product-panel__product-image {
        max-height: 100px;
    }

    .c-product-panel__retailer-image {
        bottom: 1rem;
        left: 1rem;
    }

    .c-product-panel__icons {
        margin-bottom: 0;
    }

    .c-product-panel__tags {
        display: none;
    }

    .c-product-panel__favourite-section {
        position: absolute;
        right: 0.8rem;
        bottom: 0.8rem;
    }

}
