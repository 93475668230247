//
// Spacing
// --------------------------------------------------

// object and blocks spacing
$spacing-xxs: 4px;
$spacing-xs: 8px;
$spacing-s: 16px;
$spacing-m: 24px;
$spacing-l: 32px;

$touch-element-size: 40px;
$button-minimum-width: 44px;
$button-minimum-height: 44px;
