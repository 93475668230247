//
// Cashback Enquiry Raise Claim
// ==========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.c-rc {
    padding: 0.1rem 1rem 1rem;

    @include breakpoint(sm-plus) {
        padding-top: 2.8rem;
        margin: 0 auto;

        width: 45rem;
    }

    @include breakpoint(md-plus) {
        width: 58rem;
    }

    @include breakpoint(lg-plus) {
        width: 71rem;
    }
}

.c-cerc-layout {
    display: flex;
    flex-direction: row;

    @include breakpoint(sm-plus) {
        justify-content: space-between;
    }
}

// == Elements
//

.c-cerc__button {
    width: 100%;

    margin-bottom: 0.5rem;

    @include breakpoint(sm-plus) {
        width: auto;
    }
}
