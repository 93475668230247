//
// User account header
// ==========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.c-ua-header {
    background-color: $colour-primary;
    color: $colour-white;
    padding: 3.75rem 2rem 1.875rem;
}

//== Elements
//

.c-ua-header__button {
    background-color: $colour-maroon;
    border-color: $colour-maroon;
    color: $colour-white;

    &:hover {
        background-color: transparent;
        border-color: $colour-maroon;
        color: $colour-white;
    }
}
