//
// Error Message Splash
// ==========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.c-error-message-splash {
    background: $colour-white;
    border: 1px solid $colour-grey;
    margin: 1em auto;
    padding: 5em 0;
    text-align: center;
    width: 60%;
}
