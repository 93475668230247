//
// Client Admin FAQ Accordion
// ==========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.c-faq-list {
    padding-left: 1rem;
    padding-right: 1rem;
}

.c-faq-list__item-body {
    padding-left: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.c-faq-list__item-body--hidden {
    display: none;
}
