//
// Footer - legacy
// ==========================================

@use 'config' as *;
@use 'utility' as *;

.footer {
    background-color: #1f1f1f;
    color: #f6f6f6;
    font-size: 0.85em;
    padding-top: 12.8%;
    padding-bottom: 12.8%;
}

.footer .row {
    width: 95.28%;
    margin: auto;
}

.footer-nav {
    font-size: 0.92em;
    font-family: 'Open Sans',  sans-serif;
    font-weight: bold;
}

.footer-nav a {
    color: #77c4ff;
}

.nav-logos li {
    padding: 5px 0;
}

.nav-logos li + li {
    border-left: 1px solid #4c4c4c;
}

@media screen and (max-width:789px) {
    .footer {
        text-align: center;
    }

    .nav-logos {
        margin-top: 12%;
        float: none !important;
    }

    .nav-logos li {
        max-width: 33%;
    }

    .nav-logos img {
        max-width: 80%;
    }

}
@media screen and (min-width:790px) {
    .footer {
        padding-top: 5.07%;
        padding-bottom: 5.07%;
    }

    .footer-nav {
        font-size: 1.14em;
        font-weight: 500;
    }

    .nav-logos li {
        padding: 10px 14px;
    }

}
@media screen and (min-width:992px) {
    .nav-logos li {
        padding-right: 25px;
        padding-left: 25px;
    }

}
