@use 'config' as *;
@use 'utility' as *;

$_favourites-dropdown-height: 56px;

.favourites-bar {
    align-items: center;
    background-color: $colour-sodexo-dark-blue;
    border-radius: $spacing-xs;
    color: $colour-white;
    display: flex;
    flex-direction: column;
    gap: $spacing-s;
    justify-content: space-between;
    padding: $spacing-xs;

    @include breakpoint(sm-plus) {
        flex-direction: row;
        padding: $spacing-xs $spacing-xs $spacing-xs $spacing-s;
    }
}

.favourites-bar__text {
    display: flex;
    flex: 2 1 auto;
    flex-direction: column;
    gap: $spacing-xxs;
    justify-content: center;
    width: 100%;

    p {
        @include lineClamp(1);

        margin: 0;
        word-break: break-all;
        text-overflow: ellipsis;
    }
}

.favourites-bar__favourites {
    flex: 2 2 auto;
    position: relative;
    width: 100%;
}

.favourites-bar__favourites-dropdown {
    align-items: center;
    display: flex;
    font-size: $font-size-medium;
    height: $_favourites-dropdown-height;
    justify-content: space-between;
    min-width: 250px;
    padding: $spacing-s;
    width: 100%;

    &:hover {
        color: $colour-black;
    }

    @include breakpoint(xs-neg) {
        min-width: 180px;
        justify-content: center;
    }
}

.favourites-bar__favourites-icon {
    color: $colour-maroon;

    @include breakpoint(xs-neg) {
        display: none;
    }
}

.favourites-bar__favourites-dropdown-caret--rotate {
    transform: rotateZ(180deg);
}

.favourites-bar__favourites-list {
    background-color: $colour-white;
    border: 1px solid $colour-dark-grey;
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    display: none;
    font-size: $font-size-small;
    list-style: none;
    margin: 2px 0 0;
    padding: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
}

.favourites-bar__favourites-list--visible {
    display: block;
}

.favourites-bar__favourites-list-item {
    border-bottom: 0.5px solid $colour-grey;
    margin: 0;
    padding: $spacing-s $spacing-m;
    position: relative;

    a {
        @include lineClamp(3);

        color: $colour-mine-shaft;
        font-weight: $base-font-weight;
        max-width: 200px;
        padding: 0;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: pre-wrap;
        -webkit-box-orient: vertical;

        &:hover {
            background-color: transparent;
        }
    }
}
