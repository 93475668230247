//
// Landing new deals
// ==========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.landing-new-deals {
    padding: 35px 0 45px;
}

.landing-new-deals p {
    font-size: 1.125em;
    font-weight: 200;
}

.landing-new-deals .lead {
    font-size: 1.5em;
    font-weight: normal;
    margin-bottom: 0;
}

.landing-new-deals .btn {
    margin-top: 1em;
    margin-bottom: 0.8em;
}
