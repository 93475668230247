//
// Checkout Progress Indicator
// ==========================================

@use 'config' as *;
@use 'utility' as *;

.checkout-workflow {
    height: 115px;
}

.checkout-workflow__body {
    display: flex;
    align-items: center;
    padding: 40px 50px 0;
}

.checkout-workflow__circle {
    display: flex;
    height: 30px;
    width: 30px;
    justify-content: center;
    border: 4.5px solid $colour-dark-grey;
    border-radius: 100px;
}

.checkout-workflow__circle--reached {
    background-color: $colour-dark-grey;
}

.checkout-workflow__line {
    display: flex;
    height: 0;
    width: 80px;
    border: 2px solid $colour-dark-grey;
}

.checkout-workflow__label {
    position: relative;
    top: 35px;
    text-align: center;
    color: $colour-black;
}

.checkout-workflow__current-page {
    font-weight: bolder;
}

@media (max-width: 768px) {
    .checkout-workflow__line {
        width: 100px;
    }

    .checkout-workflow__body {
        margin: 0 auto;
        width: auto;
        padding: 40px 10% 0;
        align-items: center;
        justify-content: center;
    }
}
