@use 'config' as *;
@use 'utility' as *;


.product-info {
    padding: $spacing-m 0;
    margin: 0 $spacing-m;
    border-top: 1px solid $colour-dark-grey;

    @include breakpoint(md-plus) {
        padding: $spacing-l 0;
    }
}


.product-info__title {
    @include heading-small;

    padding-bottom: $spacing-s;
}

.product-info--with-side-header {
    @include breakpoint(md-plus) {
        display: flex;
        flex-direction: row;
        gap: $spacing-l;
    }

    .product-info__header {
        @include breakpoint(md-plus) {
            flex: 0 0 200px;
        }

        @include breakpoint(lg-plus) {
            flex: 0 0 300px;
        }
    }

    .product-info__content {
        @include breakpoint(md-plus) {
            flex: 1 1 auto;
        }
    }
}
