//
// panel-list - legacy code
// ==========================================

@use 'config' as *;
@use 'utility' as *;

.panel-list-group {
    padding: 0 15px;
}

@media (min-width: $screen-sm) {
    .panel-list-group {
        padding: 20px 15px;
    }

}

.panel-list-group > div {
    border-bottom: solid 1px #dfdfdf;
}

.panel-list-group div > div {
    padding: 10px;
}

.panel-list-group div:last-child {
    border-bottom: none;
}
