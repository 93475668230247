//
// Global settings
// --------------------------------------------------

$animation: 0.3s ease-in;
$border: 1px;

$site-max-width: 1440px;
$content-max-width: 800px;
$site-gutter: 24px;

// Bootstrap grid gutter
$grid-gutter-width: 30px;
$grid-gutter-width-no-unit: 30;

// Border widths
$border-width-extra-thin: 1px;
$border-width-thin: 2px;

// Border radius
$border-radius: 10px;
$border-radius-large: 15px;
$favourites-border-radius: 5px;
$button-border-radius: 8px;
