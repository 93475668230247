//
// Product View Claim Box
// ==========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.product-claim-box {
    background-color: $colour-white;

    @include breakpoint(sm-neg) {
        position: fixed;
        bottom: 0;
        z-index: 1;
        width: 100%;
        border-top: 1px solid $colour-dark-grey;
    }

    @include breakpoint(sm-plus) {
        position: sticky;
        top: $spacing-s;
    }

    @include breakpoint(h-sm-max) {
        position: initial;
    }
}

// == Elements
//

.product-claim-box__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $spacing-m;
    background-color: $colour-darker-grey;
    min-height: 200px;

    @include breakpoint(sm-neg) {
        display: none;
    }
}

.product-claim-box__title {
    @include body-text-large;

    color: $colour-white;
}

.product-claim-box__subtitle {
    @include body-text-large;

    color: $colour-white;
    background-color: $colour-darker-grey;
}

.product-claim-box__content {
    @include body-text-medium;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $spacing-s;
    padding: $spacing-m;
    text-align: center;

    @include breakpoint(sm-plus) {
        padding: $spacing-l $spacing-m;
    }
}

.product-claim-box__button {
    width: 100%;
}
