//
// Product View Hero
// ==========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.c-pv-hero__button {
    font-size: 3rem;

    color: $colour-white;
    border: none;
    background: none;
}
