//
// Product View Claim Box
// ==========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.c-product-view-claim-box {
    margin-bottom: 0;

    border: 1px solid $colour-grey;
    background-color: $colour-white;
    text-align: center;


    @include breakpoint(sm-plus) {
        position: absolute;
        top: 4rem;
        left: 58%;

        width: 300px;

        z-index: 3;
    }

    @include breakpoint(md-plus) {
        left: 65%;
    }

    @include breakpoint(lg-plus) {
        left: 60%;
        width: 340px;
    }
}

// == Elements
//

.c-product-view-claim-box__header {
    padding: 3.5rem 8% 1.3rem;
    font-size: 28px;

    @include breakpoint(sm-plus) {
        padding: 2rem 8%;

        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
    }

    @include breakpoint(md-plus) {
        padding: 0.5rem 1rem;
    }
}

.c-product-view-claim-box__content {
    padding: 2.5rem 2rem;

    min-height: 60px;

    word-wrap: break-word;

    @include breakpoint(md-plus) {
        padding: 1rem;
    }
}
