@use 'config' as *;

.sso-user-update-modal {
  display: none;
}

.sso-user-update-modal.is-open {
  display: block;
}

.sso-user-update-modal__visit-retailer {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
}

.sso-user-update-modal__visit-retailer-link {
  display: flex;
}

.sso-user-update-modal__visit-retailer-svg {
  margin-left: 10px;
}


.sso-user-update-modal__contact-details-saved {
  display: flex;
  color: $colour-white;
}

