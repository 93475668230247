@use 'config' as *;
@use 'utility' as *;

/**
 * Button object
 */

.o-button {
    @include button;
}

.o-button--secondary {
    background-color: $colour-lighter-grey;
    color: $colour-black;

    &:hover {
        background-color: $colour-grey;
        color: $colour-black;
    }
}

.o-button--disabled,
.o-button:disabled,
.o-button[disabled] {
    background-color: $colour-grey;
    color: $colour-white;

    &:hover {
        background-color: $colour-grey;
        color: $colour-white;
    }
}

.o-button--ghost {
    background-color: transparent;
    color: $colour-black;

    &:hover {
        background-color: transparent;
        color: $colour-black;
    }
}


