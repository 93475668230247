//
// Order Details Table
// =========================================

@use 'config' as *;
@use 'utility' as *;

.order-details {
  width: 100%;
}

.order-details__failed-message {
  padding: $spacing-xs 0;
  border-bottom: 1px solid $colour-grey;
  margin-bottom: $spacing-m;

}

.order-details__item,
.order-details__header {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.order-details__header {
  background-color: $colour-concrete;
  text-transform: uppercase;
  font-weight: bold;
}

.order-details__body,
.order-details__info {
  background-color: $colour-white;
}

.order-details__saving-image {
  width: 15px;
  height: 15px;

  @include breakpoint(md-plus) {
    margin-right: 8px;
  }

}

.order-details__header-description {
  padding: 12px;
}


.order-details__header-quantity,
.order-details__header-rrp,
.order-details__header-saving,
.order-details__header-cost {
  padding: 12px;
  border-left: solid 1px $colour-grey;
}

.order-details__header-description {
  display: none;

  @include breakpoint(md-plus) {
    display: initial;
  }
}

.order-details__header-description {
  @include breakpoint(md-plus) {
    flex: 1
  }
}

.order-details__header-quantity,
.order-details__header-rrp,
.order-details__header-saving,
.order-details__header-cost {
  width: 25%;

  @include breakpoint(md-plus) {
    width: 12%;
  }
}

.order-details__header-quantity,
.order-details__header-rrp,
.order-details__header-cost,
.order-details__footer-totals-title,
.order-details__footer-totals-rrp,
.order-details__footer-totals-cost {
  display: flex;
  flex-direction: column-reverse;
}

.order-details__header-saving,
.order-details__footer-totals-saving {
  background-color: $colour-peppermint;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;

  @include breakpoint(md-plus) {
    flex-direction: row;
  }
}

.order-details__header-quantity {
  text-align: center;
}

.order-details__header-rrp,
.order-details__header-saving,
.order-details__header-cost {
  text-align: right;
}

.order-details__item {
  border-bottom: 1px solid $colour-grey;
}

.order-details__item-description {
  padding: 12px;
}

.order-details__item-quantity,
.order-details__item-rrp,
.order-details__item-saving,
.order-details__item-cost {
  padding: 12px;
  border-left: 1px solid $colour-grey;
}

.order-details__item-quantity,
.order-details__item-rrp,
.order-details__item-saving,
.order-details__item-cost {

  width: 25%;

  @include breakpoint(md-plus) {
    width: 12%;
  }
}

.order-details__item-description {
  order: -1;
  width: 90%;

  @include breakpoint(md-plus) {
    order: initial;
    flex: 1;
  }
}

.order-details__item-image {
  width: 80px;
  padding-bottom: 4px;
}

.order-details__item-link {
  color: $colour-black;
  text-decoration: none;
}

.order-details__item-link:hover {
  text-decoration: underline;
}

.order-details__item-link:focus {
  color: $colour-endeavour;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.order-details__item-quantity {
  text-align: center;
}

.order-details__item-rrp,
.order-details__item-saving,
.order-details__item-cost {
  text-align: right;
}

.order-details__info {
  border-bottom: 1px solid $colour-grey;
  padding: 12px 12px;

  @include breakpoint(md-plus) {
    padding-left: 12px;
  }
}

.order-details__footer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background-color: $colour-concrete;
}

.order-details__footer-totals {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top: 3px solid $colour-black;
}

.order-details__footer-totals-title {
  flex: 1;
  padding: 12px 12px;
}


.order-details__footer-totals-rrp,
.order-details__footer-totals-saving,
.order-details__footer-totals-cost {
  padding: 12px 12px;
  width: 25%;

  @include breakpoint(md-plus) {
    width: 12%;
  }
}

.order-details__footer-totals-rrp,
.order-details__footer-totals-saving,
.order-details__footer-totals-cost {
  border-left: 1px solid $colour-grey;
}

.order-details__footer-totals-rrp,
.order-details__footer-totals-saving,
.order-details__footer-totals-cost {
  text-align: right;
}

.order-details__billing-address {
  margin-top: $spacing-l;
}
