//
// Jumbotron
// ==========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.jumbotron {
    background-size: cover;
    background-repeat: no-repeat;
    color: $colour-white;
    font-family: 'Open Sans',  sans-serif;
    padding-top: 60px;
    min-height: 450px;
    min-height: 342px\9;
    margin-bottom: 0;
    position: relative;
    background-position: center center;
}

.jumbotron.sm {
    min-height: 322px;
    min-height: 184px\9;
    padding-top: 90px;
}

.jumbotron p {
    font-size: 1.065em;
    line-height: 1.7;
}

.jumbotron .favourite-button {
    color: $colour-white;
    font-size: 45px;
}

.jumbotron .jumbotron-benefit-logo {
    max-width: 132px;
}

.landing-jumbotron-logo,
.jumbotron-logo-circle {
    background-color: $colour-white;
    line-height: 155px;
    width: 155px;
    height: 155px;
    border-radius: 50%;
    margin: auto;
    overflow: hidden;
}

.landing-jumbotron-logo img,
.jumbotron-logo-circle img {
    max-width: 140px;
    max-height: 90px;
}

.jumbotron-logo-circle {
    margin-bottom: 3em;
}

@media (min-width:992px) {
    .jumbotron h1 {
        font-size: 2.83em;
        text-shadow: 0 2px 6px rgba(0, 0, 0, 0.8);
    }

    .jumbotron.sm h1 {
        font-size: 3.875em;
        font-weight: 400;
        margin-top: 0;
        text-shadow: 0 2px 6px rgba(0, 0, 0, 0.8);
    }

    .jumbotron p {
        font-size: 1.125em;
        text-shadow: 0 2px 6px rgba(0, 0, 0, 0.8);
    }

}


.landing-jumbotron {
    //margin-bottom: 30px;
}

.landing-jumbotron::after {
    content: '';
    display: block;
    width: 100%;
    height: 5px;
    position: absolute;
    bottom: 0;
    z-index: 3;
}

.landing-jumbotron .btn {
    margin-top: 0.5em;
}

.landing-jumbotron .search {
    letter-spacing: -5px;
    padding: 0;
    width: 100%;
    max-width: 630px;
    height: 70px;
    margin: 35px auto;
    position: relative;
}

.landing-jumbotron .search label {
    letter-spacing: 1px;
}

.landing-jumbotron .search input[type=text] {
    line-height: 70px;
    display: inline-block;
    width: 89%;
    height: 100%;
    border: none;
}

.landing-jumbotron .search-go {
    vertical-align: top;
    display: inline-block;
    padding: 0.65em 0;
    width: 10%;
    height: 100%;
    min-width: 0;
    border: none;
    border-radius: 0;
    margin: 0;
}

@media (max-width:992px) {
    .landing-jumbotron .btn-primary {
        display: block;
    }

}
@media (min-width:992px) {
    .landing-jumbotron p {
        font-size: 1.375em;
    }

}
