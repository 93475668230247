/**
 * Breakpoint mixin
 *
 * To be used as follows:
 * @include breakpoint($screen-lg) {
 *     css properties
 * }
 */

@use 'config' as *;

@mixin breakpoint($point) {
    @if $point == xl-plus {
        @media (min-width: $screen-xl) { @content ; }
    }
    @if $point == xl-neg {
        @media (max-width: $screen-lg-max) { @content ; }
    }
    @if $point == lg-plus {
        @media (min-width: $screen-lg) { @content ; }
    }
    @if $point == lg-neg {
        @media (max-width: $screen-md-max) { @content ; }
    }
    @else if $point == md-plus {
        @media (min-width: $screen-md-min) { @content ; }
    }
    @else if $point == md-neg {
        @media (max-width: $screen-sm-max) { @content ; }
    }
    @else if $point == md-only {
        @media (min-width: $screen-md-min) and (max-width: $screen-md-max) { @content ; }
    }
    @else if $point == sm-plus {
        @media (min-width: $screen-sm-min) { @content ; }
    }
    @else if $point == sm-neg {
        @media (max-width: $screen-xs-max) { @content ; }
    }
    @else if $point == sm-only {
        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { @content ; }
    }
    @else if $point == xs-plus {
        @media (min-width: $screen-xs-min) { @content ; }
    }
    @else if $point == xs-neg {
        @media (max-width: $screen-xxs-max) { @content ; }
    }
    @else if $point == xs-only {
        @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) { @content ; }
    }
    @else if $point == xxs-plus {
        @media (min-width: $screen-xxs-min) { @content ; }
    }
    @else if $point == xxs-neg {
        @media (max-width: $screen-xxs-max) { @content ; }
    }
    @else if $point == xxs-only {
        @media (min-width: $screen-xxs-min) and (max-width: $screen-xxs-max) { @content ; }
    }
    @else if $point == h-sm-max {
        @media (max-height: 900px) { @content ; }
    }
    @else {
        @media (min-width: $point) { @content ; }
    }
}
