//
// bootstrap modal and carousel overwrite
// ==========================================

@use 'config' as *;
@use 'utility' as *;

.c-onboarding {
    &.modal.and.carousel {
        position: fixed; // Needed because the carousel overrides the position property
    }

    .modal-backdrop.in {
        z-index: 0;
    }

    .modal-dialog {

        @include breakpoint(sm-neg) {
            margin: 0;
            min-height: 100%;
            width: 100%;
        }

        @include breakpoint(sm-plus) {
            display: flex;
            align-items: center;
        }

        @include breakpoint(md-plus) {
            width: 800px;
        }
    }

    .modal-content {

        @include breakpoint(sm-neg) {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 100%;
            min-height: 100%;
        }

        @include breakpoint(sm-plus) {
            min-height: 600px;
        }

        @include breakpoint(md-plus) {
            min-height: 600px;
        }
    }

    .modal-footer {
        position: relative;
        background-color: $colour-white;
    }

    .modal-body {

        overflow: auto;

        @include breakpoint(sm-neg) {
            height: calc(100% - 95px); // minus footer and header
        }

        @include breakpoint(sm-plus) {
            padding-bottom: 85px;
        }

        @include breakpoint(md-plus) {
            padding: 20px 20px 95px;
        }
    }

    .carousel-caption {
        position: relative;
        top: auto;
        right: auto;
        left: auto;
        bottom: auto;
        padding: 16px 0;
        text-align: left;
        text-shadow: none;
        color: $colour-mine-shaft;
    }

    a.carousel-control {
        padding: 10px 16px 10px 12px;
        width: 100px;

        &.right {
            padding-right: 30px;
        }
    }

    .carousel-control {
        top: auto;
        bottom: 6px;
        left: 6px;
        display: block;
        font-size: 16px;
        width: auto;
        text-shadow: none;
        color: $colour-endeavour;
        white-space: nowrap;
        text-align: right;

        @include breakpoint(sm-plus) {
            bottom: 24px;
        }

        @include breakpoint(md-plus) {
            bottom: 24px;
            left: 41px;
        }

        @include breakpoint(sm-neg) {
            bottom: 15px;
        }

        .glyphicon {
            top: 50%;
            left: 8px;
            width: auto;
            height: auto;
            font-size: 14px;
            margin-top: -7px;
            margin-left: 0;
        }

        &:hover {
            color: darken($colour-endeavour, 10%);
        }

        button[type=submit] {
            font-weight: 300;
            font-size: 15px;
        }
    }

    .carousel-control.left {
        padding-left: 40px;
    }

    .carousel-control.right {
        right: 6px;
        left: auto;
        text-align: left;
        background-color: $colour-endeavour;
        color: $colour-white;

        @include breakpoint(md-plus) {
            right: 41px;
        }

        .glyphicon {
            right: 8px;
            left: auto;
            margin-right: 0;
        }

        &:hover {
            background-color: darken($colour-endeavour, 10%);
            color: $colour-white;
        }
    }

    .carousel-indicators {
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        padding: 16px;
        border-top: 1px solid $colour-grey;
        width: 100%;

        @include breakpoint(sm-plus) {
            padding: 40px 16px;
        }

        @include breakpoint(sm-neg) {
            bottom: 40px;
        }
    }

    .carousel-indicators li {
        width: 8px;
        height: 8px;
        margin: 0;
        border-color: $colour-endeavour;

        @include breakpoint(sm-plus) {
            width: 20px;
            height: 20px;
        }
    }

    .carousel-indicators .active {
        position: relative;
        width: 8px; //needed as bootstrap overwrites the default size
        height: 8px;

        @include breakpoint(sm-plus) {
            width: 20px;
            height: 20px;
        }
    }

    .carousel-indicators .active::before {
        position: absolute;
        content: '';
        top: 1px;
        left: 1px;
        width: 4px;
        height: 4px;
        border-radius: 8px;
        background-color: $colour-endeavour;

        @include breakpoint(sm-plus) {
            top: 2px;
            left: 2px;
            width: 14px;
            height: 14px;
        }
    }

    .carousel-indicators__image img {
        display: block;
        max-width: 100%;
        margin: 0 auto;
    }

    .c-onboarding-status {
        display: flex;
        justify-content: flex-end;
        padding-right: 38px;
        padding-bottom: 5px;
        min-height: 29px;
        position: relative;

        @include breakpoint(sm-neg) {
            padding-top: 5px;
            padding-right: 5px;
        }

        @include breakpoint(sm-plus) {
            bottom: 50px;
            padding-right: 5px;
        }

        @include breakpoint(md-plus) {
            padding-right: 38px;
            bottom: 70px;
        }

        label,
        input[type=checkbox] {
            z-index: 16;
        }

        label {
            font-size: 16px;
            font-weight: 300;
        }

        input[type=checkbox] {
            width: 16px;
            margin: 2px 0 0 5px;
        }
    }
}
