@use 'config' as *;
@use 'utility' as *;

.search-favourites-bar {
    padding: $spacing-m;
}

.search-favourites-bar__title {
    padding: $spacing-s 0;
    font-size: $font-size-h3;
}

.search-favourites-bar__components {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: $spacing-s;
    justify-content: center;

    @include breakpoint(md-plus) {
        flex-direction: row;
    }

    > * {
        flex: 1 0;
        width: 100%;
    }
}