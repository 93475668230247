
/**
 * button
 *
 * To be used as follows:
 * @include button;
 *
 */

@use 'config' as *;
@use 'body-text' as *;
@use 'breakpoint' as *;

@mixin button {
    @include button-text;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: $spacing-xs;
    padding: 14px $spacing-m;
    background-color: $colour-primary;
    border: none;
    border-radius: $button-border-radius;
    color: $colour-white;
    text-decoration: none;

    &:hover {
        background-color: $colour-secondary;
        color: $colour-sodexo-dark-blue;
        text-decoration: none;
    }
}
