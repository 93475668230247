@use 'config' as *;
@use 'utility' as *;

@media (max-width:767px) {
    .container {
        padding-right: 20px;
        padding-left: 20px;
    }

    .row {
        margin-left: -5px;
        margin-right: -5px;
    }

    .col-lg-1,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-md-1,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-sm-1,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-xs-1,
    .col-xs-10,
    .col-xs-11,
    .col-xs-12,
    .col-xs-2,
    .col-xs-3,
    .col-xs-4,
    .col-xs-5,
    .col-xs-6,
    .col-xs-7,
    .col-xs-8,
    .col-xs-9 {
        padding-left: 5px;
        padding-right: 5px;
    }

}
@media (min-width:1200px) {
    .seven-cols .col-sm-1,
    .seven-cols .col-md-1,
    .seven-cols .col-lg-1 {
        width: 14.285714285714285714285714285714%;
        *width: 14.285714285714285714285714285714%;
    }

}

.no-js .dropdown-toggle:hover + ul,
.no-js ul.dropdown-menu:hover {
    display: block;
}

.no-js .dropdown-menu {
    margin-top: 0;
}

.no-js .collapse {
    display: block;
}

html,
body {
    overflow: visible;
}

body {
    background-color: #f6f6f6;
    -ms-overflow-style: scrollbar;
}

.no-padding {
    padding: 0;
}

.container#content {
    outline: none;
}

@media screen and (max-width:767px) {
    .sm-nofloat {
        float: none !important;
    }

    .col-sm-no-margin {
        padding-left: 0;
        padding-right: 0;
        margin-left: -15px;
        margin-right: -15px;
    }

}

.text-primary {
    color: $colour-primary;
}

a.text-primary:hover,
a.text-primary:focus {
    color: $colour-primary;
}

.bg-primary {
    background-color: $colour-primary;
    color: $colour-white;
}

.bg-primary svg {
    fill: $colour-white;
}

a.bg-primary:hover,
a.bg-primary:focus {
    background-color: $colour-secondary;
}

.btn-primary {
    color: $colour-white;
    background-color: $colour-primary;
    border-color: $colour-primary;
}

.btn-primary:focus,
.btn-primary.focus {
    color: $colour-endeavour;
    background-color: $colour-secondary;
    border-color: $colour-secondary;
}

.btn-primary:hover {
    color: $colour-sodexo-dark-blue;
    background-color: $colour-secondary;
    border-color: $colour-secondary;
}

.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
    color: $colour-white;
    background-color: $colour-primary;
    border-color: $colour-primary;
}

.btn-primary:active:hover,
.btn-primary.active:hover,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.open > .dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.open > .dropdown-toggle.btn-primary.focus {
    color: $colour-sodexo-dark-blue;
    background-color: $colour-secondary;
    border-color: $colour-secondary;
}

.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
    background-image: none;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
    background-color: $colour-endeavour;
    border-color: $colour-endeavour;
}

.btn-primary .badge {
    color: $colour-endeavour;
    background-color: $colour-white;
}

.label-primary {
    background-color: $colour-endeavour;
}

.label-primary[href]:hover,
.label-primary[href]:focus {
    background-color: #286090;
}

.panel-primary {
    border-color: $colour-endeavour;
}

.panel-primary > .panel-heading {
    color: $colour-white;
    background-color: $colour-endeavour;
    border-color: $colour-endeavour;
}

.panel-primary > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: $colour-endeavour;
}

.panel-primary > .panel-heading .badge {
    color: $colour-endeavour;
    background-color: $colour-white;
}

.panel-primary > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: $colour-endeavour;
}

.text-secondary {
    color: $colour-light-yellow;
}

a.text-secondary:hover,
a.text-secondary:focus {
    color: #286090;
}

.bg-secondary {
    color: $colour-endeavour;
    background-color: $colour-light-yellow;
}

.bg-secondary a {
    color: $colour-endeavour;
}

.bg-secondary svg {
    fill: $colour-endeavour;
}

a.bg-secondary:hover,
a.bg-secondary:focus {
    background-color: #286090;
}

.bg-secondary .btn-transparent {
    color: $colour-endeavour;
    border-color: $colour-endeavour;
}

.bg-secondary .btn-transparent:hover {
    background-color: $colour-endeavour;
    color: $colour-light-yellow;
}

.btn-secondary {
    color: $colour-endeavour;
    background-color: $colour-light-yellow;
    border-color: $colour-light-yellow;
}

.btn-secondary:focus,
.btn-secondary.focus {
    color: $colour-endeavour;
    background-color: #286090;
    border-color: #122b40;
}

.btn-secondary:hover {
    color: $colour-light-yellow;
    background-color: $colour-endeavour;
    border-color: #204d74;
}

.btn-secondary:active,
.btn-secondary.active,
.open > .dropdown-toggle.btn-secondary {
    color: $colour-endeavour;
    background-color: #286090;
    border-color: #204d74;
}

.btn-secondary:active:hover,
.btn-secondary.active:hover,
.open > .dropdown-toggle.btn-secondary:hover,
.btn-secondary:active:focus,
.btn-secondary.active:focus,
.open > .dropdown-toggle.btn-secondary:focus,
.btn-secondary:active.focus,
.btn-secondary.active.focus,
.open > .dropdown-toggle.btn-secondary.focus {
    color: $colour-endeavour;
    background-color: #204d74;
    border-color: #122b40;
}

.btn-secondary:active,
.btn-secondary.active,
.open > .dropdown-toggle.btn-secondary {
    background-image: none;
}

.btn-secondary.disabled,
.btn-secondary[disabled],
fieldset[disabled] .btn-secondary,
.btn-secondary.disabled:hover,
.btn-secondary[disabled]:hover,
fieldset[disabled] .btn-secondary:hover,
.btn-secondary.disabled:focus,
.btn-secondary[disabled]:focus,
fieldset[disabled] .btn-secondary:focus,
.btn-secondary.disabled.focus,
.btn-secondary[disabled].focus,
fieldset[disabled] .btn-secondary.focus,
.btn-secondary.disabled:active,
.btn-secondary[disabled]:active,
fieldset[disabled] .btn-secondary:active,
.btn-secondary.disabled.active,
.btn-secondary[disabled].active,
fieldset[disabled] .btn-secondary.active {
    background-color: $colour-light-yellow;
    border-color: $colour-light-yellow;
}

.btn-secondary .badge {
    color: $colour-light-yellow;
    background-color: $colour-endeavour;
}

.label-secondary {
    background-color: $colour-light-yellow;
}

.label-secondary[href]:hover,
.label-secondary[href]:focus {
    background-color: #286090;
}

.panel-secondary {
    border-color: $colour-light-yellow;
}

.panel-secondary > .panel-heading {
    color: $colour-endeavour;
    background-color: $colour-light-yellow;
    border-color: $colour-light-yellow;
}

.panel-secondary > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: $colour-light-yellow;
}

.panel-secondary > .panel-heading .badge {
    color: $colour-light-yellow;
    background-color: $colour-endeavour;
}

.panel-secondary > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: $colour-light-yellow;
}

body.high-contrast .text-primary {
    color: #000000;
}

body.high-contrast a.text-primary:hover,
body.high-contrast a.text-primary:focus {
    color: #000000;
    text-decoration: underline;
}

body.high-contrast .bg-primary {
    color: $colour-white;
    background-color: #000000;
}

body.high-contrast a.bg-primary:hover,
body.high-contrast a.bg-primary:focus {
    background-color: $colour-white;
    text-decoration: underline;
}

body.high-contrast .btn-primary {
    color: $colour-white;
    background-color: #000000;
    border-color: #000000;
}

body.high-contrast .btn-primary:focus,
body.high-contrast .btn-primary.focus {
    color: $colour-white;
    background-color: #000000;
    border-color: #000000;
}

body.high-contrast .btn-primary:hover {
    color: $colour-white;
    background-color: #000000;
    border-color: #000000;
    text-decoration: underline;
}

body.high-contrast .btn-primary:active,
body.high-contrast .btn-primary.active,
body.high-contrast .open > .dropdown-toggle.btn-primary {
    color: $colour-white;
    background-color: #000000;
    border-color: #000000;
}

body.high-contrast .btn-primary:active:hover,
body.high-contrast .btn-primary.active:hover,
body.high-contrast .open > .dropdown-toggle.btn-primary:hover,
body.high-contrast .btn-primary:active:focus,
body.high-contrast .btn-primary.active:focus,
body.high-contrast .open > .dropdown-toggle.btn-primary:focus,
body.high-contrast .btn-primary:active.focus,
body.high-contrast .btn-primary.active.focus,
body.high-contrast .open > .dropdown-toggle.btn-primary.focus {
    color: $colour-white;
    background-color: #000000;
    border-color: #000000;
}

body.high-contrast .btn-primary:active,
body.high-contrast .btn-primary.active,
body.high-contrast .open > .dropdown-toggle.btn-primary {
    background-image: none;
}

body.high-contrast .btn-primary.disabled,
body.high-contrast .btn-primary[disabled],
body.high-contrast fieldset[disabled] .btn-primary,
body.high-contrast .btn-primary.disabled:hover,
body.high-contrast .btn-primary[disabled]:hover,
body.high-contrast fieldset[disabled] .btn-primary:hover,
body.high-contrast .btn-primary.disabled:focus,
body.high-contrast .btn-primary[disabled]:focus,
body.high-contrast fieldset[disabled] .btn-primary:focus,
body.high-contrast .btn-primary.disabled.focus,
body.high-contrast .btn-primary[disabled].focus,
body.high-contrast fieldset[disabled] .btn-primary.focus,
body.high-contrast .btn-primary.disabled:active,
body.high-contrast .btn-primary[disabled]:active,
body.high-contrast fieldset[disabled] .btn-primary:active,
body.high-contrast .btn-primary.disabled.active,
body.high-contrast .btn-primary[disabled].active,
body.high-contrast fieldset[disabled] .btn-primary.active {
    background-color: #000000;
    border-color: #000000;
}

body.high-contrast .btn-primary .badge {
    color: #000000;
    background-color: $colour-white;
}

body.high-contrast .label-primary {
    background-color: #000000;
}

body.high-contrast .label-primary[href]:hover,
body.high-contrast .label-primary[href]:focus {
    background-color: #000000;
}

body.high-contrast .panel-primary {
    border-color: #000000;
}

body.high-contrast .panel-primary > .panel-heading {
    color: $colour-white;
    background-color: #000000;
    border-color: #000000;
}

body.high-contrast .panel-primary > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #000000;
}

body.high-contrast .panel-primary > .panel-heading .badge {
    color: #000000;
    background-color: $colour-white;
}

body.high-contrast .panel-primary > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #000000;
}

body.high-contrast .text-secondary {
    color: #000000;
}

body.high-contrast a.text-secondary:hover,
body.high-contrast a.text-secondary:focus {
    color: #000000;
    text-decoration: underline !important;
}

body.high-contrast .bg-secondary {
    color: $colour-white;
    background-color: #000000;
}

body.high-contrast .bg-secondary a {
    color: #000000;
}

body.high-contrast a.bg-secondary:hover,
body.high-contrast a.bg-secondary:focus {
    background-color: #000000;
    text-decoration: underline !important;
}

body.high-contrast .bg-secondary .btn-transparent {
    color: $colour-white;
    border-color: $colour-white;
}

body.high-contrast .bg-secondary .btn-transparent:hover {
    background-color: #000000;
    color: $colour-white;
}

body.high-contrast .bg-secondary .btn-transparent:hover {
    text-decoration: underline !important;
}

body.high-contrast .btn-secondary {
    color: #000000;
    background-color: $colour-white;
    border-color: #000000;
}

body.high-contrast .btn-secondary:focus,
body.high-contrast .btn-secondary.focus {
    color: #000000;
    background-color: $colour-white;
    border-color: #000000;
}

body.high-contrast .btn-secondary:hover {
    color: $colour-white;
    background-color: #000000;
    border-color: #000000;
    text-decoration: underline !important;
}

body.high-contrast .btn-secondary:active,
body.high-contrast .btn-secondary.active,
body.high-contrast .open > .dropdown-toggle.btn-secondary {
    color: $colour-white;
    background-color: #000000;
    border-color: #000000;
}

body.high-contrast .btn-secondary:active:hover,
body.high-contrast .btn-secondary.active:hover,
body.high-contrast .open > .dropdown-toggle.btn-secondary:hover,
body.high-contrast .btn-secondary:active:focus,
body.high-contrast .btn-secondary.active:focus,
body.high-contrast .open > .dropdown-toggle.btn-secondary:focus,
body.high-contrast .btn-secondary:active.focus,
body.high-contrast .btn-secondary.active.focus,
body.high-contrast .open > .dropdown-toggle.btn-secondary.focus {
    color: $colour-white;
    background-color: #000000;
    border-color: #000000;
}

body.high-contrast .btn-secondary:active,
body.high-contrast .btn-secondary.active,
body.high-contrast .open > .dropdown-toggle.btn-secondary {
    background-image: none;
}

body.high-contrast .btn-secondary.disabled,
body.high-contrast .btn-secondary[disabled],
body.high-contrast fieldset[disabled] .btn-secondary,
body.high-contrast .btn-secondary.disabled:hover,
body.high-contrast .btn-secondary[disabled]:hover,
body.high-contrast fieldset[disabled] .btn-secondary:hover,
body.high-contrast .btn-secondary.disabled:focus,
body.high-contrast .btn-secondary[disabled]:focus,
body.high-contrast fieldset[disabled] .btn-secondary:focus,
body.high-contrast .btn-secondary.disabled.focus,
body.high-contrast .btn-secondary[disabled].focus,
body.high-contrast fieldset[disabled] .btn-secondary.focus,
body.high-contrast .btn-secondary.disabled:active,
body.high-contrast .btn-secondary[disabled]:active,
body.high-contrast fieldset[disabled] .btn-secondary:active,
body.high-contrast .btn-secondary.disabled.active,
body.high-contrast .btn-secondary[disabled].active,
body.high-contrast fieldset[disabled] .btn-secondary.active {
    background-color: #000000;
    border-color: #000000;
}

body.high-contrast .btn-secondary .badge {
    color: #000000;
    background-color: $colour-white;
}

body.high-contrast .label-secondary {
    background-color: #000000;
}

body.high-contrast .label-secondary[href]:hover,
body.high-contrast .label-secondary[href]:focus {
    background-color: #000000;
}

body.high-contrast .panel-secondary {
    border-color: #000000;
}

body.high-contrast .panel-secondary > .panel-heading {
    color: $colour-white;
    background-color: #000000;
    border-color: #000000;
}

body.high-contrast .panel-secondary > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #000000;
}

body.high-contrast .panel-secondary > .panel-heading .badge {
    color: #000000;
    background-color: $colour-white;
}

body.high-contrast .panel-secondary > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #000000;
}

.ie8-only {
    display: none;
    display: block\9;
}

@media screen and (max-width:767px) {
    .listpage-2-col-right-content {
        position: static;
    }

}
@media screen and (min-width:768px) {
    .listpage-2-col-left {
        width: 100%;
        float: left;
        margin-right: -330px;
    }

    .listpage-2-col-left-content {
        margin-right: 330px;
    }

    .listpage-2-col-right {
        width: 330px;
        float: right;
    }

}

.bg-grey {
    background-color: #edeae6;
    color: #333333;
}

.bg-white {
    background-color: white;
    color: #333333;
}

.bg-blue {
    background-color: $colour-endeavour;
    color: $colour-white;
}

.bg-lightgrey {
    background-color: #fafafa;
}

.bg-speckledgrey {
    background: #444349;
    color: $colour-white;
}

.bg-speckledgrey svg {
    fill: $colour-white;
}

.bg-grey svg,
.bg-whitex svg {
    fill: #333333;
}

.btn {
    font-family: 'Open Sans',  sans-serif;
    font-weight: 500;
    text-decoration: none;
    padding: 0.83em 1.38em;
    min-width: 12.5em;
    border-radius: 0;
}

.trailing-button-icon {
    margin-right: 0;
    margin-left: 1em;
}

.btn-default {
    font-size: 18px;
}

.btn-lg {
    font-weight: 300;
    padding-left: 1.25em;
    padding-right: 1.25em;
}

.btn-transparent {
    background-color: transparent;
    color: #333333;
    border: 2px solid;
}

.btn-transparent:hover,
.btn-transparent:focus {
    background-color: $colour-light-yellow;
    border-color: $colour-light-yellow;
    color: $colour-endeavour;
}

.btn-transparent.btn-blue {
    color: #006abb;
    border-color: #006abb;
}

.btn-transparent.btn-blue:hover,
.btn-transparent.btn-blue:focus {
    background-color: $colour-light-yellow;
    border-color: $colour-light-yellow;
    color: $colour-endeavour;
}

.btn-transparent.btn-white {
    color: $colour-white;
    border-color: $colour-white;
}

.btn-transparent.btn-white:hover,
.btn-transparent.btn-white:focus {
    background-color: $colour-light-yellow;
    border-color: $colour-light-yellow;
    color: $colour-endeavour;
}

.btn-group-md > .btn,
.btn-md {
    font-size: 16px;
    padding: 0.6em 1.38em;
    border-width: 1px;
}

.btn-group-sm > .btn,
.btn-sm {
    font-size: 14px;
    padding: 0.6em 1.38em;
    border-width: 1px;
}

.btn-group-xs > .btn,
.btn-xs {
    font-size: 14px;
    padding: 0.4em 1.2em;
    min-width: 0;
    border-width: 1px;
}

.btn-thin {
    min-width: 0;
}

@media (min-width:992px) {
    .btn-lg {
        padding-left: 2.9em;
        padding-right: 2.9em;
    }

    .btn-long {
        padding-left: 5em;
        padding-right: 5em;
        margin-bottom: 3px;
    }

}

.btn.btn-no-borders {
    border-color: transparent;
}

.call-us-box {
    background: #fcfcfc;
    color: #333333;
    font-size: 0.812em;
    text-align: left;
    padding: 10px 50px;
    margin-bottom: 10px;
}

.call-us-box .call-us-tel {
    font-size: 2.3em;
}

.call-us-box .call-us-tel:focus {
    text-decoration: underline;
}

.call-us-box p {
    margin: 0;
}

@media (min-width:768px) and (max-width:992px) {
    .call-us-box {
        background-image: none;
        padding: 10px 45px;
    }

}
@media (min-width:992px) {
    .call-us-box {
        background-position: 100% 0;
        padding: 10px 65px;
    }

}
@media (min-width:768px) {
    .call-us-box-square {
        background-position: right top;
        padding-top: 60px;
        height: 313px;
    }

}

a,
.link-color {
    color: #006abb;
}

p a {
    color: inherit;
    text-decoration: underline;
}

@media screen and (min-width:768px) {
    a[href^=tel] {
        color: inherit;
        text-decoration: none;
    }

}

ul,
ol {
    padding-left: 1.2em;
}

li {
    margin-bottom: 1.3em;
}

.list-inline > li {
    margin-bottom: 0;
}

.svg {
    display: inline-block;
}

.navbar .svg-shopping-voucher {
    background: url(../../images/svgs/menu-icons.jpg) no-repeat -15px -5px;
    background: linear-gradient(transparent, transparent),  url(../../images/svgs/shopping-voucher.svg) no-repeat 0 0;
}

.navbar .svg-giftcard {
    background: url(../../images/svgs/menu-icons.jpg) no-repeat -15px -60px;
    background: linear-gradient(transparent, transparent),  url(../../images/svgs/giftcard.svg) no-repeat 0 0;
}

.navbar .svg-cashback {
    background: url(../../images/svgs/menu-icons.jpg) no-repeat -15px -107px;
    background: linear-gradient(transparent, transparent),  url(../../images/svgs/cashback.svg) no-repeat 0 0;
}

.navbar .svg-voucher-codes {
    background: url(../../images/svgs/menu-icons.jpg) no-repeat -15px -156px;
    background: linear-gradient(transparent, transparent),  url(../../images/svgs/voucher-code.svg) no-repeat 0 0;
}

.navbar .svg-promotion {
    background: url(../../images/svgs/menu-icons.jpg) no-repeat -15px -205px;
    background: linear-gradient(transparent, transparent),  url(../../images/svgs/promotions.svg) no-repeat 0 0;
}

.navbar .svg-hot-offers {
    background: url(../../images/svgs/menu-icons.jpg) no-repeat -15px -254px;
    background: linear-gradient(transparent, transparent),  url(../../images/svgs/hot-offers.svg) no-repeat 0 0;
}

.navbar .svg-local-discounts {
    background: url(../../images/svgs/menu-icons.jpg) no-repeat -15px -306px;
    background: linear-gradient(transparent, transparent),  url(../../images/svgs/local-discounts.svg) no-repeat 0 0;
}

.fa-circle-thin.with-arrow {
    font-size: 32px;
    position: relative;
}

.fa-circle-thin.with-arrow::after {
    content: '\f105';
    font-size: 28px;
    display: inline-block;
    position: absolute;
    top: 1px;
    left: 10px;
}

.svg-cog {
    background: url(../../images/svgs/sprite.png) no-repeat -104px -1103px;
    background: linear-gradient(transparent, transparent),  url(../../images/svgs/cog.svg) no-repeat 0 0;
}

.svg-shopping-voucher {
    background: linear-gradient(transparent, transparent),  url(../../images/svgs/shopping-voucher.svg) no-repeat 0 0;
}

.svg-giftcard {
    background: linear-gradient(transparent, transparent),  url(../../images/svgs/giftcard.svg) no-repeat 0 0;
}

.svg-cashback {
    background: linear-gradient(transparent, transparent),  url(../../images/svgs/cashback.svg) no-repeat 0 0;
}

.svg-voucher-code {
    background: linear-gradient(transparent, transparent),  url(../../images/svgs/voucher-code.svg) no-repeat 0 0;
}

.svg-promotions {
    background: linear-gradient(transparent, transparent),  url(../../images/svgs/promotions.svg) no-repeat 0 0;
}

.svg-hot-offers {
    background: linear-gradient(transparent, transparent),  url(../../images/svgs/hot-offers.svg) no-repeat 0 0;
}

.svg-local-discounts {
    background: linear-gradient(transparent, transparent),  url(../../images/svgs/local-discounts.svg) no-repeat 0 0;
}

.svg-travel {
    background: linear-gradient(transparent, transparent),  url(../../images/svgs/travel.svg) no-repeat 0 0;
}

.svg-heart {
    background: linear-gradient(transparent, transparent),  url(../../images/svgs/favourite.svg) no-repeat 0 0;
}

.svg-fashion {
    background: linear-gradient(transparent, transparent),  url(../../images/svgs/fashion.svg) no-repeat 0 0;
}

.svg-cogs {
    background: linear-gradient(transparent, transparent),  url(../../images/svgs/cogs.svg) no-repeat 0 0;
}

.svg-home {
    background: linear-gradient(transparent, transparent),  url(../../images/svgs/home.svg) no-repeat 0 0;
}

.svg-guide {
    width: 92px;
    height: 104px;
}

.svg-shopping-voucher-guide {
    background: url(../../images/svgs/sprite.png) no-repeat 0 -1978px;
    background: linear-gradient(transparent, transparent),  url(../../images/svgs/guide-shopping-voucher.svg) no-repeat;
}

.svg-giftcard-guide {
    background: url(../../images/svgs/sprite.png) no-repeat 0 -1447px;
    background: linear-gradient(transparent, transparent),  url(../../images/svgs/guide-giftcard.svg) no-repeat 0 0;
}

.svg-cashback-guide {
    background: url(../../images/svgs/sprite.png) no-repeat 0 -1553px;
    background: linear-gradient(transparent, transparent),  url(../../images/svgs/guide-cashback.svg) no-repeat 0 0;
}

.svg-voucher-code-guide {
    background: url(../../images/svgs/sprite.png) no-repeat 0 -1659px;
    background: linear-gradient(transparent, transparent),  url(../../images/svgs/guide-voucher-code.svg) no-repeat 0 0;
}

.svg-promotions-guide {
    background: url(../../images/svgs/sprite.png) no-repeat 0 -1765px;
    background: linear-gradient(transparent, transparent),  url(../../images/svgs/guide-promotions.svg) no-repeat 0 0;
}

.svg-question-guide {
    background: url(../../images/svgs/sprite.png) no-repeat 0 -1871px;
    background: linear-gradient(transparent, transparent),  url(../../images/svgs/guide-question.svg) no-repeat 0 0;
}

.svg-cashback-questions {
    background: url(../../images/svgs/sprite.png) no-repeat -85px -44px;
    background: linear-gradient(transparent, transparent),  url(../../images/svgs/cashback-questions.svg) no-repeat 0 0;
    width: 45px;
    height: 53px;
    margin-bottom: 10px;
}

svg {
    max-width: 100%;
    max-height: 100%;
}

.svg-90 {
    width: 90px;
    height: 90px;
}

.svg-90,
.grid-menu-item .svg {
    background-image: none;
}

.svg-90.svg-shopping-vouchers,
.grid-menu-item .svg.svg-shopping-vouchers {
    background: transparent url(../../images/svgs/sprite.png) no-repeat 0 -287px \9;
}

.svg-90.svg-discount-cards,
.grid-menu-item .svg.svg-discount-cards {
    background: transparent url(../../images/svgs/sprite.png) no-repeat 0 -197px \9;
}

.svg-90.svg-cashback,
.grid-menu-item .svg.svg-cashback {
    background: transparent url(../../images/svgs/sprite.png) no-repeat -2px -104px \9;
}

.svg-90.svg-home,
.grid-menu-item .svg.svg-home {
    background: transparent url(../../images/svgs/sprite.png) no-repeat 0 -1103px \9;
}

.svg-90.svg-cogs,
.grid-menu-item .svg.svg-cogs {
    background: transparent url(../../images/svgs/sprite.png) no-repeat 0 -1195px \9;
}

.svg-90.svg-fashion,
.grid-menu-item .svg.svg-fashion {
    background: transparent url(../../images/svgs/sprite.png) no-repeat 0 -1288px \9;
}

.svg-120 {
    width: 122px;
    height: 122px;
    line-height: 200px;
}

.svg-120,
.grid-menu-item .svg {
    background-image: none;
}

.svg-120.svg-hot-offers,
.grid-menu-item .svg.svg-hot-offers {
    background: transparent url(../../images/svgs/sprite.png) no-repeat -1px -499px \9;
}

.svg-120.svg-local-discounts,
.grid-menu-item .svg.svg-local-discounts {
    background: transparent url(../../images/svgs/sprite.png) no-repeat 0 -380px \9;
}

.svg-120.svg-voucher-codes,
.grid-menu-item .svg.svg-voucher-codes {
    background: transparent url(../../images/svgs/sprite.png) no-repeat -1px -620px \9;
}

.svg-120.svg-promotions,
.grid-menu-item .svg.svg-promotions {
    background: transparent url(../../images/svgs/sprite.png) no-repeat -1px -740px \9;
}

.svg-120.svg-travel,
.grid-menu-item .svg.svg-travel {
    background: transparent url(../../images/svgs/sprite.png) no-repeat -1px -862px \9;
}

.svg-120.svg-heart,
.grid-menu-item .svg.svg-heart {
    background: transparent url(../../images/svgs/sprite.png) no-repeat 0 -983px \9;
}

.svg-120.svg-activity,
.grid-menu-item .svg.svg-activity {
    background: transparent url(../../images/svgs/sprite.png) no-repeat 0 -3086px \9;
}

.svg-120.svg-giftcard,
.grid-menu-item .svg.svg-giftcard {
    background: transparent url(../../images/svgs/sprite.png) no-repeat 0 -2597px \9;
}

.svg-120.svg-cashback,
.grid-menu-item .svg.svg-cashback {
    background: transparent url(../../images/svgs/sprite.png) no-repeat 0 -2718px \9;
}

.svg-120.svg-bank,
.grid-menu-item .svg.svg-bank {
    background: transparent url(../../images/svgs/sprite.png) no-repeat 0 -2963px \9;
}

.svg-120.svg-book,
.grid-menu-item .svg.svg-book {
    background: transparent url(../../images/svgs/sprite.png) no-repeat 0 -2840px \9;
}

.svg-120.svg-mail,
.grid-menu-item .svg.svg-mail {
    background: transparent url(../../images/svgs/sprite.png) no-repeat 0 -2475px \9;
}

.svg-120.svg-password,
.grid-menu-item .svg.svg-password {
    background: transparent url(../../images/svgs/sprite.png) no-repeat 0 -3208px \9;
}

.svg.svg-64 {
    width: 64px;
    height: 64px;
    font-size: 64px;
}

.svg.svg-64.svg-shopping-voucher {
    background: url(../../images/svgs/sprite.png) no-repeat -64px -2408px \9;
}

.svg.svg-64.svg-giftcard {
    background: url(../../images/svgs/sprite.png) no-repeat 0 -2083px \9;
}

.svg.svg-64.svg-cashback {
    background: url(../../images/svgs/sprite.png) no-repeat -64px -2083px \9;
}

.svg.svg-64.svg-voucher-code {
    background: url(../../images/svgs/sprite.png) no-repeat 0 -2147px \9;
}

.svg.svg-64.svg-promotions {
    background: url(../../images/svgs/sprite.png) no-repeat -64px -2147px \9;
}

.svg.svg-64.svg-hot-offers {
    background: url(../../images/svgs/sprite.png) no-repeat 0 -2212px;
}

.svg.svg-64.svg-local-discounts {
    background: url(../../images/svgs/sprite.png) no-repeat -67px -2212px \9;
}

.svg.svg-64.svg-giftcard-primary {
    background: url(../../images/svgs/sprite.png) no-repeat 0 -2083px \9;
}

.svg.svg-64.svg-cashback-primary {
    background: url(../../images/svgs/sprite.png) no-repeat -64px -2083px \9;
}

.svg.svg-64.svg-bank-primary {
    background: transparent url(../../images/svgs/sprite.png) no-repeat 0 -3393px \9;
}

.svg.svg-64.svg-email-primary {
    background: transparent url(../../images/svgs/sprite.png) no-repeat 0 -3458px \9;
}

.svg.svg-64.svg-password-primary {
    background: transparent url(../../images/svgs/sprite.png) no-repeat 0 -3328px \9;
}

.svg.svg-64.svg-book-primary {
    background: transparent url(../../images/svgs/sprite.png) no-repeat -67px -3393px \9;
}

.svg.svg-64.svg-guide-question-primary {
    background: transparent url(../../images/svgs/sprite.png) no-repeat 0 -3588px \9;
}

.svg.svg-64.svg-cashback-guide-primary {
    background: transparent url(../../images/svgs/sprite.png) no-repeat -67px -3588px \9;
}

.svg.svg-64.svg-travel {
    background: url(../../images/svgs/sprite.png) no-repeat 0 -2277px \9;
}

.svg.svg-64.svg-heart {
    background: url(../../images/svgs/sprite.png) no-repeat -65px -2275px \9;
}

.svg.svg-64.svg-fashion {
    background: url(../../images/svgs/sprite.png) no-repeat 0 -2342px \9;
}

.svg.svg-64.svg-cogs {
    background: url(../../images/svgs/sprite.png) no-repeat -64px -2342px \9;
}

.svg.svg-64.svg-home {
    background: url(../../images/svgs/sprite.png) no-repeat 0 -2406px \9;
}

.svg.svg-64.svg-activity {
    background: url(../../images/svgs/sprite.png) no-repeat -66px -3328px \9;
}

span.svg.svg-38 {
    width: 38px;
    height: 38px;
}

span.svg.svg-38.svg-shopping-voucher {
    background: url(../../images/svgs/sprite.png) no-repeat 0 0 \9;
}

span.svg.svg-38.svg-giftcard {
    background: url(../../images/svgs/sprite.png) no-repeat -40px 0 \9;
}

span.svg.svg-38.svg-cashback {
    background: url(../../images/svgs/sprite.png) no-repeat -80px 0 \9;
}

span.svg.svg-38.svg-voucher-code {
    background: url(../../images/svgs/sprite.png) no-repeat 0 -41px \9;
}

span.svg.svg-38.svg-promotions {
    background: url(../../images/svgs/sprite.png) no-repeat -40px -41px \9;
}

html {
    font-size: 1em;
}

body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 1em;
    color: #333333;
    font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
.title {
    font-family: 'Open Sans',  sans-serif;
    font-weight: 600;
}

h2,
h3,
.title {
    font-size: 2em;
    font-weight: 600;
}

.small-title {
    font-size: 1.68em;
}

@media (max-width:767px) {
    h2 {
        font-size: 1.5625em;
    }

    .sm-title-lg {
        font-size: 2em;
        line-height: 1;
    }

}

.bold {
    font-weight: bold;
}

.small {
    font-size: 0.75em;
}

.call-us-tel {
    font-size: 2.1875em;
}

.skip-menu a {
    background-color: $colour-white;
    color: #000000;
    padding: 0.5em;
}

.light-blue {
    color: #77c4ff !important;
}

.corner-ribbon {
    width: 180px;
    position: absolute;
    top: 27px;
    top: 0 \9;
    z-index: 3;
    left: -48px;
    left: 0 \9;
    text-align: center;
    padding-top: 2px;
    line-height: 36px;
    color: $colour-white;
    transform: rotate(-45deg);
    transform: rotate(-45deg);
    text-transform: uppercase;
    font-size: 14px;
}

.corner-ribbon.ribbon-sm {
    line-height: 20px;
    font-size: 8px;
    left: -64px;
    top: 14px;
}

.ribbon-blue {
    background: #59b7ff;
}

.ribbon-green {
    background: #5ee29b;
}

.ribbon-yellow {
    background: #f0d35d;
}

a.favourite-button {
    color: #d9d9d9;
    margin-left: 5px;
    background-color: transparent;
    border: none;
    text-decoration: none;
}

a.favourite-button.active {
    color: #e30613;
}

.tooltip.in {
    opacity: 1;
}

.tooltip.top {
    margin-top: -10px;
}

.tooltip.top .tooltip-arrow {
    border-top-color: #444349;
}

.tooltip .tooltip-inner {
    background: #444349;
    padding: 10px;
    border-radius: 2px;
    font-size: 13px;
}

.tooltip .tooltip-inner span {
    font-size: 12px;
}

.context-panel {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 0;
    border-color: #d5d5d5;
    margin-bottom: 30px;
}

.context-panel.panel-default .panel-heading {
    background-color: #f3f3f3;
}

.context-panel .panel-heading {
    text-align: center;
    padding: 52px 8% 20px;
    position: relative;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.context-panel .panel-heading h3 {
    font-size: 1.875em;
}

.context-panel .panel-heading::before {
    content: '';
    width: 23px;
    height: 13px;
    display: block;
    position: absolute;
    top: 17px;
    background: url(../../images/svgs/sprite.png) no-repeat -107px -1132px;
    left: 50%;
    margin-left: -11px;
}

.context-panel .panel-body {
    padding: 30px 8%;
}

.context-panel .panel-footer {
    background-color: #f3f3f3;
    text-align: center;
    padding: 22px 8%;
    font-size: 0.875em;
}

.context-panel .panel-footer a {
    color: inherit;
}

@media (min-width:768px) {
    .context-panel {
        margin-bottom: 11px;
    }

}

.context-panel-btn-only strong {
    color: #808080;
}

.context-panel-btn-only .btn {
    margin-bottom: 1em;
}

.context-panel-mobile-affix.affix .panel-heading,
.text-content-affixed.affix .panel-heading,
.text-content-affixed.affix-bottom .panel-heading,
.context-panel.modal.in .panel-heading,
.modal.in .context-panel .panel-heading {
    padding: 0 20px;
}

.context-panel-mobile-affix.affix .panel-heading::before,
.text-content-affixed.affix .panel-heading::before,
.text-content-affixed.affix-bottom .panel-heading::before,
.context-panel.modal.in .panel-heading::before,
.modal.in .context-panel .panel-heading::before {
    content: none;
}

.context-panel-mobile-affix.affix .panel-heading.panel-discount,
.text-content-affixed.affix .panel-heading.panel-discount,
.text-content-affixed.affix-bottom .panel-heading.panel-discount,
.context-panel.modal.in .panel-heading.panel-discount,
.modal.in .context-panel .panel-heading.panel-discount {
    height: auto;
}

.context-panel-mobile-affix.affix .panel-heading.panel-discount .discount,
.text-content-affixed.affix .panel-heading.panel-discount .discount,
.text-content-affixed.affix-bottom .panel-heading.panel-discount .discount,
.context-panel.modal.in .panel-heading.panel-discount .discount,
.modal.in .context-panel .panel-heading.panel-discount .discount {
    display: inline;
    padding: 0;
}

.context-panel-mobile-affix.affix .panel-heading.panel-discount .voucher,
.text-content-affixed.affix .panel-heading.panel-discount .voucher,
.text-content-affixed.affix-bottom .panel-heading.panel-discount .voucher,
.context-panel.modal.in .panel-heading.panel-discount .voucher,
.modal.in .context-panel .panel-heading.panel-discount .voucher {
    padding: 10px;
}

.context-panel-mobile-affix.affix .panel-heading.panel-discount .discount-value,
.context-panel-mobile-affix.affix .panel-heading.panel-discount .discount-percent,
.context-panel-mobile-affix.affix .panel-heading.panel-discount .discount-off,
.context-panel-mobile-affix.affix .panel-heading.panel-discount .voucher-value,
.text-content-affixed.affix .panel-heading.panel-discount .discount-value,
.text-content-affixed.affix .panel-heading.panel-discount .discount-percent,
.text-content-affixed.affix .panel-heading.panel-discount .discount-off,
.text-content-affixed.affix .panel-heading.panel-discount .voucher-value,
.text-content-affixed.affix-bottom .panel-heading.panel-discount .discount-value,
.text-content-affixed.affix-bottom .panel-heading.panel-discount .discount-percent,
.text-content-affixed.affix-bottom .panel-heading.panel-discount .discount-off,
.text-content-affixed.affix-bottom .panel-heading.panel-discount .voucher-value,
.context-panel.modal.in .panel-heading.panel-discount .discount-value,
.context-panel.modal.in .panel-heading.panel-discount .discount-percent,
.context-panel.modal.in .panel-heading.panel-discount .discount-off,
.context-panel.modal.in .panel-heading.panel-discount .voucher-value,
.modal.in .context-panel .panel-heading.panel-discount .discount-value,
.modal.in .context-panel .panel-heading.panel-discount .discount-percent,
.modal.in .context-panel .panel-heading.panel-discount .discount-off,
.modal.in .context-panel .panel-heading.panel-discount .voucher-value {
    position: relative;
    font-size: 2em;
}

.context-panel-mobile-affix.affix .panel-heading.panel-discount .discount-percent,
.text-content-affixed.affix .panel-heading.panel-discount .discount-percent,
.text-content-affixed.affix-bottom .panel-heading.panel-discount .discount-percent,
.context-panel.modal.in .panel-heading.panel-discount .discount-percent,
.modal.in .context-panel .panel-heading.panel-discount .discount-percent {
    font-size: 1.5em;
}

.context-panel-mobile-affix.affix .panel-heading.panel-discount .discount-type,
.text-content-affixed.affix .panel-heading.panel-discount .discount-type,
.text-content-affixed.affix-bottom .panel-heading.panel-discount .discount-type,
.context-panel.modal.in .panel-heading.panel-discount .discount-type,
.modal.in .context-panel .panel-heading.panel-discount .discount-type {
    display: none;
}

.context-panel .btn-close-model {
    background-color: transparent;
    color: $colour-white;
    border: 0;
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 1.5625em;
    z-index: 2;
}

.context-panel-mobile-affix.affix .panel-heading h3,
.text-content-affixed.affix .panel-heading h3,
.text-content-affixed.affix-bottom .panel-heading h3 {
    margin-top: 0;
}

.context-panel.collapse.in .panel-body,
.context-panel-mobile-affix.affix .panel-heading {
    padding: 0 1px;
}

.context-panel-mobile-affix.affix .panel-body {
    padding-top: 10px;
    padding-bottom: 10px;
}

.context-panel-mobile-affix.affix .panel-footer {
    display: none;
}

.context-panel-mobile-affix .btn:focus {
    background-color: $colour-endeavour;
    color: $colour-white;
}

@media screen and (max-width:767px) {
    .context-panel-mobile-height {
        min-height: 140px;
    }

    .panel.context-panel.collapse {
        position: fixed;
        top: 0;
        max-height: 100%;
        left: 0;
        right: 0;
        width: 100%;
        background-color: $colour-white;
        overflow-y: scroll;
        overflow-x: hidden;
        border-bottom: 100px;
        z-index: 10;
        -webkit-overflow-scrolling: touch;
        padding-bottom: 20px;
        box-shadow: 0 0 12px black;
        box-shadow: 0 0 12px black;
    }

    .panel.context-panel.collapse .slider-wrapper {
        max-width: none;
    }

}

table {
    width: 100%;
}

th,
td {
    vertical-align: top;
}

@media screen and (max-width:992px) {
    table td[class*=col-],
    table th[class*=col-] {
        float: left;
        display: block;
    }

}

.btn.like-button {
    background-color: transparent;
    min-width: 0;
    padding: 0.4em 0.8em;
    margin-right: 10px;
    color: #333333;
    font-size: 12px;
    text-decoration: none;
}

.btn.like-button i {
    margin-right: 0.8em;
}

a.like-button.active,
a.like-button:hover {
    color: $colour-white;
    background-color: #006abb;
}

.related-info-panel {
    border: 1px solid #d5d5d5;
    padding: 20px;
    margin-bottom: 11px;
}

.related-info-panel ul {
    margin-top: 10px;
}

.related-info-panel ul li {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    font-size: 0.875em;
}

.related-info-panel h3 {
    color: #808080;
    font-size: 1.125em;
    margin: 0;
}

.summary-panel .col-xs-8 {
    padding: 0;
}

.summary-panel .col-xs-2 {
    padding: 0;
    text-align: right;
}

.summary-panel .bottom-border {
    border-bottom: solid 1px #e5e5e5;
    padding-bottom: 0.5em;
}

.summary-panel p {
    margin: 0.5em 0 0;
}

.summary-panel .summary-purchased-item {
    margin: 0;
    color: #808080;
}

.summary-panel .summary-row p {
    color: #808080;
}

.summary-panel .summary-company-name,
.summary-panel .summary-price,
.summary-panel .total-row p {
    font-weight: 700;
}

.summary-panel .summary-savings p {
    margin: 0;
}

.summary-panel .summary-savings .summary-saving-amount span {
    font-weight: 700;
}

@media screen and (min-width:767px) {
    .modal-sm-only {
        display: block;
        position: static;
    }

}

.no-js .carousel-inner > .item {
    display: block;
}

.carousel-control.right,
.carousel-control.left {
    background-image: none;
    filter: alpha(opacity=100);
}

.carousel-control {
    width: 56px;
    opacity: 1;
}

.carousel-control:hover > span {
    background-color: $colour-dark-chambray;
}

.carousel-control .icon-prev,
.carousel-control .icon-next {
    background-color: #000000;
    font-size: 48px;
    width: 56px;
    height: 56px;
}

.carousel-control span.icon-prev {
    margin-left: 0;
    left: 0;
}

.carousel-control span.icon-next {
    margin-right: 0;
    padding-left: 3px;
    right: 0;
}

.carousel-control:focus .icon-prev,
.carousel-control:focus .icon-next {
    background-color: #006abb;
}

.carousel-control.carousel-control-disabled .icon-prev,
.carousel-control.carousel-control-disabled .icon-next {
    background-color: black;
    background-color: rgba(0, 0, 0, 0.1);
}

.responsive-carousel .carousel-indicators li {
    background-color: #cccac8;
    border: none;
    width: 11px;
    height: 11px;
    margin: 0 5px;
}

.responsive-carousel .carousel-indicators .active {
    background-color: #000000;
}

.responsive-carousel .carousel-control.left {
    left: -15px;
}

.responsive-carousel .carousel-control.right {
    right: -15px;
}

.responsive-carousel .carousel-control .icon-prev,
.responsive-carousel .carousel-control .icon-next {
    margin-top: -52px;
}

@media (min-width:768px) {
    .responsive-carousel .carousel-control.left {
        left: 0;
    }

    .responsive-carousel .carousel-control.right {
        right: 0;
    }
}
@media (min-width:1200px) {
    .responsive-carousel .carousel-control.left {
        left: -5px;
    }

    .responsive-carousel .carousel-control.right {
        right: -5px;
    }

}
@media (min-width:1200px) and (min-width:1240px) {
    .responsive-carousel .carousel-control.left {
        left: -26px;
    }

    .responsive-carousel .carousel-control.right {
        right: -26px;
    }
}
@media screen and (min-width:992px) and (max-width:1199px) {
    .responsive-carousel .col-xs-4 {
        max-width: 323px;
    }

}
@media screen and (min-width:768px) and (max-width:992px) {
    .responsive-carousel .col-xs-3 {
        max-width: 187px;
    }

}
@media screen and (min-width:768px) and (max-width:1199px) {
    .responsive-carousel .col-xs-2 {
        max-width: 161px;
    }

}

.cookie-control {
    font-weight: 100;
    padding: 15px 20px 15px 25px;
}

.cookie-control .pull-left {
    max-width: 80%;
}

.cookie-control a {
    color: #006abb;
    text-decoration: underline;
    display: block;
}

a.close-cookie-link {
    font-size: 35px;
    font-weight: 100;
    text-decoration: none;
}

@media screen and (min-width:768px) {
    .cookie-control {
        font-size: 18px;
        padding: 32px 35px 32px 55px;
    }

    .cookie-control p {
        line-height: 40px;
    }

    .cookie-control a {
        display: inline;
    }

}

.choose-favourites .favourite-item {
    display: block;
}

.choose-favourites .favourite-item .panel {
    height: 186px;
}

.choose-favourites .favourite-item.selected {
    color: $colour-endeavour;
}

.choose-favourites .favourite-item.selected .panel {
    border: 3px solid $colour-endeavour;
}

.choose-favourites .checkbox {
    height: 27px;
}

a.feature-panel {
    text-decoration: none;
}

.feature-panel {
    display: block;
    overflow: hidden;
}

.feature-panel .panel {
    border-radius: 0;
    border: 0;
    position: relative;
    margin-bottom: 0;
}

.feature-panel .panel-heading {
    position: relative;
    overflow: hidden;
    padding: 0;
    border: 0;
    border-radius: 0;
}

.feature-panel .panel-heading > img {
    width: 100%;
}

.feature-panel .panel-body {
    position: relative;
}

.feature-panel h3 {
    text-transform: uppercase;
}

.feature-panel hr {
    width: 46px;
    margin-top: 14px;
    margin-bottom: 14px;
}

.feature-panel .feature-content {
    font-family: 'Open Sans',  sans-serif;
}

.feature-panel .view-more {
    font-size: 0.75em;
    text-transform: uppercase;
}

.feature-panel .panel-body .company-logo {
    position: absolute;
    top: -52px;
    right: 15px;
    width: 82px;
    height: 82px;
    line-height: 82px;
    border-radius: 50%;
    background-color: $colour-white;
    text-align: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.feature-panel .panel-body .company-logo img {
    max-width: 80%;
}

@media (min-width:992px) {
    .no-touch .feature-panel:hover,
    .no-touch .feature-panel:focus {
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
        transition: box-shadow 0.1s ease-in;
    }

    .no-touch .feature-panel.feature-hover:hover .panel-body,
    .no-touch .feature-panel.feature-hover:focus .panel-body {
        position: absolute;
        background-color: black;
        background-color: rgba(0, 0, 0, 0.65);
        filter: alpha(opacity=80);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: auto;
        color: $colour-white;
        transition: background 0.1s ease-in;
        transition: background 0.1s ease-in;
    }

    .no-touch .feature-panel.feature-hover:hover .panel-body h3 span,
    .no-touch .feature-panel.feature-hover:hover .panel-body .feature-content,
    .no-touch .feature-panel.feature-hover:focus .panel-body h3 span,
    .no-touch .feature-panel.feature-hover:focus .panel-body .feature-content {
        color: $colour-white;
    }

    .no-touch .feature-panel.feature-hover:hover .panel-heading > img,
    .no-touch .feature-panel.feature-hover:focus .panel-heading > img {
        height: 100%;
        width: auto;
    }

}

.fp-benefits-sm {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.fp-benefits-sm .panel {
    height: 203px;
}

.fp-benefits-sm .panel-heading {
    height: 132px;
}

.fp-benefits-sm .panel-body {
    background-color: $colour-white;
    padding: 0 5.945%;
    min-height: 71px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.fp-benefits-sm .panel-body .company-logo {
    top: -72px;
}

.fp-benefits-sm .panel-body h3 {
    font-size: 1.125em;
    margin-top: 11px;
    margin-bottom: 3px;
}

.fp-benefits-sm .panel-body h3 span {
    font-size: 0.666em;
    color: #333333;
    font-weight: 700;
}

.fp-benefits-sm .feature-content {
    color: #333333;
}

.fp-benefits-lg {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.fp-benefits-lg .panel {
    height: 430px;
}

.fp-benefits-lg .panel-heading {
    height: 266px;
}

.fp-benefits-lg .panel-body {
    min-height: 164px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 18px;
}

.fp-benefits-lg .feature-content {
    margin: 10px 0;
}

@media (min-width:992px) {
    .no-touch .fp-benefits-lg:hover .panel-heading,
    .no-touch .fp-benefits-lg:focus .panel-heading {
        height: 430px;
    }

    .no-touch .fp-benefits-lg:hover .panel-body,
    .no-touch .fp-benefits-lg:focus .panel-body {
        padding-top: 182px;
    }

    .no-touch .fp-benefits-lg:hover .view-more,
    .no-touch .fp-benefits-lg:focus .view-more {
        position: absolute;
        bottom: 27px;
        width: 100%;
        left: 0;
    }

}

.feature-panel-sm {
    width: 170px;
    max-width: 100%;
    margin: 0 auto;
    color: inherit;
}

.feature-panel-sm .panel {
    background-color: transparent;
    height: 21em;
}

.feature-panel-sm .panel-heading {
    height: 186px;
}

.feature-panel-sm .panel-heading .company-logo {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: $colour-white;
    height: 75px;
    line-height: 75px;
    z-index: 10;
}

.feature-panel-sm .panel-heading .company-logo img {
    max-width: 75%;
    max-height: 80%;
}

.feature-panel-sm .panel-body {
    background-color: transparent;
    height: 8.375em;
}

.feature-panel-sm .panel-body h3 {
    margin-top: 0;
    margin-bottom: 4px;
    font-size: 1.375em;
    color: #006abb;
}

.feature-panel-sm .panel-body .company-name {
    margin-bottom: 12px;
    text-transform: uppercase;
    font-size: 0.75em;
}

.feature-panel-sm .panel-body .feature-content {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 0.875em;
}

@media (min-width:992px) {
    .no-touch .feature-panel-sm:hover .panel-heading,
    .no-touch .feature-panel-sm:focus .panel-heading {
        height: 21em;
    }

    .no-touch .feature-panel-sm:hover .panel-body h3,
    .no-touch .feature-panel-sm:focus .panel-body h3 {
        color: $colour-white;
    }

    .no-touch .feature-panel-sm:hover .panel-body,
    .no-touch .feature-panel-sm:focus .panel-body {
        padding-top: 60px;
        bottom: 75px;
    }

}

.feature-panel-lg .panel {
    height: 427px;
}

.feature-panel-lg .panel-heading {
    height: 221px;
}

.feature-panel-lg .panel-body {
    min-height: 206px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 25px;
    padding-left: 25px;
}

.feature-panel-lg .panel-body h3 {
    margin-top: 0;
    margin-bottom: 4px;
    font-size: 1.5625em;
}

.feature-panel-lg .panel-body .company-name {
    margin-bottom: 25px;
}

.feature-panel-lg .panel-body .feature-content {
    margin: 10px 0;
}

.feature-panel-lg .panel-body hr {
    width: 46px;
    margin-top: 19px;
    margin-left: 0;
}

@media (min-width:992px) {
    .no-touch .feature-panel-lg:hover .panel-heading,
    .no-touch .feature-panel-lg:focus .panel-heading {
        height: 427px;
    }

    .no-touch .feature-panel-lg:hover .panel-body,
    .no-touch .feature-panel-lg:focus .panel-body {
        padding-top: 180px;
        text-align: center;
    }

    .no-touch .feature-panel-lg:hover .panel-body .company-logo,
    .no-touch .feature-panel-lg:focus .panel-body .company-logo {
        top: 46px;
        width: 110px;
        height: 110px;
        line-height: 110px;
        left: 50%;
        margin-left: -55px;
    }

    .no-touch .feature-panel-lg:hover .panel-body hr,
    .no-touch .feature-panel-lg:focus .panel-body hr {
        margin: 0 auto;
    }

    .no-touch .feature-panel-lg:hover .view-more,
    .no-touch .feature-panel-lg:focus .view-more {
        position: absolute;
        bottom: 27px;
        width: 100%;
        left: 0;
        text-align: center;
    }

}

.filter-list-panel {
    background-color: $colour-white;
    margin-bottom: 10px;
}

.filter-list-panel .list-group .list-group-item {
    border-radius: 0;
    padding: 0;
    border-left: none;
    border-right: none;
    border-top: none;
    margin-bottom: 1px;
}

.filter-list-panel .list-group .list-group-item a {
    padding: 16px 20px;
    width: 100%;
    display: block;
    font-size: 0.875em;
}

.filter-list-panel .list-group .list-group-item a .fa {
    padding-top: 3px;
    font-size: 1.2em;
}

@media screen and (max-width:767px) {
    .filter-list-panel {
        position: fixed;
        top: 0;
        max-height: 100%;
        left: 0;
        width: 100%;
        background-color: $colour-white;
        overflow-y: scroll;
        overflow-x: hidden;
        border-bottom: 100px;
        z-index: 5;
        -webkit-overflow-scrolling: touch;
        padding-bottom: 20px;
        box-shadow: 0 0 12px black;
        box-shadow: 0 0 12px black;
    }

    .filter-list-panel h2 {
        margin: 0;
        padding: 20px;
    }

    .filter-list-panel h2 .header-close-btn {
        background: transparent;
        border: none;
    }

    .filter-list-panel .list-group .list-group-item a {
        color: #333333;
    }

    .filter-list-panel .list-group .list-group-item a.active {
        background-color: #f8f8f8;
    }

    .filter-list-panel .list-group .list-group-item a span {
        color: #acacac;
    }

    .filter-list-panel .mobile-filter-close-btn {
        margin: 0 20px;
        display: block;
    }

    .filter-list-panel .mobile-filter-close-btn .btn {
        width: 100%;
    }

}
@media screen and (min-width:768px) {
    .filter-list-panel {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        padding-bottom: 20px;
        height: auto !important;
    }

    .filter-list-panel.collapse {
        display: block;
    }

    .filter-list-panel h2,
    .filter-list-panel h3 {
        margin: 0;
        text-transform: uppercase;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-size: 0.875em;
        padding: 15px 30px;
    }

    .filter-list-panel h2 {
        background: #fafafa;
        margin: 0;
        color: #333333;
        padding: 15px 30px;
    }

    .filter-list-panel .svg-cog {
        width: 25px;
        height: 26px;
        margin-right: 5px;
        vertical-align: middle;
    }

    .filter-list-panel h3 {
        margin: 0;
        color: #9a9a9a;
        padding: 20px 30px 10px;
    }

    .filter-list-panel .list-group .list-group-item {
        border: none;
    }

    .filter-list-panel .list-group .list-group-item a {
        padding: 10px 30px;
        width: 100%;
        display: block;
        font-size: 0.875em;
        text-decoration: underline;
    }

    .filter-list-panel .list-group .list-group-item a.active {
        font-weight: bold;
        color: #333333;
        text-decoration: none;
    }

}
// .footer {
//     background-color: #1f1f1f;
//     color: #f6f6f6;
//     font-size: 0.85em;
//     padding-top: 12.8%;
//     padding-bottom: 12.8%;
// }

// .footer .row {
//     width: 95.28%;
//     margin: auto;
// }

// .footer-nav {
//     font-size: 0.92em;
//     font-family: "Open Sans",  sans-serif;
//     font-weight: bold;
// }

// .footer-nav a {
//     color: #77c4ff;
// }

// .nav-logos li {
//     padding: 5px 0;
// }

// .nav-logos li + li {
//     border-left: 1px solid #4c4c4c;
// }

// @media screen and (max-width:789px) {
//     .footer {
//         text-align: center;
//     }

//     .nav-logos {
//         margin-top: 12%;
//         float: none !important;
//     }

//     .nav-logos li {
//         max-width: 33%;
//     }

//     .nav-logos img {
//         max-width: 80%;
//     }

// }
// @media screen and (min-width:790px) {
//     .footer {
//         padding-top: 5.07%;
//         padding-bottom: 5.07%;
//     }

//     .footer-nav {
//         font-size: 1.14em;
//         font-weight: 500;
//     }

//     .nav-logos li {
//         padding: 10px 14px;
//     }

// }
// @media screen and (min-width:992px) {
//     .nav-logos li {
//         padding-right: 25px;
//         padding-left: 25px;
//     }

// }
label,
.label {
    cursor: pointer;
    color: inherit;
    font-size: 100%;
    font-weight: normal;
    text-align: left;
    padding: 0;
    margin-bottom: 0;
    border: 0;
}

input[type=checkbox],
input[type=radio] {
    vertical-align: middle;
    width: 22px;
    height: 22px;
    float: left;
}

.checkbox-label {
    vertical-align: middle;
    padding-left: 5px;
}

.checkbox input[type=checkbox],
.radio input[type=radio] {
    position: relative;
    margin-top: 0;
    margin-left: 0;
    margin-right: 5px;
}

.radio label {
    vertical-align: top;
    max-width: 80%;
}

fieldset legend.label {
    margin-bottom: 4px;
}

form .btn,
.form .btn {
    font-weight: 600;
}

form,
.form {
    font-size: 18px;
}

.form-container {
    padding-top: 45px;
    padding-bottom: 45px;
}

form:focus,
.form-container:focus {
    outline: 0;
}

input[type=text],
input[type=email],
input[type=password] {
    font-size: 16px;
    padding: 0 16px;
    height: 50px;
    line-height: 50px;
}

input[type=text].small,
input[type=email].small,
input[type=password].small {
    height: 35px;
    font-size: 14px;
    line-height: 35px;
}

.form-main {
    margin-top: 20px;
    margin-bottom: 25px;
}

.form-group {
    text-align: left;
    margin-bottom: 20px;
}

.form-group.small-bottom-margin {
    margin-bottom: 10px;
}

.form-control {
    border-color: #333333;
    border-radius: 1px;
}

.form-error-summary {
    display: none;
}

label.control-label {
    width: 100%;
}

.form-extrainfo,
.password-help,
.showpasswordlabel {
    font-size: 13px;
    font-weight: normal;
}

.form-extrainfo input[type=checkbox],
.password-help input[type=checkbox],
.showpasswordlabel input[type=checkbox] {
    margin-top: 0;
}

label + .form-extrainfo {
    margin-top: -5px;
}

.form-error-summarylabel {
    margin-bottom: 0;
}

.form-error-summary {
    background-color: #f2dede;
    color: #ff0000;
    padding: 14px 15px 22px;
    border-top: 4px solid #ff0000;
}

.form-error-summary .fa-exclamation-circle {
    font-size: 45px;
    margin-bottom: 3px;
}

.form-error-summary p {
    margin-bottom: 0;
}

.form-error-summary .error-small {
    font-size: 16px;
}

span.control-label,
label.control-label,
.has-error .control-label {
    color: #fe3434;
    font-size: 14px;
    font-weight: bold;
    display: block;
}

.has-error .form-control,
.has-error .form-control:focus {
    border-color: #fe3434;
}

.has-error .form-control {
    padding-right: 40px;
}

@media screen and (max-width:768px) {
    label {
        margin-bottom: 0;
    }

    .g-recaptcha {
        transform: scale(0.875);
        transform-origin: 0 0;
    }

}
@media screen and (min-width:768px) {
    .form-group--inline {
        display: inline-block;
        width: 49%;
    }
}
// .jumbotron {
//     background-size: cover;
//     background-repeat: no-repeat;
//     color: #fff;
//     font-family: "Open Sans",  sans-serif;
//     padding-top: 60px;
//     min-height: 450px;
//     min-height: 342px\9;
//     margin-bottom: 0;
//     position: relative;
//     background-position: center center;
// }

// .jumbotron.sm {
//     min-height: 322px;
//     min-height: 184px\9;
//     padding-top: 90px;
// }

// .jumbotron p {
//     font-size: 1.065em;
//     line-height: 1.7;
// }

// .jumbotron .favourite-button {
//     color: #fff;
//     font-size: 45px;
// }

// .jumbotron .jumbotron-benefit-logo {
//     max-width: 132px;
// }

// .landing-jumbotron-logo,
// .jumbotron-logo-circle {
//     background-color: #fff;
//     line-height: 155px;
//     width: 155px;
//     height: 155px;
//     border-radius: 50%;
//     margin: auto;
// }

// .landing-jumbotron-logo img,
// .jumbotron-logo-circle img {
//     max-width: 140px;
//     max-height: 90px;
// }

// .jumbotron-logo-circle {
//     margin-bottom: 3em;
// }

// @media (min-width:992px) {
//     .jumbotron h1 {
//         font-size: 2.83em;
//         text-shadow: 0px 2px 6px rgba(0,  0,  0,  0.8);
//     }

//     .jumbotron.sm h1 {
//         font-size: 3.875em;
//         font-weight: 400;
//         margin-top: 0;
//         text-shadow: 0px 2px 6px rgba(0,  0,  0,  0.8);
//     }

//     .jumbotron p {
//         font-size: 1.125em;
//         text-shadow: 0px 2px 6px rgba(0,  0,  0,  0.8);
//     }

// }
.page-wrapper {
    position: relative;
}

// .navbar li {
//     margin: 0;
// }

// .navbar > .container .navbar-brand,
// .navbar > .container-fluid .navbar-brand,
// .container-fluid > .navbar-header,
// .container > .navbar-container,
// .container > .navbar-header {
//     padding: 0;
//     margin: 0;
// }

// .navbar-brand {
//     background-color: #fff;
//     font-family: "Open Sans",  sans-serif;
//     line-height: 89px;
//     text-align: center;
//     padding: 0;
//     min-height: 92px;
//     height: auto;
// }

// .navbar-brand img {
//     display: inline;
// }

// .navbar-default {
//     background-color: $colour-endeavour;
// }

// .navbar-default .navbar-brand:hover,
// .navbar-default .navbar-brand:focus {
//     background-color: #fff;
// }

// .navbar-default .navbar-nav > li > a:hover {
//     color: #fff;
// }

// .navbar-default .navbar-nav > .active > a,
// .navbar-default .navbar-nav > .active > a:focus,
// .navbar-default .navbar-nav > .active > a:hover {
//     background-color: #f6f6f6;
// }

// .navbar {
//     color: #fff;
//     border-radius: 0;
//     border: 0;
//     margin-bottom: 0;
// }

// .navbar .container,
// .navbar .container-fluid {
//     padding: 0;
// }

// .navbar .glyphicon-menu-down {
//     font-size: 0.5em;
//     line-height: 16px;
//     vertical-align: text-top;
//     padding-top: 3px;
// }

// .nav-home,
// .navbar-nav > li > a {
//     font-family: "Open Sans",  sans-serif;
// }

// .navbar-default .navbar-nav > li > a,
// .nav-home {
//     line-height: 92px;
// }

// .navbar-default .navbar-nav > li > a {
//     color: inherit;
// }

// .logged-out .navbar-nav > li > a,
// .logged-out .nav-home {
//     padding: 0 45px 0 35px;
// }

// .logged-in .navbar-nav > li > a,
// .logged-in .nav-home {
//     padding: 0 16px;
// }

// .nav-home.bg-secondary:hover {
//     background-color: $colour-light-yellow;
//     color: #333;
//     text-decoration: none;
// }

// .navbar-default .navbar-nav > .open > a,
// .navbar-default .navbar-nav > .open > a:focus,
// .navbar-default .navbar-nav > .open > a:hover {
//     background-color: transparent;
//     color: inherit;
// }

// .navbar-nav ul {
//     list-style: none;
// }

// @media screen and (min-width:1025px) {
//     .navbar {
//         background-color: $colour-endeavour;
//     }

//     .nav-home {
//         background-color: $colour-light-yellow;
//         color: $colour-endeavour;
//     }

//     .nav-current {
//         background-color: #fff !important;
//         color: #555 !important;
//     }

//     .navbar .open {
//         position: relative;
//         z-index: 10;
//     }

//     .navbar-default .navbar-nav > li > a:focus {
//         color: #fff;
//         text-decoration: underline;
//         outline: thin dotted;
//         outline: 5px auto -webkit-focus-ring-color;
//         outline-offset: -2px;
//     }

//     .navbar .dropdown-menu a:hover {
//         color: #333;
//         text-decoration: none;
//     }

//     .navbar-nav > li > .dropdown-menu {
//         margin-top: -10px;
//     }

//     .navbar .dropdown-menu {
//         margin-left: -35%;
//     }

//     .navbar .dropdown-menu:before {
//         content: "\f0d8";
//         font: normal normal normal 30px/1 FontAwesome;
//         position: absolute;
//         top: -21px;
//         left: 44%;
//     }

//     .nav-current + .dropdown-menu:before {
//         display: none;
//     }

//     .navbar .dropdown-menu li a {
//         color: #006abb;
//         line-height: 1.39;
//         display: block;
//         padding: 0 20px;
//     }

//     .navbar .dropdown-menu .item {
//         display: block;
//         padding: 10px 12px;
//     }

//     .navbar .dropdown-menu li + li .item,
//     .navbar .dropdown-menu .item + .item {
//         border-top: 1px solid #f8f8f8;
//     }

//     .dropdown--account .dropdown-menu {
//         min-width: 260px;
//         margin-left: -60px;
//     }

//     .navbar .fa-angle-right {
//         color: #b8b8b8;
//     }

//     .dropdown--categories .dropdown-menu {
//         min-width: 300px;
//         margin-left: -80px;
//     }

//     .dropdown-menu .fa-angle-right {
//         line-height: 23px;
//         float: right;
//         margin-top: -30px;
//     }

//     .sub-dropdown-toggle {
//         position: relative;
//     }

//     .navbar ul.dropdown-submenu {
//         display: none;
//         background-color: #333;
//         padding: 7px 10px;
//         min-width: 265px;
//         position: absolute;
//         top: -2px;
//         left: 98%;
//     }

//     .navbar ul.dropdown-submenu:before {
//         content: "\f0d9";
//         color: #333;
//         font: normal normal normal 30px/1 FontAwesome;
//         position: absolute;
//         top: 8px;
//         left: -9px;
//     }

//     .navbar .open > .dropdown-submenu {
//         display: block;
//     }

//     .navbar .dropdown-submenu li a {
//         color: #59b7ff;
//         padding: 7px 20px;
//     }

//     .dropdown--retailers .dropdown-menu {
//         padding: 0;
//         min-width: 430px;
//         margin-left: -140px;
//     }

//     .dropdown--retailers .dropdown-title {
//         margin-top: 13px;
//         margin-bottom: 0;
//     }

//     .dropdown--retailers .dropdown-menu ul {
//         min-width: 160px;
//         padding: 5px 0;
//         margin: 2px 0 0;
//     }

//     .dropdown-title {
//         color: #333;
//         font-size: 11px;
//         font-weight: 600;
//         text-transform: uppercase;
//         padding: 0 20px;
//         margin-bottom: 5px;
//     }

//     .navbar .dropdown--retailers .item {
//         padding: 5px 0;
//     }

//     .navbar .dropdown--retailers strong.item {
//         display: block;
//         padding-top: 15px;
//         width: 100%;
//     }

//     .dropdown--retailers .fa-angle-right {
//         margin-top: -25px;
//     }

//     .dropdown--retailers a:hover strong {
//         text-decoration: underline;
//     }

//     .navbar .dropdown--a-z,
//     .navbar .dropdown-submenu {
//         background-color: #333;
//         padding: 10px;
//     }

//     .navbar .dropdown--a-z a,
//     .navbar .dropdown-submenu a {
//         color: #77c4ff;
//         display: block;
//     }

//     .navbar .dropdown--a-z a:hover,
//     .navbar .dropdown-submenu a:hover {
//         color: #fff;
//     }

//     .dropdown--a-z a {
//         font-weight: bold;
//         text-align: center;
//         width: 33%;
//         padding: 11px 20px 10px 20px;
//         float: left;
//     }

//     .dropdown--a-z a:focus {
//         color: #fff;
//     }

//     .dropdown--icons .dropdown-menu {
//         padding: 0;
//         min-width: 265px;
//         margin-left: -67px;
//     }

//     .navbar .dropdown--icons li a {
//         font-family: "Open Sans",  sans-serif;
//         padding-left: 0;
//         overflow: hidden;
//     }

//     .dropdown--icons .svg-wrapper {
//         background-color: #f7f7f7;
//         float: left;
//         padding-top: 10px;
//         padding-bottom: 10px;
//         width: 26%;
//     }

//     .dropdown--icons .svg {
//         display: block;
//         width: 30px;
//         height: 30px;
//         margin: auto;
//     }

//     .navbar .dropdown--icons .item {
//         padding-top: 15px;
//         width: 74%;
//         float: right;
//     }

//     .dropdown--benefits .dropdown-menu {
//         margin-left: -50px;
//     }

//     .navbar .dropdown--benefits .item {
//         padding: 10px 0 0 0;
//         margin: 0 20px;
//     }

//     .navbar .dropdown--benefits .item p,  .navbar .dropdown--benefits .item a {
//         padding-right: 15px;
//         padding-left: 15px;
//     }

//     .dropdown--benefits li a:hover {
//         background: transparent;
//     }

//     .item a {
//         margin-bottom: 10px;
//     }

// }
// .navbar-default .navbar-toggle .icon-bar {
//     background-color: #fff;
// }

// .navbar-default .navbar-toggle {
//     border: none;
//     margin-top: 1.7em;
//     margin-right: 2.5em;
// }

// @media screen and (max-width:1024px) {
//     body {
//     }

//     .page-wrapper {
//         overflow: hidden;
//     }

//     .navbar {
//         position: static;
//         position: initial;
//     }

//     .logged-in .navbar-container {
//         background-color: #1f1f1f;
//         color: #fff;
//         padding-top: 70px;
//         padding-right: 30px;
//         padding-bottom: 70px;
//         width: 70%;
//         max-width: 300px;
//         position: absolute;
//         top: 0;
//         bottom: 0;
//         right: -71%;
//         z-index: 20;
//         transition: all 0.5s;
//     }

//     .navbar-container .fa {
//         margin-right: 0.7em;
//     }

//     .navbar-container.active {
//         right: 0;
//     }

//     .navbar-toggle {
//         position: relative;
//         z-index: 30;
//     }

//     .navbar-toggle span {
//         transition: all 0.5s;
//     }

//     .navbar-toggle .bar-1 {
//         -webkit-transform-origin: 11px 5px;
//         transform-origin: 11px 5px;
//     }

//     .navbar-toggle .bar-3 {
//         -webkit-transform-origin: 11px -2px;
//         transform-origin: 11px -2px;
//     }

//     .navbar-toggle.active .bar-1 {
//         -webkit-transform: rotate(-226deg);
//         transform: rotate(-226deg);
//     }

//     .navbar-toggle.active .bar-2 {
//         opacity: 0;
//     }

//     .navbar-toggle.active .bar-3 {
//         -webkit-transform: rotate(226deg);
//         transform: rotate(226deg);
//     }

//     .navbar-default .navbar-toggle:focus,
//     .navbar-default .navbar-toggle:hover {
//         background-color: transparent;
//         border-color: transparent;
//     }

//     .nav-home {
//         color: #fff;
//     }

//     .nav-home,
//     .navbar-nav {
//         font-size: 20px;
//         margin: 0;
//     }

//     .logged-in .nav-home {
//         padding: 0;
//     }

//     .logged-in .navbar-nav > li > a,
//     .logged-in .nav-home {
//         line-height: 50px;
//     }

//     .logged-in .nav-home:hover,
//     .logged-in .navbar-nav > li > a:hover {
//         color: #fff;
//     }

//     .logged-in .nav-home,
//     .logged-in .navbar-nav {
//         text-align: right;
//         display: block;
//     }

//     .logged-in .nav-current {
//         font-weight: bold;
//     }

//     .logged-in .navbar-header {
//         float: none;
//     }

//     .logged-in .navbar-toggle {
//         display: block;
//     }

//     .logged-in .navbar-nav {
//         float: none !important;
//         margin: 0 -15px;
//     }

//     .logged-in .navbar-nav > li {
//         float: none;
//     }

//     .logged-in .navbar-nav > li > a {
//         padding-top: 10px;
//         padding-bottom: 10px;
//     }

//     .logged-in .navbar-container .sm-nofloat {
//         float: none !important;
//     }

//     .logged-in .navbar-nav .glyphicon-menu-down,
//     .logged-in .open > .dropdown-menu {
//         display: none;
//     }

//     .navbar-default .navbar-nav > li > a:focus {
//         color: #fff;
//     }

// }
// @media screen and (max-width:768px) {
//     .logged-out .navbar-nav {
//         background-color: #fff;
//         color: #333;
//         text-align: center;
//         margin: 0;
//     }

//     .logged-out .navbar-nav li a:hover {
//         color: #333;
//     }

//     .logged-out .navbar-nav .fa-lock {
//         margin-left: -20px;
//     }

// }
// @media screen and (min-width:1025px) and (max-width:1100px) {
//     .nav-basket,
//     .nav-search {
//         display: none;
//     }

// }
.page-indicator span {
    display: inline-block;
    background-color: #cccac8;
    border-radius: 50%;
    width: 11px;
    height: 11px;
    margin: 0 4px;
}

.page-indicator span.active {
    background-color: $colour-primary;
}

.page-header {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 0;
    margin-bottom: 45px;
    position: relative;
    border-bottom: 0;
}

.header-icon h1 {
    padding-left: 70px;
    font-weight: 400;
}

.header-icon p {
    padding-left: 70px;
}

.header-icon .svg {
    display: block;
    position: absolute;
    top: 72px;
    left: 5px;
}

@media screen and (min-width: $screen-md) {
    .header-icon h1 {
        max-width: 65%;
    }

    .header-icon p {
        max-width: 65%;
    }
}

.page-header .header-back-btn {
    position: absolute;
    top: 76px;
    right: 15px;
}

.header-wrapper-grey {
    background-color: #f3f3f3;
    border-bottom: 1px solid #e5e5e5;
}

.header-wrapper-grey .page-header {
    margin-bottom: 0;
}

.header-wrapper-grey .page-header h1 {
    text-transform: none;
    font-weight: 700;
}

@media screen and (max-width: $screen-sm-max) {
    .page-header .header-back-btn {
        display: none;
    }
}

@media screen and (max-width: $screen-sm) {
    .page-header h1 {
        font-size: 1.8rem;
        font-weight: 200;
        max-width: 100%;
        padding: 0;
    }

    .page-header .header-back-btn {
        display: none;
    }

    .page-header.header-icon {
        position: relative;
        padding: 2rem 1rem;
    }

    .page-header .svg {
        display: none;
    }
}

.text-content-page .page-header.header-title-only {
    padding-bottom: 50px;
}

.order-by-dropdown {
    margin-bottom: 10px;
}

.order-by-dropdown .dropdown .dropdown-menu {
    width: 100%;
}

.order-by-dropdown .dropdown .dropdown-menu li {
    margin: 0;
}

.order-by-dropdown .dropdown {
    margin-top: 5px;
}

.order-by-dropdown .dropdown .btn {
    text-align: left;
    font-size: 14px;
    width: 100%;
    padding: 7px 14px;
    position: relative;
}

.order-by-dropdown .dropdown .btn .caret {
    right: 12px;
    top: 45%;
    position: absolute;
}

@media screen and (min-width:768px) {
    .order-by-dropdown {
        text-align: right;
        background-color: #ececec;
        padding: 7px 9px;
        margin-bottom: 0;
    }

    .order-by-dropdown .dropdown {
        display: inline-block;
        text-transform: uppercase;
        margin-left: 12px;
        margin-top: 0;
    }

    .order-by-dropdown .dropdown .dropdown-menu {
        width: 210px;
    }

    .order-by-dropdown .dropdown .btn {
        text-transform: uppercase;
        width: 210px;
        font-size: 12px;
        font-weight: bold;
    }

}

.filter-list-panel {
    background-color: $colour-white;
    margin-bottom: 10px;
}

.filter-list-panel .list-group .list-group-item {
    border-radius: 0;
    padding: 0;
    border-left: none;
    border-right: none;
    border-top: none;
    margin-bottom: 1px;
}

.filter-list-panel .list-group .list-group-item a {
    padding: 16px 20px;
    width: 100%;
    display: block;
    font-size: 0.875em;
}

.filter-list-panel .list-group .list-group-item a .fa {
    padding-top: 3px;
    font-size: 1.2em;
}

@media screen and (max-width:767px) {
    .filter-list-panel {
        position: fixed;
        top: 0;
        max-height: 100%;
        left: 0;
        width: 100%;
        background-color: $colour-white;
        overflow-y: scroll;
        overflow-x: hidden;
        border-bottom: 100px;
        z-index: 5;
        -webkit-overflow-scrolling: touch;
        padding-bottom: 20px;
        box-shadow: 0 0 12px black;
        box-shadow: 0 0 12px black;
    }

    .filter-list-panel h2 {
        margin: 0;
        padding: 20px;
    }

    .filter-list-panel h2 .header-close-btn {
        background: transparent;
        border: none;
    }

    .filter-list-panel .list-group .list-group-item a {
        color: #333333;
    }

    .filter-list-panel .list-group .list-group-item a.active {
        background-color: #f8f8f8;
    }

    .filter-list-panel .list-group .list-group-item a span {
        color: #acacac;
    }

    .filter-list-panel .mobile-filter-close-btn {
        margin: 0 20px;
        display: block;
    }

    .filter-list-panel .mobile-filter-close-btn .btn {
        width: 100%;
    }

}
@media screen and (min-width:768px) {
    .filter-list-panel {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        padding-bottom: 20px;
        height: auto !important;
    }

    .filter-list-panel.collapse {
        display: block;
    }

    .filter-list-panel h2,
    .filter-list-panel h3 {
        margin: 0;
        text-transform: uppercase;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-size: 0.875em;
        padding: 15px 30px;
    }

    .filter-list-panel h2 {
        background: #fafafa;
        margin: 0;
        color: #333333;
        padding: 15px 30px;
    }

    .filter-list-panel .svg-cog {
        width: 25px;
        height: 26px;
        margin-right: 5px;
        vertical-align: middle;
    }

    .filter-list-panel h3 {
        margin: 0;
        color: #9a9a9a;
        padding: 20px 30px 10px;
    }

    .filter-list-panel .list-group .list-group-item {
        border: none;
    }

    .filter-list-panel .list-group .list-group-item a {
        padding: 10px 30px;
        width: 100%;
        display: block;
        font-size: 0.875em;
        text-decoration: underline;
    }

    .filter-list-panel .list-group .list-group-item a.active {
        font-weight: bold;
        color: #333333;
        text-decoration: none;
    }

}

.panel-list-item .panel-discount {
    padding: 10px;
    position: relative;
}

.panel-discount .panel-discount-inner {
    border: 3px dashed $colour-white;
    display: block;
    min-height: 120px;
    width: 100%;
    color: $colour-white;
    padding-top: 20px;
}

.panel-discount .discount {
    position: relative;
    padding-right: 35px;
    height: 62px;
    overflow: hidden;
    padding-top: 2px;
    display: inline-block;
    font-weight: bold;
}

.panel-discount .voucher {
    font-weight: bold;
}

.panel-discount .discount-value,
.panel-discount .voucher-value {
    font-size: 4.375em;
    line-height: 0.88;
}

.panel-discount .discount-percent {
    position: absolute;
    top: -5px;
    right: 0;
    font-size: 2.3em;
}

.panel-discount .discount-off {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 1em;
    text-transform: uppercase;
}

.panel-discount .discount-type,
.panel-discount .voucher-type {
    font-size: 0.875em;
    font-weight: 300;
    display: block;
}

.textcontent-sidecol .fa-envelope-o {
    color: $colour-endeavour;
}

@media screen and (min-width:992px) {
    .panel-list-item .panel-discount .panel-discount-inner {
        border: 1px dashed #858589;
        min-height: 200px;
        min-height: 150px\9;
        padding-top: 50px;
        padding-top: 60px\9;
    }

}

.panel-list {
    overflow: hidden;
    margin-bottom: 20px;
}

.panel-list-item {
    margin-top: 17px;
    border-radius: 0;
    width: 100%;
    margin-bottom: 0;
}

.panel-list-item .panel-heading {
    min-height: 120px;
    border: 0;
    padding: 0;
}

.panel-list-item .panel-body {
    padding: 20px 20px 30px;
    color: #333333;
    position: relative;
}

.panel-list-item .panel-body h2 {
    font-size: 1.125em;
    margin: 0 0 20px;
    width: 70%;
}

.panel-list-item .panel-body h2 .favourite-button {
    margin-left: 0;
}

.panel-list-item .panel-body h2 a:first-child {
    color: #000000;
    margin-right: 5px;
}

.panel-list-item .panel-body h3 {
    font-size: 1em;
    margin-bottom: 3px;
}

.panel-list-item .company-category {
    font-size: 0.875em;
    font-weight: 300;
}

.panel-list-item .panel-body .company-logo {
    position: absolute;
    top: 20px;
    right: 20px;
}

.panel-list-item .panel-body .company-logo img {
    max-width: 70px;
    max-height: 45px;
}

.panel-list-item .panel-body .sub-title {
    font-size: 0.75em;
}

.panel-list-item .panel-body .local-discounts-miles {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #ebebeb;
    font-size: 0.75em;
    color: #000033;
    font-weight: 700;
    text-transform: uppercase;
    padding: 6px 0.833em 4px;
}

@media screen and (min-width:992px) {
    .panel.panel-list-item {
        float: left;
        margin-top: 11px;
        border-radius: 0;
    }

    .panel-list-item .panel-heading {
        float: left;
        width: 33.42%;
        min-height: 200px;
        min-height: 160px\9;
    }

    .panel-list-item .panel-body {
        float: left;
        width: 66.58%;
        min-height: 200px;
        min-height: 160px\9;
    }

    .panel-list-item .panel-body h2 {
        font-size: 1.375em;
        text-transform: uppercase;
        margin-bottom: 3px;
    }

    .panel-list-item .panel-body .sub-title {
        margin-bottom: 15px;
    }

    .panel-list-item .panel-body .company-logo img {
        max-width: 100px;
        max-height: 100px;
        float: right;
    }

    .panel-list-item .panel-body .view-more {
        font-size: 0.875em;
    }

}
@media screen and (min-width:1200px) {
    .panel-list-item .panel-body {
        padding: 30px;
    }

    .panel-list-item .panel-body .company-logo {
        top: 30px;
        right: 19px;
    }

}

.benefits-page .panel-list-item .panel-heading {
    min-height: 0;
}

.benefits-page .panel-list-item .panel-body h2 {
    width: 100%;
}

.benefits-page .benefit-list-promo {
    display: block;
    position: relative;
    overflow: hidden;
    height: 180px;
}

.benefits-page .benefit-list-promo-image {
    width: 100%;
}

.benefits-page .benefit-list-promo-logo {
    text-align: center;
    width: 100%;
    height: 180px;
    position: absolute;
    top: 0;
    left: 0;
}

.benefits-page .benefit-list-promo-logo img {
    width: auto;
    max-width: 60%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    transform: translateY(-50%);
    transform: translateY(-50%);
}

.benefits-page .panel-list-item .panel-body {
    min-height: 180px;
}

.benefits-page .panel-list-item .panel-body .benefit-list-content {
    font-size: 0.875em;
}

@media (min-width:992px) {
    .benefits-page .benefit-list-promo-image {
        width: 110%;
        margin-top: -5%;
        margin-left: -5%;
        transition: all 0.5s;
    }

}

.well {
    background-color: #f3f3f3;
    border-color: #dbdbdb;
}

.pagination {
    margin: 40px 0 20px;
    border-radius: 1px;
}

.pagination > .active > a,
.pagination > .active > span {
    background-color: #006abb;
}

.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
    background-color: $colour-light-yellow;
}

.pagination > li > a,
.pagination > li > span {
    color: #333333;
    border-color: #bdbdbd;
}

.pagination > li:first-child a,
.pagination > li:last-child a {
    color: #006abb;
}

.subpage-tabs li {
    padding: 0;
    width: 100%;
}

.subpage-tabs li + li {
    border-top: 1px solid #dbdbdb;
}

.subpage-tabs a {
    text-align: center;
    display: block;
    padding: 10px;
}

.subpage-tabs a .svg {
    display: none;
}

.subpage-tabs .tab-name {
    font-size: 0.937em;
    font-weight: bold;
    text-transform: uppercase;
}

.subpage-tabs .small {
    color: #333333;
}

.subpage-tabs .current-tab {
    background-color: #f7f7f7;
    border-top: 5px solid #006abb;
}

@media (min-width:768px) {
    .subpage-tabs {
        text-align: center;
        margin: auto;
    }

    .subpage-tabs li {
        width: auto;
    }

    .subpage-tabs li + li {
        border-top: none;
    }

    .subpage-tabs a {
        display: inline-block;
        padding: 45px 10px;
        vertical-align: middle;
    }

    .subpage-tabs a .svg {
        display: block;
        margin: 0 auto;
        margin-bottom: 8px;
    }

    .subpage-tabs a.current-tab {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .subpage-tabs span {
        display: block;
        vertical-align: middle;
    }

}
@media (min-width:992px) {
    .subpage-tabs a {
        padding-left: 30px;
        padding-right: 30px;
    }

}
@media (min-width:1200px) {
    .subpage-tabs a {
        padding-left: 40px;
        padding-right: 40px;
    }

}

.advert-panel {
    margin-bottom: 20px;
}

.advert-panel a:focus {
    display: block;
    box-shadow: 0 0 5px black;
    box-shadow: 0 0 5px black;
}

.advert-panel h3 {
    font-size: 0.625em;
    text-transform: uppercase;
    color: #333333;
}

.advert-panel-wrapper {
    padding-bottom: 20px;
    border: 1px solid #cccccc;
}

.advert-panel-wrapper a {
    text-align: center;
    display: block;
}

.offer-how-to-guide {
    background-color: #006abb;
    color: $colour-white;
    padding: 30px;
    margin-bottom: 20px;
}

.offer-how-to-guide h2 {
    font-size: 1.125em;
    padding: 0 30px;
}

.offer-how-to-guide p {
    font-size: 0.875em;
    padding: 10px 0;
}

.offer-how-to-guide .view-link {
    color: $colour-white;
    text-decoration: underline;
}

.offer-how-to-enquiry {
    background-color: $colour-white;
    color: #333333;
    border: 1px solid #d2d2d2;
}

.employee-benefits-panel {
    background-color: #006abb;
    color: $colour-white;
    padding: 25px 30px;
}

.employee-benefits-panel h2 {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 0.875em;
    margin-top: 0;
    text-transform: uppercase;
}

.employee-benefits-panel .benefit-saving {
    font-size: 1.5625em;
    font-family: 'Open Sans',  sans-serif;
    font-weight: 300;
    padding-bottom: 5px;
}

.employee-benefits-panel .benefit-description {
    font-size: 0.8125em;
}

.employee-benefits-panel .btn {
    width: 100%;
    font-size: 0.8125em;
    margin-top: 5px;
}

// .offer-list-panel {
//     margin-top: 11px;
//     border: none;
//     -webkit-box-shadow: 0 0px 5px rgba(0,  0,  0,  0.1);
//     box-shadow: 0 0px 5px rgba(0,  0,  0,  0.1);
// }

// .offer-list-panel .panel-heading {
//     height: 155px;
//     line-height: 155px;
//     padding: 0;
//     position: relative;
//     background-color: #FFF;
//     overflow: hidden;
// }

// .offer-list-panel .panel-heading img {
//     max-width: 220px;
//     max-height: 120px;
// }

// .offer-list-panel .favourite-button {
//     position: absolute;
//     top: 10px;
//     right: 10px;
// }

// .offer-list-panel .favourite-button .fa {
//     display: block;
//     font-size: 1.375em;
// }

// .offer-list-panel .panel-body {
//     background-color: #f6f6f6;
//     padding: 10px 25px;
// }

// .offer-list-panel .panel-body h2 {
//     font-size: 0.75em;
//     text-transform: uppercase;
//     color: #6d6d6d;
//     margin-top: 0;
//     margin-bottom: 14px;
// }

// .offer-list-panel .available-offers li {
//     width: 50px;
//     font-size: 0.75em;
//     margin: 0 0px;
//     text-align: center;
//     padding: 0;
// }

// .offer-list-panel .available-offers li a {
//     display: inline-block;
//     text-decoration: underline;
// }

// .offer-list-panel .available-offers .svg {
//     background-position: center center;
//     margin: 0 6px 5px;
// }

// @media (min-width:992px) and (max-width:1200px) {
//     .panel.offer-list-panel .panel-body {
//         padding: 15px 12px;
//     }

// }
// @media (min-width:992px) {
//     .offer-list-panel:hover,
//     .offer-list-panel:focus {
//         -webkit-box-shadow: 0px 0px 20px 0px rgba(0,  0,  0,  0.3);
//         box-shadow: 0px 0px 20px 0px rgba(0,  0,  0,  0.3);
//         transition: box-shadow 0.1s ease-in;
//     }

// }
#search-results-panel {
    background-color: $colour-white;
    box-shadow: 0 0 8px 0 rgba(50, 50, 50, 0.75);
    box-shadow: 0 0 8px 0 rgba(50, 50, 50, 0.75);
    box-shadow: 0 0 8px 0 rgba(50, 50, 50, 0.75);
    width: 100%;
    display: none;
    max-width: 1167px;
    z-index: 999999;
    position: absolute;
}

.search-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 1170px;
    width: 100%;
    padding: 30px 0;
}

.search-panel-cat {
    float: left;
    width: 30%;
}

.search-panel-items {
    float: left;
    width: 70%;
}

.search-panel-items div a {
    cursor: pointer;
}

.search-panel-items div {
    padding: 5px 0;
}

.search-panel-item-group {
    border-bottom: solid 1px #e5e5e5;
    padding: 30px 0;
    margin: 0 30px;
}

.clearfix {
    clear: both;
}

.highlight {
    font-weight: bold;
}

// .search-wrapper .input-group {
//     width: 100%;
//     border: 2px solid transparent;
//     width: 100%;
//     max-width: 1167px;
// }

// .search-wrapper .form-inline .input-group .input-group-btn {
//     width: 54px;
// }

// .search-wrapper .form-control {
//     border: 0;
//     background-color: #f5f5f5;
//     padding-left: 30px;
// }

// .search-wrapper .form-control:focus {
//     outline: none;
// }

// .search-wrapper .btn {
//     border: 0;
//     min-width: 0;
//     padding: 0.7em 1em;
//     width: 54px;
//     background-color: #f5f5f5;
// }

// .search-results .panel-list-order-title {
//     float: left;
//     line-height: 2em;
// }

// .search-results .search-wrapper {
//     display: block;
// }

// .panel-list-group {
//     padding: 0 15px;
// }

// @media (min-width:768px) {
//     .panel-list-group {
//         padding: 20px 15px;
//     }

// }
// .panel-list-group > div {
//     border-bottom: solid 1px #dfdfdf;
// }

// .panel-list-group div > div {
//     padding: 10px 10px;
// }

// .panel-list-group div:last-child {
//     border-bottom: none;
// }

// .search-results #inline-search {
//     background-color: transparent;
//     color: #fff;
// }

// .search-results .input-group {
//     border: solid 2px #fff;
// }

// .search-results .search-wrapper {
//     padding-bottom: 0;
// }

// .search-results::-webkit-input-placeholder {
//     color: #fff;
// }

// .search-results:-moz-placeholder {
//     color: #fff;
// }

// .search-results::-moz-placeholder {
//     color: #fff;
// }

// .search-results:-ms-input-placeholder {
//     color: #fff;
// }

// .search-results .input-group-btn .btn {
//     background-color: transparent;
//     color: #fff;
// }

// .search-results.offer-list .page-header {
//     background-color: inherit;
//     color: inherit;
//     padding-bottom: 50px;
// }

@media screen and (max-width:767px) {
    .grid-menu {
        margin-left: -15px;
        margin-right: -15px;
    }

}

.grid-menu h2 {
    font-weight: 200;
    letter-spacing: -1px;
    margin-top: 0.3em;
    margin-bottom: 0.3em;
}

.grid-menu .svg {
    width: 91px;
    height: 93px;
}

.grid-menu .svg {
    background: none;
}

.grid-menu-item {
    display: block;
    padding: 30px 25px 45px;
}

.grid-menu-item:hover,
.grid-menu-item:focus {
    color: inherit;
    text-decoration: none;
    position: relative;
    z-index: 10;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.grid-menu-item.bg-primary:hover,
.grid-menu-item.bg-primary:focus {
    background-color: $colour-endeavour;
    color: $colour-white;
}

.grid-menu-item.bg-secondary:hover,
.grid-menu-item.bg-secondary:focus {
    background-color: $colour-light-yellow;
    color: $colour-endeavour;
}

.grid-menu-item.bg-speckledgrey:hover,
.grid-menu-item.bg-speckledgrey:focus {
    color: $colour-white;
}

.grid-menu-item p {
    font-size: 14px;
    max-width: 410px;
    margin-left: auto;
    margin-right: auto;
}

.grid-menu-item .fa-chevron-circle-right {
    font-size: 35px;
}

@media screen and (min-width:768px) and (max-width:1200px) {
    .grid-menu .svg {
        width: 90px;
        height: 90px;
    }

    .grid-menu .col-sm-4 .svg {
        width: 70px;
        height: 70px;
    }

}
@media screen and (min-width:768px) {
    .grid-menu {
        margin-bottom: 95px;
    }

    .grid-menu .col-sm-6 .svg {
        width: 120px;
        height: 120px;
    }

    .grid-menu .svg.svg-promotions {
        height: 121px\9;
    }

    .grid-menu-item {
        padding-bottom: 15px;
        height: 330px;
    }

    .grid-menu-item p {
        overflow: hidden;
        height: 60px;
        max-width: 410px;
        margin-left: auto;
        margin-right: auto;
    }

}
@media screen and (min-width:992px) {
    .grid-menu-item p {
        overflow: hidden;
        height: 40px;
    }

    .grid-menu-item.col-sm-4 {
        height: 320px;
    }

    .grid-menu-item.col-sm-4 svg {
        margin-top: 0.5em;
    }

}

.home-hero-carousel {
    z-index: 1;
}

.home-hero-carousel .carousel-inner > .item {
    text-align: center;
    overflow: hidden;
    height: 483px;
}

.home-hero-carousel .carousel-inner > .item > img {
    max-width: none;
    left: 50%;
    margin-left: -100%;
    position: relative;
    display: inline;
}


.home-hero-carousel .carousel-caption {
    @media screen and (min-width: 500px) {
        top: 80px;
    }
}

.home-hero-carousel .carousel-caption h2,
.home-hero-carousel .carousel-caption h2 {
    font-size: 2em;
}

.home-hero-carousel .carousel-caption p {
    margin-top: 24px;
    margin-bottom: 38px;
}

.home-hero-carousel .carousel-caption .btn {
    text-transform: uppercase;
}

@media only screen and (max-width:768px) {
    .benefit-logo {
        display: none;
    }

    .choose-benefit .dropdown .dropdown-menu {
        width: 190px !important;
    }

    .choose-benefit .dropdown .btn {
        width: 190px !important;
    }

}
@media only screen and (min-width:992px) {
    .home-hero-carousel .carousel-inner > .item {
        height: 550px;
    }

    .home-hero-carousel .carousel-caption {
        left: 30%;
        right: 30%;
    }

    .home-hero-carousel .carousel-caption h2 {
        display: block;
    }

    .home-hero-carousel .carousel-caption .benefit-logo {
        width: 136px;
    }

    .home-hero-carousel .carousel-indicators {
        bottom: 25px;
        width: 100%;
        margin-left: 0;
        left: 0;
    }

    .home-hero-carousel .carousel-indicators li {
        width: 72px;
        height: 72px;
        overflow: hidden;
        border-radius: 50%;
        text-indent: 0;
        border: 2px solid $colour-white;
    }

    .home-hero-carousel .carousel-indicators-heading {
        color: $colour-white;
        font-size: 0.875em;
        text-transform: uppercase;
        position: absolute;
        z-index: 5;
        bottom: 124px;
        text-align: center;
        width: 100%;
    }

    .home-hero-carousel .carousel-indicators li.active {
        border: 2px solid #5aa5dc;
        margin: 1px;
    }

    .home-hero-carousel .carousel-indicators li img {
        filter: grayscale(100%);
        filter: grayscale(100%);
    }

    .home-hero-carousel .carousel-indicators li.active img {
        filter: grayscale(0%);
        filter: grayscale(0%);
    }

}
@media only screen and (min-width:1280px) {
    .home-hero-carousel .item > img {
        width: 100%;
        height: 876px;
    }

}

.offer-carousel {
    padding-top: 37px;
    position: relative;
}

.offer-carousel h2 {
    margin-bottom: 33px;
    text-align: center;
}

.offer-carousel.similar-offers-carousel h2 {
    margin-bottom: 5px;
}

.offer-carousel.similar-offers-carousel h2 + p {
    margin-bottom: 30px;
}

.offer-carousel .btn-offer-view-all {
    margin-bottom: 20px;
    text-align: center;
}

.offer-carousel .carousel-inner {
    padding-bottom: 80px;
}

.offer-carousel .carousel-indicators {
    bottom: 20px;
}

.offer-carousel .panel-body hr {
    border-color: #333333;
}

@media (min-width:768px) {
    .offer-carousel h2 {
        text-align: left;
        float: left;
        margin-bottom: 33px;
        width: 80%;
    }

    .offer-carousel.similar-offers-carousel h2 {
        float: none;
        width: 100%;
    }

    .offer-carousel .btn-offer-view-all {
        margin-top: 20px;
        float: right;
    }

}

.membership-form h2 {
    font-size: 45px;
    font-weight: 600;
    margin-top: 0;
}

.forgotten-password {
    font-size: 14px;
    margin-top: 35px;
}

.login-form .form-control {
    border-width: 2px;
}

@media screen and (min-width:768px) {
    .login-form .has-error .control-label {
        display: inline;
    }

    .login-form .has-error .control-label::before {
        content: '- ';
    }

}

.join-form--step1 .form-main {
    margin-top: 0;
    margin-bottom: 18px;
}

.join-exclusive {
    background-color: #f2f2f2;
    padding: 25px;
}

.join-exclusive p {
    font-weight: bold;
}

.join-exclusive ul {
    font-size: 14px;
}

.form-group .progress {
    position: relative;
    height: 4px;
    width: 99.2%;
    margin: auto;
    margin-top: -0.35em;
    box-shadow: none;
    background-color: transparent;
}

.accept-deals {
    font-size: 14px;
}

.accept-deals label {
    width: 100%;
}

.accept-deals .checkbox-label {
    padding-left: 30px;
}

input.showpasswordcheckbox {
    float: right;
}

.showpasswordcheckbox + span {
    vertical-align: middle;
    vertical-align: -webkit-baseline-middle;
    margin-right: 5px;
}

.check-email {
    line-height: 1.3;
    margin-top: 5px;
}

.check-email strong {
    color: #333333;
    display: block;
}

.accept-tcs {
    font-size: 12px;
}

.favourite-item {
    margin-bottom: 20px;
}

.favourite-item .panel-body {
    line-height: 160px;
    text-align: center;
}

.favourite-item.selected .panel-body {
    line-height: 155px;
}

.join-form--step3 h2 {
    font-size: 32px;
    margin-bottom: 1em;
}

.join-form--step3 .form-main {
    margin: 35px 0 0;
}

.form-container .page-indicator {
    padding-bottom: 45px;
}

.join-form--step3 .panel {
    border: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.choose-favourites .favourite-item {
    margin-bottom: 40px;
}

@media screen and (max-width:768px) {
    .join-form--step2 h2 {
        font-size: 40px;
    }

    .choose-favourites .favourite-item .panel {
        height: 120px;
    }

    .favourite-item .panel-body {
        line-height: 90px;
        text-align: center;
    }

    .favourite-item .panel-body img {
        max-width: 80%;
    }

    .favourite-item.selected .panel-body {
        line-height: 85px;
    }

    .join-form--step3 .btn,
    .favorites-page .form-container .btn {
        width: 49%;
        min-width: 0;
    }

    .join-form--step3 .btn:hover,
    .join-form--step3 .btn:focus,
    .favorites-page .form-container .btn:hover,
    .favorites-page .form-container .btn:focus {
        background-color: inherit;
        color: inherit;
        border-color: inherit;
    }

    .join-form--step3 .btn--primary {
        float: left;
    }

    .join-form--step3 .btn--transparent {
        float: right;
    }

}
@media screen and (max-width:992px) {
    .join-exclusive {
        margin-top: 25px;
    }

}
@media screen and (min-width:768px) {
    .form-col-container {
        margin-top: 45px;
    }

    .join-form--step2 .col-md-4 {
        padding: 0;
    }

    .join-form--step3 .page-indicator {
        padding-bottom: 45px;
    }

}
@media screen and (min-width:992px) {
    .join-exclusive {
        max-width: 300px;
        margin-left: 15px;
    }

}

.text-content-page {
    background-color: $colour-white;
}

.text-content-page .page-header {
    background-color: #f3f3f3;
    padding-bottom: 0;
    border-top: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
    margin-bottom: 20px;
}

.offer-info li .like-button {
    margin-bottom: 5px;
}

.modal-open {
    overflow: hidden;
    position: fixed;
    height: 100%;
    width: 100%;
}

.context-panel-mobile-affix.affix {
    top: 0;
    width: 100%;
    z-index: 10;
}

.context-panel-mobile-affix.affix-bottom {
    position: relative;
}

.text-section {
    font-size: 0.875em;
}

.text-section + .text-section {
    padding-top: 2em;
    border-top: 1px solid #d5d5d5;
    margin-top: 2em;
}

.text-section.bottom-border {
    border-bottom: 1px solid #d5d5d5;
    padding-bottom: 2em;
    margin-bottom: 2em;
}

.text-section h3,
.text-section-title {
    font-size: 1.42em;
}

.text-content {
    clear: both;
}

.text-content img {
    max-width: 100%;
}

.text-section .show-more-link {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.857em;
    display: block;
    width: 100%;
    padding-top: 36px;
    margin-top: -42px;
    position: relative;
    outline: none;
}

.text-section .show-more-link.masked::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 15px;
    background-color: white;
    background-color: rgba(255, 255, 255, 0.5);
}

.no-js .text-section .show-more-link {
    display: none;
}

.text-section .video-placeholder {
    width: 100%;
    height: 400px;
    line-height: 400px;
    text-align: center;
    background-color: #f3f3f3;
    margin-bottom: 30px;
    border: 1px solid #000000;
}

.textcontent-main-col {
    padding-bottom: 60px;
}

.textcontent-sidecol .text-content-affixed.affix {
    top: 0;
    width: 300px;
    position: fixed;
}

.textcontent-sidecol .text-content-affixed.affix-bottom {
    position: relative;
}

@media (max-width:767px) {
    .textcontent-sidecol {
        margin-top: 0 !important;
        position: static;
    }

    .text-content-page .page-header {
        text-align: center;
    }

    .text-content-page .page-title {
        font-size: 1.375em;
        font-weight: 100;
    }

    .offer-info li {
        display: block;
        padding: 15px 0;
    }

    .offer-info li + li {
        border-top: 1px solid #d5d5d5;
    }

}
@media (min-width:768px) {
    .text-content-page .page-header {
        padding-bottom: 20px;
    }

    .text-content-page .page-header .container {
        padding-right: 345px;
    }

    .text-content-page .page-title {
        font-size: 2.375em;
    }

    .text-content-page .sub-title {
        color: #929394;
        font-size: 1.875em;
    }

    .offer-info li {
        margin-bottom: 3px;
        padding: 5px 10px 5px 0;
    }

    .offer-info li + li {
        border-left: 1px solid #d5d5d5;
        padding-left: 10px;
    }

    .text-section {
        position: relative;
        padding-left: 25.37%;
        margin-top: 20px;
    }

    .text-section--with-logo {
        min-height: 155px;
    }

    .text-section.no-top-margin {
        margin-top: 0;
    }

    .text-section h3,
    .text-section-title {
        font-size: 1em;
        text-transform: uppercase;
        width: 90px;
    }

    .text-section-title {
        padding-top: 4px;
        margin-top: 0;
        position: absolute;
        top: 0;
        left: 0;
    }

    .text-section-logo-circle {
        position: absolute;
        top: 30px;
        left: 0;
        text-align: center;
        background-color: $colour-white;
        line-height: 80px;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        border: 1px solid #d6d6d6;
    }

    .text-section-logo-circle img {
        max-width: 60px;
        max-height: 50px;
    }

    .text-section + .text-section .text-section h3,
    .text-section + .text-section .text-section-title {
        padding-top: 27px;
    }

    .textcontent-main-col {
        max-width: 420px;
        width: 390px\9;
    }

    .text-content-affixed.affix {
        width: 300px;
    }

    .textcontent-sidecol {
        margin-top: 20px;
        min-width: 330px;
        width: 330px\9;
    }

    .context-panel.collapse {
        display: block;
        height: auto !important;
    }

}
@media (min-width:992px) {
    .textcontent-main-col {
        max-width: 559px;
        width: 529px\9;
    }

    .text-section h3 {
        width: 120px;
        font-size: 1.14em;
    }

    .text-section-logo-circle {
        line-height: 122px;
        width: 122px;
        height: 122px;
    }

    .text-section-logo-circle img {
        max-width: 90px;
        max-height: 70px;
    }

}
@media (min-width:1200px) {
    .text-section h3 {
        width: 140px;
        font-size: 1.14em;
    }

    .textcontent-main-col {
        width: 685px\9;
    }

    .text-content-page .page-header .container {
        padding-right: 415px;
    }

    .textcontent-sidecol .text-content-affixed.affix {
        width: 360px;
    }

    .textcontent-main-col {
        max-width: none;
    }

}

.text-content-affixed.affix .call-us-box,
.text-content-affixed.affix-bottom .call-us-box {
    padding: 5px;
    background-image: none;
}

.text-content-affixed.affix .call-us-box h3,
.text-content-affixed.affix-bottom .call-us-box h3 {
    margin-top: 10px;
    margin-bottom: 0;
}

.text-content-affixed.affix .call-us-box p,
.text-content-affixed.affix-bottom .call-us-box p {
    display: none;
}

.context-panel-form .panel-heading h3 {
    font-weight: 400;
}

.context-panel-form .form-title {
    font-size: 1em;
    display: block;
    width: 100%;
    font-family: 'Open Sans',  sans-serif;
    font-weight: 400;
    text-align: center;
    line-height: 1em;
}

.context-panel-form .form-title.small {
    font-size: 0.75em;
}

.context-panel-form .has-error label {
    color: #333333;
}

.context-panel-form .has-error label.control-label {
    color: #fe3434;
}

.context-panel-form .control-label {
    text-align: center;
    margin-bottom: 4px;
}

.context-panel-form .form-error {
    display: none;
    padding-bottom: 5px;
    color: #fe3434;
    font-size: 14px;
    font-weight: bold;
}

.context-panel-form .form-error label {
    text-align: center;
}

.context-panel-form .radio label {
    max-width: none;
}

.context-panel-form .amount-panel {
    border-bottom: 1px solid #e5e5e5;
}

.context-panel-form .amount-panel label {
    width: 100%;
}

.context-panel-form .amount-panel #voucher-amount {
    line-height: 1.2em;
    height: auto;
    width: 100%;
    text-align: center;
    padding: 3px 0;
    border: none;
    border-top: 1px solid #d0d0d0;
    background-clip: padding-box;
    border-radius: 0;
}

.context-panel-form .amount-panel .input-group-addon {
    background-color: $colour-white;
    border-radius: 0;
    border-bottom: none;
}

.context-panel-form .amount-panel .slider-total {
    font-size: 0.7em;
}

@media (min-width:1200px) {
    .context-panel-form .amount-panel .slider-total {
        font-size: 0.875em;
    }

}

.context-panel-form .amount-panel .small {
    margin-top: 5px;
}

.context-panel-form form {
    padding: 15px 8%;
}

.context-panel-form .form-extrainfo {
    margin-top: 10px;
}

.context-panel-form .slider-wrapper {
    overflow: hidden;
    height: 30px;
    margin-bottom: 5px;
    max-width: 300px;
    max-width: calc(100% - 1px);
    display: none;
}

.context-panel-form .slider-wrapper input[type='range'] {
    display: none\9;
    height: 30px;
    width: 72%;
    appearance: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    margin: 0;
    border: none;
    padding: 1px 2px;
    background: $colour-white;
    box-shadow: inset 0 1px 0 0 #d0d0d0,  inset 0 -1px 0 0 #d0d0d0;
    box-shadow: inset 0 1px 0 0 #d0d0d0,  inset 0 -1px 0 0 #d0d0d0;
    outline: none;
    border-radius: 0;
}

.context-panel-form .slider-wrapper input[type='range']::-moz-range-track {
    border: inherit;
    background: transparent;
}

.context-panel-form .slider-wrapper input[type='range']::-ms-track {
    border: inherit;
    color: transparent;
    background: transparent;
}

.context-panel-form .slider-wrapper input[type='range']::-ms-fill-lower,
.context-panel-form .slider-wrapper input[type='range']::-ms-fill-upper {
    background: transparent;
}

.context-panel-form .slider-wrapper input[type='range']::-ms-tooltip {
    display: none;
}

.context-panel-form .slider-wrapper input[type='range']::-webkit-slider-thumb {
    appearance: none;
    width: 30px;
    height: 18px;
    border: none;
    border-radius: 12px;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $colour-endeavour), color-stop(100%, $colour-endeavour));
    background-image: linear-gradient(top, $colour-endeavour 0, $colour-endeavour 100%);
    background-image: linear-gradient(to bottom, $colour-endeavour 0, $colour-endeavour 100%);
}

.context-panel-form .slider-wrapper input[type='range']::-moz-range-thumb {
    width: 30px;
    height: 18px;
    border: none;
    border-radius: 12px;
    background-image: linear-gradient(to bottom, $colour-endeavour 0, $colour-endeavour 100%);
}

.context-panel-form .slider-wrapper input[type='range']::-ms-thumb {
    width: 30px;
    height: 18px;
    border-radius: 12px;
    border: 0;
    background-image: linear-gradient(to bottom, $colour-endeavour 0, $colour-endeavour 100%);
}

.context-panel-form .slider-wrapper input[type='range']:focus {
    background-color: $colour-light-yellow;
}

.context-panel-form .slider-wrapper .plus,
.context-panel-form .slider-wrapper .minus {
    padding: 0;
    height: 30px;
    width: 14%;
    background-color: $colour-white;
    border: 1px solid #d0d0d0;
}

.context-panel-form select {
    font-size: 0.777em;
    padding: 5px;
}

.context-panel-form .voucher-denominations {
    border-bottom: 1px solid #e5e5e5;
    position: relative;
}

.context-panel-form .voucher-denominations label {
    font-size: 0.9375em;
}

.context-panel-form .voucher-denominations select {
    width: 100%;
    margin-bottom: 4px;
}

.context-panel-form .form-submit {
    padding: 10px 0;
}

.context-panel-form .panel-body .btn {
    margin: 5px 0;
    font-size: 0.888em;
}

.context-panel-form .panel-body .btn.full-width {
    width: 100%;
}

.context-panel-form .panel-footer {
    font-family: 'Open Sans',  sans-serif;
    font-weight: 400;
    background-color: transparent;
    padding: 0 0 5px;
    border-top: 0;
}

.context-panel-form .panel-footer a {
    color: #333333;
}

.context-panel-form .panel-footer a .fa {
    color: #59b7ff;
    font-size: 1.5em;
    padding-right: 10px;
    padding-bottom: 10px;
}

.panel.context-form-innerpad .panel-body {
    padding: 0;
}

.panel.context-form-innerpad .voucher-denominations {
    padding: 15px 8%;
}

.bank-details a.btn {
    color: $colour-white;
    border-color: $colour-white;
}

.bank-details .account-unlock-content .form-group .form-control {
    color: #565a5c;
    font-size: 1em;
    height: 35px;
    line-height: 35px;
    margin: 5px 0 0;
    padding: 0 7px;
}

.bank-details .form-group label {
    font-size: 0.8em;
    font-weight: bold;
    margin-bottom: 0;
}

.bank-details .account-unlock-content {
    color: #565a5c;
}

.bank-details .form-control.sortcode {
    max-width: 60px;
    float: left;
    margin-right: 10px !important;
}

.bank-details .has-error .form-control.sortcode {
    background-position: 130% center;
}

.bank-details .generic-secure {
    font-size: 0.7em;
    padding-top: 1.5em;
}

.bank-details .generic-secure .fa-lock {
    margin-right: 5px;
}

@media screen and (max-width:767px) {
    .bank-details .bank-details-text {
        margin-top: 1em;
        padding-top: 1em;
        border-top: 1px solid #e3e3e3;
    }

}
@media screen and (min-width:992px) {
    .bank-details .bank-details-text {
        padding-left: 2.5em;
    }

}

.bank-details .bank-details-text p {
    margin: 0 0 1em;
    color: #565a5c;
    font-size: 0.8em;
}

.bank-details .bank-details-form-error-summary {
    margin-bottom: 1em;
}

.customer-info-form {
    padding-bottom: 5em;
}

.customer-info-form .form-error-summary {
    margin-bottom: 1em;
}

.customer-info-form .form-controls {
    padding-bottom: 10px;
}

.customer-info-form .form-group-first {
    padding-top: 10px;
}

.customer-info-form .form-extrainfo + .form-group-first {
    padding-top: 4px;
}

.customer-info-form #fullnamelabel {
    width: 100%;
}

.customer-info-form #fullnamelabel span {
    font-weight: bold;
}

.contact-details-page form {
    margin-bottom: 1em;
}

.contact-details-page label,
.contact-details-page .label {
    font-weight: bold;
}

.contact-details-page label span,
.contact-details-page .label span {
    font-weight: normal;
}

.contact-details-page [name='title'] {
    width: 50%;
}

.contact-details-page [name='addressl2'] {
    margin-top: 0.7em;
}

@media screen and (max-width:768px) {
    .contact-details-page [name='firstname'],
    .contact-details-page [name='lastname'] {
        margin-top: 0.5em;
    }

    .customer-info-form .btn {
        width: 100%;
    }

    .customer-info-form [type='reset'] {
        margin-top: 0.5em;
    }

}
@media screen and (min-width:768px) {
    .contact-details-page .form-group--name {
        float: left;
        margin-left: 2%;
        margin-top: 0;
    }

    .contact-details-page [name='title'] {
        width: 16%;
        margin-left: 0;
    }

    .contact-details-page [name='firstname'],
    .contact-details-page [name='lastname'],
    .contact-details-page [name='postcode'] {
        width: 40%;
    }

    .contact-details-page [name='primaryemail'],
    .contact-details-page [name='alternativeemail'],
    .contact-details-page [name='phone'] {
        width: 62.2%;
    }

}

.code-list-page {
    background-color: $colour-white;
}

.code-list-page .page-header {
    border-top: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
    background-color: #f3f3f3;
    margin-bottom: 20px;
}

.code-list-item {
    display: block;
    max-width: 720px;
}

.code-list-item .panel-heading {
    color: $colour-white;
    font-size: 10px;
    min-height: 0;
    border-radius: 0;
}

.code-list-item .discount {
    padding-right: 20px;
    height: auto;
    margin: 30px 0;
}

.code-list-item .panel-body {
    background-color: #f7f7f7;
    padding-bottom: 0;
}

.code-list-item .panel-body h2 {
    text-transform: none;
    width: auto;
}

.code-list-item:hover,
.code-list-item:focus,
.code-list-item:active {
    text-decoration: none;
}

.code-item-active {
    max-width: none;
    border-top: 5px solid #006abb;
    border-bottom: 0;
    position: relative;
}

.code-item-active .panel-heading {
    background: $colour-white;
    color: #333333;
}

.code-item-active .panel-body {
    background-color: $colour-white;
}

.code-item-active .panel-body h2 {
    color: #006abb;
}

.code-list .text-content {
    background-color: #f5f5f5;
    padding: 50px 35px;
}

.modal-sm-only {
    padding-right: 0 !important;
}

.textcontent-main-col.code-list {
    padding-bottom: 0;
}

@media (max-width:767px) {
    .code-item-active .panel-heading {
        border-bottom: 1px solid #dbdbdb;
    }

}
@media (min-width:768px) {
    .code-list-page .modal-sm-only {
        display: none;
    }

    .code-item-active::after {
        content: '';
        display: block;
        width: 20px;
        height: 34px;
        position: absolute;
        top: 70px;
        right: -20px;
    }

    .code-item-active .panel-heading {
        border-right: 1px solid #dbdbdb;
    }

    .clipbord-send {
        margin-top: 1.2em;
    }

    .clipbord-send a {
        color: #333333;
        font-size: 0.9em;
        display: block;
    }

    .clipbord-send a .fa {
        color: $colour-endeavour;
        font-size: 1.2em;
        padding-right: 6px;
    }

}
@media (min-width:768px) and (min-width:1200px) {
    .clipbord-send a {
        display: inline;
    }

    .clipbord-send a:first-child {
        border-right: 1px solid #dbdbdb;
        padding-right: 0.7em;
        margin-right: 0.7em;
    }

}
@media (min-width:992px) {
    .code-list-item {
        position: relative;
    }

    .code-list-item .panel-heading {
        width: 20%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
    }

    .code-list-item .discount {
        margin-top: 75px;
        margin-bottom: 75px;
    }

    .code-list-item .panel-body {
        width: 80%;
        float: right;
    }

    .code-list-item .offer-info {
        margin-top: 3em;
    }

    .code-list-item:hover {
        box-shadow: 0 0 6px #d5d5d5;
    }

    .code-item-active {
        border-top: 1px solid #dddddd;
        border-left: 5px solid #006abb;
    }

    .code-list .text-content {
        border-left: 5px solid #006abb;
    }

    .copy-to-clipboard {
        width: 70%;
        margin: 0 auto;
    }

}

.get-code-panel {
    border-color: #dddddd;
    margin-top: 11px;
    position: relative;
    margin-bottom: 0;
}

.voucher-code {
    font-size: 26px;
    font-weight: bold;
}

.mobile-get-code-panel .discount-amount {
    font-size: 3em;
    font-weight: bold;
    font-family: 'Open Sans',  sans-serif;
}

.mobile-get-code-panel .panel-body {
    padding: 0 0 40px;
}

.mobile-get-code-panel .discount-detail {
    background-color: #f5f5f5;
    font-weight: bold;
    display: block;
    padding: 25px 0;
}

.mobile-get-code-panel .voucher-code {
    display: block;
    padding: 9px;
    border: 3px solid $colour-endeavour;
    margin: 35px 20px;
}

.mobile-get-code-panel .btn-close-model {
    color: $colour-white;
    float: right;
    margin-top: 1.5em;
}

.mobile-get-code-panel .panel-footer {
    font-family: 'Open Sans',  sans-serif;
    font-weight: 400;
    background-color: transparent;
    padding: 0 0 5px;
    border-top: 0;
}

.mobile-get-code-panel .panel-footer a {
    color: #333333;
}

.mobile-get-code-panel .panel-footer a .fa {
    color: #59b7ff;
    font-size: 1.5em;
    padding-right: 10px;
    padding-bottom: 10px;
}

@media (min-width:768px) and (max-width:992px) {
    .code-list {
        max-width: none;
    }

    .get-code-panel {
        margin-top: 17px;
    }

    .get-code-panel .panel-heading {
        padding: 35px 0;
    }

    .get-code-panel .panel-body {
        padding: 38px 15px;
    }

    .get-code-panel .btn-primary {
        margin-bottom: 5px;
    }

}

.standard-content-area {
    padding-bottom: 3em;
    color: #565a5c;
    font-size: 0.9375em;
}

.standard-content-area h2 {
    margin: 2.5em 0 1.5em;
    font-size: 0.9375em;
    color: #565a5c;
    font-weight: 700;
}

.standard-content-area p + p {
    margin-top: 1.5em;
}

.standard-content-area a {
    color: #006abb;
}

.standard-content-area ul {
    margin-bottom: 1.5em;
}

.standard-content-area ul li {
    margin-bottom: 0;
}

@media (min-width:992px) {
    .standard-content-area {
        padding-right: 2.5em;
    }

}
@media (min-width:1200px) {
    .standard-content-area {
        padding-right: 5.5em;
    }

}

.contact-us-page .form-group label {
    margin-bottom: 0;
}

.contact-us-page label,
.contact-us-page .label {
    font-weight: bold;
    color: #565a5c;
    width: 100%;
}

.contact-us-page label span,
.contact-us-page .label span {
    font-weight: normal;
}

@media screen and (max-width:767px) {
    .contact-us-page [name='lastname'] {
        margin-top: 0.5em;
    }

}
@media screen and (max-width:991px) {
    .contact-us-page [type='submit'] {
        margin-bottom: 1em;
    }

}
@media screen and (min-width:768px) {
    .contact-us-page select {
        width: 70%;
    }

    .contact-us-page .form-group--inline {
        width: 50%;
        margin-right: -4px;
    }

    .contact-us-page .form-group--inline.left {
        padding-right: 2%;
    }

}

.contact-us-page .contact-us-thanks {
    margin-left: 50px;
    margin-bottom: 100px;
    position: relative;
}

.contact-us-page .contact-us-thanks .btn {
    margin-top: 2em;
}

.contact-us-page .contact-us-thanks .fa {
    font-size: 2em;
    top: 5px;
    left: -50px;
    position: absolute;
}

// .landing-jumbotron {
//     margin-bottom: 30px;
// }

// .landing-jumbotron:after {
//     content: "";
//     display: block;
//     width: 100%;
//     height: 5px;
//     position: absolute;
//     bottom: 0;
//     z-index: 3;
// }

// .landing-jumbotron .btn {
//     margin-top: 0.5em;
// }

// .landing-jumbotron .search {
//     letter-spacing: -5px;
//     padding: 0;
//     width: 100%;
//     max-width: 630px;
//     height: 70px;
//     margin: 35px auto;
//     position: relative;
// }

// .landing-jumbotron .search label {
//     letter-spacing: 1px;
// }

// .landing-jumbotron .search input[type=text] {
//     line-height: 70px;
//     display: inline-block;
//     width: 89%;
//     height: 100%;
//     border: none;
// }

// .landing-jumbotron .search-go {
//     vertical-align: top;
//     display: inline-block;
//     padding: 0.65em 0;
//     width: 10%;
//     height: 100%;
//     min-width: 0;
//     border: none;
//     border-radius: 0;
//     margin: 0;
// }

// @media (max-width:992px) {
//     .landing-jumbotron .btn-primary {
//         display: block;
//     }

// }
// @media (min-width:992px) {
//     .landing-jumbotron p {
//         font-size: 1.375em;
//     }

// }
.landing-benefits {
    padding-top: 10px;
    padding-bottom: 60px;
}

.landing-benefits h2 {
    margin-bottom: 0.6em;
}

.benefit-promos {
    padding-top: 35px;
    padding-bottom: 30px;
}

.benefit-promo {
    display: block;
    margin-bottom: 10px;
    position: relative;
}

.benefit-promo-image {
    margin-top: 0;
    margin-left: 0;
    width: 100%;
}

.benefit-promo-logo {
    text-align: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.benefit-promo-logo img {
    width: auto;
    max-width: 80%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    transform: translateY(-50%);
    transform: translateY(-50%);
}

.landing-savings {
    font-family: 'Open Sans',  sans-serif;
    padding: 40px 0 80px;
}

.landing-savings .savings-promo {
    padding-top: 12px;
    border-top: 1px solid #5e5e63;
    margin-top: 25px;
}

.landing-savings .savings-promo img {
    margin-bottom: 10px;
}

.landing-savings a:hover {
    color: $colour-white;
}

// .landing-deals {
//     background-color: #edeae6;
//     padding: 40px 0 65px 0;
// }

// .landing-deals h2 {
//     margin-bottom: 0.6em;
// }

// .deal-items {
//     padding: 20px 0 40px 0;
// }

// .deal-item {
//     background-color: #FFF;
//     line-height: 140px;
//     text-align: center;
//     display: inline-block;
//     width: 100%;
//     height: 0;
//     padding-bottom: 100%;
//     border-radius: 50%;
//     box-shadow: 0 0 8px #ccc;
//     margin: 10px 0;
//     position: relative;
//     overflow: hidden;
// }

// .deal-item .image-wrapper {
//     text-align: center;
//     width: 100%;
//     position: absolute;
//     position: relative\9;
//     top: 50%;
//     -webkit-transform: translateY(-50%);
//     -ms-transform: translateY(-50%);
//     transform: translateY(-50%);
// }

// .deal-item img {
//     max-width: 80%;
// }

// .landing-new-deals {
//     padding: 35px 0 45px 0;
// }

// .landing-new-deals p {
//     font-size: 1.125em;
//     font-weight: 200;
// }

// .landing-new-deals .lead {
//     font-size: 1.5em;
//     font-weight: normal;
//     margin-bottom: 0;
// }

// .landing-new-deals .btn {
//     margin-top: 1em;
//     margin-bottom: 0.8em;
// }

// @media (min-width:768px) {
//     .savings-promos {
//         margin-top: 2.5em;
//     }

//     .landing-savings .savings-promo {
//         padding-top: 0;
//         min-height: 340px;
//         border-top: none;
//     }

//     .landing-savings .savings-promo + .savings-promo {
//         border-left: 1px solid #5e5e63;
//     }

//     .landing-savings .savings-promo img {
//         margin-top: -10px;
//     }

// }
@media (min-width:992px) {
    .benefit-promo {
        overflow: hidden;
        width: 100%;
        height: 0;
        padding-bottom: 109%;
    }

    .benefit-promo-image {
        margin-top: 0;
        margin-left: 0;
    }

    .benefit-promo:hover {
        box-shadow: 0 0 6px #333333;
    }

    .benefit-promo:hover .benefit-promo-image {
    }

    .deal-items {
        padding-top: 30px;
        padding-bottom: 50px;
    }

    .deal-item {
        margin: 0 10px;
    }

    .landing-savings h2 {
        margin-bottom: 0.6em;
    }

    .landing-savings .savings-promo {
        min-height: 0;
    }

}

.home-member-container {
    max-width: 1280px;
    margin: 0 auto;
}

.home-member-container .member-saved {
    background-color: $colour-white;
    padding: 18px 20px 28px;
}

.home-member-container > ul {
    list-style: none;
    margin: 0;
    padding: 5px 0 0;
    background-color: #f2f2f2;
}

.home-member-container > ul > li .fa {
    margin-right: 10px;
    color: #006abb;
}

@media (max-width:880px) {
    .home-member-container > ul > li {
        margin: 0 20px;
        height: 46px;
        line-height: 46px;
        border-bottom: 1px solid #e1e1e1;
    }

    .home-member-container > ul > li.last-child {
        border-bottom: none;
    }

    .home-member-container > ul > li a {
        display: block;
    }

    .home-member-sm {
        display: block;
    }

    .home-member-lg {
        display: none !important;
    }

}
@media (min-width:881px) {
    .home-member-sm {
        display: none !important;
    }

    .home-member-lg {
        display: block;
    }

    .home-member {
        background-color: $colour-white;
    }

    .home-member-container > ul {
        background-color: $colour-white;
        width: 76.9%;
        float: left;
        padding-top: 18px;
    }

    .home-member-container > ul > li {
        float: left;
        border-bottom: none;
    }

    .home-member-container .member-saved {
        float: left;
        width: 23.07%;
        padding-bottom: 18px;
    }

    .home-member-container .member-discount {
        width: 26.88%;
    }

    .home-member-container .member-favourites {
        width: 28.66%;
    }

    .home-member-container .member-deals {
        width: 25.55%;
    }

    .home-member-container .member-cashback {
        width: 18.88%;
    }

    .home-member-container .member-deals a,
    .home-member-container .member-cashback a {
        color: #333333;
        text-decoration: none;
    }

    .home-member-container .member-deals a span,
    .home-member-container .member-cashback a span {
        color: #006abb;
        font-size: 14px;
    }

    .home-member-container .member-deals a:hover span,
    .home-member-container .member-cashback a:hover span {
        border-bottom: 1px solid #006abb;
    }

    .home-member .dropdown .btn {
        font-size: 14px;
        padding: 10px 14px 12px 18px;
        width: 217px;
        text-align: left;
        position: relative;
    }

    .home-member .dropdown .btn .caret {
        right: 6%;
        top: 50%;
        position: absolute;
        color: #006abb;
    }

    .home-member .dropdown .dropdown-menu {
        width: 217px;
        padding: 0;
    }

    .home-member .dropdown .dropdown-menu li {
        margin: 0;
        border-bottom: 1px solid #c2c2c2;
    }

    .home-member .dropdown .dropdown-menu li:last-child {
        border-bottom: none;
    }

    .home-member .dropdown .dropdown-menu li > a {
        padding-top: 12px;
        padding-bottom: 10px;
    }

}
@media (min-width:881px) and (max-width:1180px) {
    .home-member-container .member-saved {
        width: 100%;
    }

    .home-member-container > ul {
        width: 100%;
        background-color: #f2f2f2;
        padding-left: 20px;
        padding-right: 20px;
    }

}

.home-search h2 {
    font-size: 1.25em;
    padding-top: 10px;
    padding-bottom: 10px;
}

.home-search .input-group {
    width: 100%;
    border: 2px solid #000000;
    margin-bottom: 50px;
    max-width: 628px;
}

.home-search .form-inline .input-group .input-group-btn {
    width: 54px;
}

.home-search .form-control {
    border: 0;
}

.home-search .btn {
    border: 0;
    border-radius: 0;
    min-width: 0;
    padding: 0.7em 1em;
    width: 54px;
    background-color: transparent;
}

@media (min-width:768px) {
    .home-search h2 {
        padding-top: 33px;
    }

}

.home-employee-benefits {
    padding-top: 40px;
    padding-bottom: 53px;
    position: relative;
    margin-top: -5px;
}

.home-employee-benefits::before {
    content: '';
    display: block;
    width: 100%;
    height: 5px;
    position: absolute;
    top: 0;
}

.home-employee-benefits .choose-benefit .dropdown {
    display: inline-block;
    text-transform: uppercase;
    margin-left: 12px;
}

.choose-benefit .dropdown .dropdown-menu {
    width: 290px;
}

.choose-benefit .dropdown .dropdown-menu li {
    margin: 0;
}

.choose-benefit .dropdown .btn {
    text-align: left;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    width: 290px;
    padding: 7px 14px;
    margin-bottom: 3px;
}

.choose-benefit .dropdown .btn .caret {
    right: 6%;
    top: 40%;
    position: absolute;
}

.home-employee-benefits h2 {
    margin-bottom: 20px;
}

.home-employee-benefits .home-benefits-grid {
    margin-top: 20px;
    margin-bottom: 18px;
}

.home-employee-benefits .home-benefits-grid .feature-panel {
    margin-bottom: 24px;
}

@media (max-width:767px) {
    .home-employee-benefits .fp-benefits-lg {
        margin-left: -20px;
        margin-right: -20px;
    }

    .home-employee-benefits .benefits-view-all {
        font-family: 'Open Sans',  sans-serif;
        font-weight: 700;
    }

}

.hot-offers {
    padding-top: 37px;
    padding-bottom: 53px;
}

.hot-offers h2 {
    margin-bottom: 47px;
}

.hot-offers-carousel .carousel-inner {
    padding-bottom: 70px;
}

.hot-offers-carousel .carousel-indicators {
    bottom: 20px;
}

.recommended-for-you {
    padding-bottom: 50px;
}

.recommended-for-you h2 {
    margin-top: 44px;
}

.recommended-offers-carousel {
    padding-top: 15px;
    padding-bottom: 15px;
}

.recommended-offers-carousel .carousel-inner {
    padding-bottom: 20px;
}

.recommended-offers-carousel.responsive-carousel .carousel-indicators li {
    background-color: #e2e2e2;
}

.recommended-offers-carousel.responsive-carousel .carousel-indicators li.active {
    background-color: #0c61ac;
}

@media (max-width:768px) {
    .recommended-offers-carousel .carousel-inner {
        padding-bottom: 40px;
    }

}
@media (min-width:768px) {
    .recommended-for-you h2 {
        margin-top: 54px;
    }

    .recommended-offers-carousel .carousel-indicators {
        top: -60px;
        right: 0;
        left: auto;
        bottom: auto;
        margin: 0;
        width: auto;
    }

}

.our-news {
    padding: 38px 0 61px;
    position: relative;
}

.our-news h2 {
    padding-bottom: 0.46875em;
}

.news-col p + p a {
    margin-top: 1.5em;
}

.our-news .news-link {
    display: block;
    padding-bottom: 10px;
}

.our-news .btn {
    margin-top: 20px;
}

.our-news hr {
    margin: 44px 0;
    color: #e5e5e5;
}

@media (min-width:768px) {
    .our-news::before {
        height: 100%;
        content: ' ';
        position: absolute;
        top: 0;
        left: 50%;
        width: 50%;
        background-color: #fcfcfc;
    }

    .our-news .news-col {
        padding-right: 6.25%;
    }

    .our-news .call-col {
        padding-left: 6.25%;
    }

}
@media (max-width:768px) {
    .call-col {
        text-align: center;
    }

    .our-news h2 {
        text-align: center;
    }

    .our-news h2 a {
        font-size: 16px;
        display: block;
        margin-top: 0.6em;
    }

}

.offer-list.category-list .fp-benefits-sm {
    margin-bottom: 24px;
}

.offer-list.category-list .fp-benefits-sm .feature-content {
    font-size: 0.875em;
}

.offer-list .call-us-box {
    background: #006abb;
    padding: 10px 20px 20px;
}

@media screen and (max-width:767px) {
    .offer-list .page-header {
        background-color: #f6f6f6;
        border: 0;
        padding-bottom: 0;
        margin-bottom: 20px;
        color: #333333;
    }

    .offer-list .mobile-show-filter-btn {
        width: 100%;
        margin-bottom: 10px;
    }

    .offer-list .advert-panel {
        margin: 25px -20px;
    }

}
@media screen and (min-width:768px) {
    .offer-list.category-list .filter-list-panel h2 {
        background: transparent;
        color: #9a9a9a;
        padding-top: 20px;
    }

    .offer-list .advert-panel {
        border: none;
    }

}

.local-discount .page-header {
    margin-bottom: 0;
}

.local-discount .location-bar {
    background-color: #ececec;
    margin-bottom: 33px;
}

.local-discount-form {
    margin: 26px 0;
}

.local-discount-form .form-control {
    border-color: #d0d0d0;
    width: 100%;
    padding: 0 16px;
    height: 38px;
    line-height: 38px;
    font-size: 0.75em;
    margin-right: 10px;
}

.location-distance-dropdown {
    width: 100%;
    margin-bottom: 20px;
}

.local-discount-form .btn-use-current-location {
    display: none;
}

.local-discount-form .btn {
    padding-top: 5px;
    padding-bottom: 5px;
    min-width: 0;
    width: 49%;
}

@media screen and (max-width:500px) {
    .local-discount-form .btn {
        width: 100%;
        margin-bottom: 5px;
    }

}
@media screen and (min-width:768px) {
    .local-discount-form .form-control {
        width: 320px;
        font-size: 0.875em;
    }

    .local-discount-form .btn {
        width: auto;
        margin-right: 10px;
    }

}
@media screen and (min-width:768px) and (max-width:991px) {
    .order-by-dropdown .dropdown.location-distance-dropdown {
        margin-left: 0;
        padding-left: 0;
    }

    .order-by-dropdown .dropdown.location-distance-dropdown .btn,
    .order-by-dropdown .dropdown.location-distance-dropdown .dropdown-menu {
        width: 100%;
    }

}
@media screen and (min-width:992px) {
    .location-distance-dropdown {
        margin-bottom: 0;
        width: auto;
    }

}

.retailers-list-page.offer-list .call-us-box {
    margin-top: 11px;
}

@media screen and (min-width:768px) {
    .retailers-list-page .page-header {
        margin-bottom: 0;
    }

}

.retalier-az {
    background-color: #e7e7e7;
    padding-top: 20px;
    padding-bottom: 18px;
    margin-bottom: 30px;
}

.retalier-az .az-top {
    margin-right: 7px;
    border-width: 1px;
    border-radius: 0;
    padding: 0 11px;
    height: 30px;
    line-height: 30px;
    min-width: 0;
    font-size: 1.25em;
    vertical-align: middle;
    margin-top: 2px;
    font-weight: 300;
}

.retalier-az .az-top.az-active {
    background-color: #006abb;
    border-color: #006abb;
    color: $colour-white;
    font-weight: 300;
}

.retalier-az .az-top.az-active:hover,
.retalier-az .az-top.az-active:focus {
    background-color: $colour-light-yellow;
    border-color: $colour-light-yellow;
}

.retalier-az a {
    text-transform: uppercase;
    font-family: 'Open Sans',  sans-serif;
    font-weight: 300;
    font-size: 1.4em;
    display: inline-block;
    vertical-align: middle;
    padding: 0 5px 0 8px;
    color: #333333;
}

.retalier-az ul {
    margin-bottom: 0;
}

.retalier-az ul > li {
    padding: 0;
    margin-bottom: 8px;
}

.retalier-az ul > li + li + li {
    border-left: 1px solid #c9c9c9;
}

@media screen and (min-width:768px) and (max-width:1200px) {
    .retalier-az ul {
        width: 80%;
        margin: 0 auto;
    }

}

.retalier-az .az-active {
    font-weight: 700;
    color: #006abb;
}

.basket-page .page-header h1,
.basket-page .page-header p {
    text-transform: none;
}

.basket-page .basket-wrapper {
    padding-top: 41px;
    padding-bottom: 41px;
}

.basket-page .basket-details {
    font-size: 0.875em;
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #e5e5e5;
    border-right: 0;
}

.basket-page .basket-header {
    background-color: #f3f3f3;
    text-transform: uppercase;
    border-bottom: 1px solid #e5e5e5;
    font-family: 'Open Sans',  sans-serif;
    font-weight: bold;
    margin: 0;
}

.basket-page .basket-header > th,
.basket-page .basket-item > td,
.basket-page .basket-delivery > td {
    vertical-align: top;
    padding-top: 12px;
    padding-bottom: 12px;
}

.basket-page .basket-header > th + th,
.basket-page .basket-item > td + td,
.basket-page .basket-delivery > td + td {
    border-left: 1px solid #e5e5e5;
}

.basket-page .basket-delivery {
    margin: 0;
}

.basket-page .basket-item > td {
    height: 6em;
}

.basket-page .basket-header div:last-child,
.basket-page .basket-item div:last-child,
.basket-page .basket-delivery div:last-child {
    border-right: none;
}

.basket-page .basket-item {
    border-bottom: 1px solid #e5e5e5;
    margin: 0;
}

.basket-page .basket-delivery > div {
    min-height: 4em;
}

.basket-page .basket-item-img {
    float: left;
    padding-right: 1em;
    width: 80px;
}

.basket-page .basket-bin {
    color: #000000;
}

.basket-page .basket-item-img img {
    max-width: 100%;
    max-height: 40px;
}

.basket-page .basket-item-copy {
    float: left;
}

.basket-page .basket-total td {
    vertical-align: top;
    border-top: 4px solid #000000;
    background-color: #f3f3f3;
    height: 85px;
    padding-top: 15px;
    font-size: 1.125em;
    margin: 0;
}

.basket-page .total-label {
    font-family: 'Open Sans',  sans-serif;
    font-weight: bold;
    color: #565a5c;
}

.basket-page .value-amount {
    width: 42px;
    height: 26px;
    line-height: 26px;
    padding: 0 10px;
}

.basket-page .payment-type-group label {
    border-color: #e6e6e6;
    border-radius: 0;
    box-shadow: 0 1px 1px 0 #bfbfbf;
    height: 40px;
    margin: 0.5em 0 0 0.7em;
    max-width: 60px;
    min-width: 60px;
}

.basket-page .payment-cards {
    margin-top: 0.5em;
}

.basket-page .payment-type-group .card-maestro {
    background-position: -115px 0 !important;
}

.basket-page .payment-type-group .card-visa {
    background-position: -57px 0 !important;
}

.basket-page .payment-type-group .card-amex {
    background-position: -168px 0 !important;
}

.basket-page .continue-shopping a {
    line-height: 5em;
}

.basket-page .checkout-btn-wrapper {
    padding-top: 20px;
}

.basket-page .panel-footer p {
    font-family: 'Open Sans',  sans-serif;
    color: #565a5c;
}

.basket-page .panel-footer p i {
    margin-right: 4px;
}

@media (max-width:1199px) {
    .basket-page .value-amount {
        width: 25px;
        padding: 0;
    }

}
@media (max-width:767px) {
    .basket-page .basket-details {
        margin-left: 5px;
        margin-right: 5px;
    }

    .basket-page .value-amount {
        width: 30px;
        padding: 0;
    }

    .basket-page .basket-remove {
        float: right;
    }

}
@media (max-width:991px) {
    .basket-page .basket-header {
        font-size: 0.85em;
    }

    .basket-page .basket-header div.basket-desc {
        border-bottom: solid 1px #dbdbdb;
        border-right: none;
    }

    .basket-page .basket-header > th,
    .basket-page .basket-item > td {
        padding-top: 6px;
        padding-bottom: 6px;
        border-right: none;
    }

    .basket-page .basket-item > td,
    .basket-page .basket-delivery > td {
        border: none;
        height: auto;
    }

    .basket-page .basket-value {
        text-align: left;
    }

    .basket-page .basket-total {
        font-size: 0.875em;
    }

    .basket-page .value-amount {
        text-align: center;
        width: 30px;
    }

}

.checkout-form .page-header h2 {
    font-weight: 300;
    font-size: 1.7em;
    margin: 0 0 1em;
}

.checkout-form .page-header p {
    margin: 0 0 0.1em;
}

.checkout-form .page-header p span {
    font-weight: 700;
}

.checkout-form .page-header button {
    margin-top: 1.5em;
    width: 100%;
    max-width: 375px;
}

.checkout-form .form-container {
    padding-top: 41px;
}

.checkout-form .form-container form {
    font-size: 0.9em;
}

.checkout-form .form-container form .noleftborder {
    padding-left: 0;
}

.checkout-form .form-container form h2 {
    font-size: 1.5em;
    margin: 0 0 1em;
    padding: 0 0 0.5em;
    border-bottom: solid 1px #000000;
}

.checkout-form .form-container form h2 span {
    color: #286090;
    font-size: 0.9em;
    font-weight: 300;
    font-family: Arial,  Helvetica,  sans-serif;
}

.checkout-form .form-container form h2 .fa-chevron-down {
    float: right;
    font-size: 0.8em;
    margin: 0.3em 0 0;
}

.checkout-form .form-container form h2 .change-details {
    float: right;
    font-size: 0.6em;
    vertical-align: middle;
}

.checkout-form .form-container form h2 .change-details .fa-cog {
    font-size: 1.3em;
    margin: 0 0 0 0.3em;
}

.checkout-form .form-container form h2 .receipt-btn {
    border: solid 1px #006abb;
    padding: 0.5em 0.8em;
    font-size: 0.5em;
}

.checkout-form .form-container form h2 .receipt-btn:last-child {
    margin-right: 0.5em;
}

.checkout-form .form-container form .receipt-button-container {
    float: right;
}

.checkout-form .form-container form .receipt-button-container .receipt-btn {
    border: solid 1px #006abb;
    padding: 0.5em 0.8em;
}

.checkout-form .form-container form .inactive-section {
    border-bottom-color: #e7e7e7;
    color: #b5b5b5;
    font-weight: 400;
}

.checkout-form .form-container form .inactive-section span {
    color: #b5b5b5;
}

.checkout-form .form-container form .no-bottom-border {
    border-bottom: none;
    margin-bottom: 0;
}

.checkout-form .form-container form .form-summary-box {
    background-color: #f3f3f3;
    border-top: solid 3px #286090;
    margin: 0 0 2em;
    padding: 1.5em 0;
}

.checkout-form .form-container form .form-summary-box .col-sm-6 {
    padding: 0;
}

.checkout-form .form-container form .form-summary-box h3 {
    text-transform: uppercase;
    font-size: 1em;
    margin: 0 0 1.55em;
    font-weight: bold;
    color: #565a5c;
}

.checkout-form .form-container form .form-summary-box p {
    color: #565a5c;
    line-height: 1.6em;
    margin: 0 0 0.8em;
}

.checkout-form .form-container form .summary-receipt-box {
    border: solid 1px #e5e5e5;
}

.checkout-form .form-container form .with-bottomborder {
    border-bottom: 1px solid #e5e5e5;
}

.checkout-form .form-container form .reciept-summary-box {
    border-top: none !important;
    background-color: transparent !important;
}

.checkout-form .form-container form .reciept-summary-box div:first-child {
    padding-left: 0 !important;
}

.checkout-form .form-container form .reciept-summary-box #billing-card-details {
    margin-top: 2em;
}

.checkout-form .form-container form .form-main {
    margin: 0.8em 0 1em;
    color: #565a5c;
}

.checkout-form .form-container form .form-main legend {
    text-transform: uppercase;
    border: none;
    font-size: 1em;
    font-weight: bold;
    color: #565a5c;
}

.checkout-form .form-container form .form-main .form-group {
    margin-bottom: 2em;
}

.checkout-form .form-container form .form-main .form-group label {
    font-weight: bold;
    vertical-align: middle;
    display: block;
    margin-bottom: 0;
}

.checkout-form .form-container form .form-main .form-group label span {
    font-weight: 300;
}

.checkout-form .form-container form .form-main .form-group label#lblCreditcard {
    margin-top: 1em;
}

.checkout-form .form-container form .form-main .form-group label#lblRemembercard {
    font-weight: 700;
}

.checkout-form .form-container form .form-main .form-group label#lblRemembercard span {
    font-weight: 300;
    font-size: 0.75em;
    position: relative;
}

.checkout-form .form-container form .form-main .form-group .form-field-descripton {
    font-size: 0.8em;
}

.checkout-form .form-container form .form-main .form-group .form-control {
    font-size: 1em;
    color: #565a5c;
    line-height: 35px;
    height: 35px;
    padding-left: 7px;
    margin: 5px 0 0;
}

.checkout-form .form-container form .form-main .form-group .inline-container {
    margin-bottom: 1em;
}

.checkout-form .form-container form .form-main .form-group .inline-container .form-control {
    width: 100%;
}

.checkout-form .form-container form .form-main .form-group #card-cvc {
    display: inline-block;
    width: 72px;
    height: 33px;
    border: solid 1px #d0d0d0;
    margin: 0 0 0 0.5em;
    position: relative;
    top: 13px;
}

.checkout-form .form-container form .form-main .form-group .dropdown-toggle {
    width: 100%;
    text-align: left;
    color: #d0d0d0;
}

.checkout-form .form-container form .form-main .form-group .dropdown-toggle .caret {
    float: right;
    margin: 1em 0.2em 0 0;
}

.checkout-form .form-container form .form-main .form-group .dropdown-menu {
    width: 100%;
    height: auto;
    max-height: 200px;
    overflow-x: hidden;
    margin-top: 0;
}

.checkout-form .form-container form .form-main .form-group p {
    margin: 1em 0;
}

.checkout-form .form-container form .form-main .form-group p .form-link {
    color: $colour-endeavour;
    text-decoration: none;
}

.checkout-form .form-container form .form-main .form-group p a:hover {
    color: $colour-endeavour;
    text-decoration: underline;
}

.checkout-form .form-container form .form-main .form-inline {
    margin-bottom: 0;
}

.checkout-form .form-container form .form-main .form-payment {
    overflow: hidden;
}

.checkout-form .form-container form .form-main .form-payment .form-control {
    display: inline-block;
    width: 40% !important;
}

.checkout-form .form-container form .form-main .form-card-details div {
    padding: 0;
}

.checkout-form .form-container form .form-main .btn i {
    margin: 0 0 0 0.8em;
    font-size: 0.8em;
    font-weight: 300;
}

.checkout-form .form-container form .btn-group label.btn,
.checkout-form .form-container form .btn-group .btn-card {
    border-color: #e6e6e6;
    padding: 0;
    min-width: 60px;
    width: 60px;
    max-width: 60px;
    height: 40px;
    margin: 0.5em 0 0 0.7em;
    border-radius: 0;
    box-shadow: 0 1px 1px 0 #bfbfbf;
    box-shadow: 0 1px 1px 0 #bfbfbf;
    box-shadow: 0 1px 1px 0 #bfbfbf;
}

.checkout-form .form-container form .btn-group label.btn input,
.checkout-form .form-container form .btn-group .btn-card input {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.checkout-form .form-container form .btn-group .card-visa {
    background-position: -57px 0 !important;
}

.checkout-form .form-container form .btn-group .card-maestro {
    background-position: -115px 0 !important;
}

.checkout-form .form-container form .btn-group .card-amex {
    background-position: -170px 0 !important;
}

.checkout-form .form-container form .btn-group label:first-child {
    margin-left: 0;
}

.checkout-form .form-container form .btn-group label.active {
    border-color: #286090;
}

.checkout-form .form-container form .btn-group #existing-card-details {
    margin-left: 1em;
    line-height: 51px;
}

.checkout-form .form-container form .btn {
    margin-bottom: 2em;
    width: 100%;
    max-width: 375px;
}

.checkout-form .form-container form .checkout-secure {
    font-size: 0.9em;
    color: #565a5c;
}

.checkout-form .form-container form .checkout-secure i {
    margin-right: 0.5em;
    font-size: 1.2em;
}

@media (min-width:768px) {
    .checkout-form .page-header button {
        width: auto;
    }

    .checkout-form .form-container form h2 {
        font-size: 2em;
    }

    .checkout-form .form-container form .form-summary-box {
        padding: 2em 0;
    }

    .checkout-form .form-container form .form-summary-box .col-sm-6 {
        padding: 0;
    }

    .checkout-form .form-container form .form-summary-box div:first-child {
        padding-left: 2.8em;
    }

    .checkout-form .form-container form .form-summary-box div:last-child {
        padding-right: 2.8em;
    }

    .checkout-form .form-container form .form-summary-box .btn-group {
        padding: 0 !important;
    }

    .checkout-form .form-container form .reciept-summary-box {
        padding-top: 3em;
    }

    .checkout-form .form-container form .reciept-summary-box #billing-card-details {
        margin-top: 5em;
    }

    .checkout-form .form-container form .form-main .form-group label#lblCreditcard {
        margin-top: 0;
    }

    .checkout-form .form-container form .form-main .form-group .label-error {
        color: #ff0000;
    }

    .checkout-form .form-container form .form-main .form-group .inline-container {
        float: left;
        width: 48%;
    }

    .checkout-form .form-container form .form-main .form-group .align-right {
        float: right;
        margin-bottom: 0;
    }

    .checkout-form .form-container form .form-main .form-inline label {
        width: 100%;
    }

    .checkout-form .form-container form .btn {
        width: auto;
    }

}

.no-js .checkout-form-step12 .collapse {
    display: block !important;
}

.no-js .btn-card,
.no-js .print-receipt {
    display: none !important;
}

.activity-page {
    background-color: $colour-white;
}

.activity-page .page-header .btn {
    color: $colour-white;
    border-color: $colour-white;
}

.activity-page .noleftborder {
    padding-left: 0;
}

.activity-page .activity-table {
    border: 1px solid #e5e5e5;
    font-size: 0.9em;
    margin-bottom: 3em;
}

.activity-page .activity-table .row {
    margin: 0;
}

.activity-page .activity-table .activity-header {
    background-color: #f3f3f3;
    text-transform: uppercase;
    border-bottom: 1px solid #e5e5e5;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: #565a5c;
}

.activity-page .activity-table .activity-header > th {
    padding-top: 12px;
    padding-bottom: 12px;
}

.activity-page .activity-table .activity-header > th + th {
    border-left: 1px solid #e5e5e5;
}

.activity-page .activity-item {
    border-bottom: 1px solid #e5e5e5;
}

.activity-page .activity-item > td {
    min-height: 14em;
    padding-top: 12px;
    padding-bottom: 12px;
}

.activity-page .activity-item > td + td {
    border-left: 1px solid #e5e5e5;
}

.activity-page .activity-desc p {
    margin: 0;
}

.activity-page .activity-desc .activity-desc-desc {
    font-size: 0.9em;
    margin-bottom: 0.3em;
}

.activity-page .activity-desc .activity-desc-details {
    font-size: 0.9em;
}

.activity-page .activity-desc .activity-desc-details a {
    color: #006abb;
}

.activity-page .activity-desc button {
    margin-top: 2em;
    min-width: 8.5em;
}

.activity-page #cashback-enquiry {
    font-weight: 700;
    font-size: 1.2em;
    margin: 0 0 0.5em;
    color: #565a5c;
}

.activity-page #cashback-statement {
    font-size: 0.8em;
    color: #565a5c;
}

.activity-page #cashback-statement span {
    font-weight: 700;
}

.activity-page #check-cashback {
    font-size: 0.8em;
}

.activity-page #check-cashback a {
    color: #006abb;
}

.activity-page .always-show {
    display: block !important;
    text-align: center;
}

.activity-page .always-show i {
    font-size: 26px;
}

.activity-page .account-unlock-content {
    margin-top: 2em;
    color: #565a5c;
}

.activity-page .account-unlock-content .form-group {
    margin-bottom: 1.5em;
}

.activity-page .account-unlock-content .form-group label {
    font-weight: bold;
    margin-bottom: 0;
    font-size: 0.8em;
}

.activity-page .account-unlock-content .form-group .form-control {
    font-size: 1em;
    color: #565a5c;
    line-height: 35px;
    height: 35px;
    padding: 0 7px;
    margin: 5px 0 0;
}

.activity-page .account-unlock-content .btn {
    min-width: 7.5em;
}

.activity-page .account-unlock-content .btn-cancel {
    float: right;
    border-color: #000000;
    background-color: $colour-white;
}

.activity-page .account-unlock-text {
    margin-top: 2em;
}

.activity-page .account-unlock-text p {
    margin: 0 0 1em;
    color: #565a5c;
    font-size: 0.8em;
}

@media screen and (min-width:768px) {
    .account-unlock-content {
        border-right: solid 1px #e3e3e3;
        padding-right: 2em;
    }

    .account-unlock-content .btn {
        min-width: 8.5em;
        width: 40%;
    }

}
@media screen and (min-width:992px) {
    .account-unlock-content {
        padding-right: 4em;
        margin-bottom: 2em;
    }

}
@media screen and (max-width:768px) {
    .account-unlock-content .btn {
        width: 100%;
        margin-bottom: 1em;
    }

    .activity-page .activity-table {
        border-left: none;
    }

}
@media screen and (max-width:992px) {
    .activity-table .activity-desc .btn {
        padding-left: 0;
        padding-right: 0;
        min-width: 7em;
    }

}
@media screen and (min-width:1200px) {
    .account-unlock-text {
        padding: 0;
    }

}

.account-page .page-header {
    margin-bottom: 0;
}

.savings-banner {
    background-color: #e7e7e7;
    font-size: 1.5em;
    padding: 20px 10px;
    margin-bottom: 50px;
}

.savings-banner span {
    color: #006abb;
}

.account-option,
.discount-card {
    color: #333333;
    font-size: 0.875em;
    display: block;
    margin-bottom: 30px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.account-option .panel-heading,
.discount-card .panel-heading {
    min-height: 175px;
    position: relative;
}

.account-option .svg,
.discount-card .svg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -60px;
    margin-left: -60px;
}

.account-option .image-wrapper,
.discount-card .image-wrapper {
    position: absolute;
    position: relative/9;
    top: 50%;
    right: 0;
    left: 0;
    transform: translateY(-50%);
    transform: translateY(-50%);
    transform: translateY(-50%);
}

.account-option .title,
.discount-card .title {
    color: #333333;
    font-size: 1.29em;
    font-weight: 400;
    margin-bottom: 0;
}

.account-option a,
.discount-card a {
    font-weight: bold;
}

.discount-card {
    color: #8d8b8b;
}

.account-option p {
    color: inherit;
}

.account-option:hover,
.account-option:focus,
.discount-card:hover,
.discount-card:focus {
    color: inherit;
    text-decoration: none;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.discount-card .panel-body {
    background-color: #f9f9f9;
}

@media (min-width:992px) and (max-width:1200px) {
    .account-option .panel-body {
        min-height: 130px;
    }

}

.favorite-item {
    background-color: #fcfcfc;
    background: -webkit-gradient(radial, center center, 0, center center, 100%, color-stop(0%, #f9f9f9), color-stop(100%, #fdfdfd));
    background: radial-gradient(center, ellipse cover, #f9f9f9 0%, #fdfdfd 70%);
    background: radial-gradient(ellipse at center, #f9f9f9 0%, #fdfdfd 70%);
    font-size: 0.75em;
    overflow: hidden;
    min-height: 170px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    position: relative;
}

.favorite-item img {
    display: inline;
    max-width: 100%;
}

.favorite-item .panel-heading {
    line-height: 110px;
    text-align: center;
    float: left;
    width: 35%;
    position: absolute;
    top: 0;
    bottom: 0;
}

.favorite-item .panel-body {
    float: right;
    width: 65%;
}

.favorite-item .title {
    font-size: 1.33em;
}

.favorite-item .title a {
    text-decoration: none;
}

.favorite-item .btn {
    color: #006abb;
    font-size: 1em;
    padding: 5px;
    min-width: 0;
    margin-top: 1em;
}

.favorite-item .btn i {
    margin: 0;
}

@media (min-width:992px) {
    .favorite-item .panel-heading {
        line-height: 130px;
        width: 45%;
    }

    .favorite-item .panel-body {
        width: 55%;
    }

}

.favorites-empty .form-container {
    padding-top: 20px;
}

.favorites-empty h2 {
    font-size: 1.77em;
    font-weight: 400;
}

.favorites-empty p {
    max-width: 660px;
    margin: auto;
}

.favorites-empty .form-main {
    margin-top: 40px;
}

.faq-heading h4 a {
    display: block;
    width: 100%;
    padding: 20px 22px 20px 0;
    position: relative;
}

.faq.row {
    margin: 0;
}

.faq h2 {
    font-size: 1.5625em;
    margin-bottom: 0.8em;
}

.faq-item {
    border-top: solid 1px #e5e5e5;
}

.panel-group .faq-item:last-child {
    border-bottom: solid 1px #e5e5e5;
}

.faq-heading h4 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1em;
}

.faq-item.expanded {
    background-color: #f4f4f4;
    padding-bottom: 20px;
    padding-right: 20px;
}

.faq-title .collapsed::after {
    position: absolute;
    content: '\f104';
    display: block;
    font-family: FontAwesome;
    right: 10px;
    top: 20px;
    color: #000000;
}

.faq-title a {
    text-decoration: none;
}

.faq-title a::after {
    position: absolute;
    content: '\f107';
    display: block;
    font-family: FontAwesome;
    right: 10px;
    top: 20px;
    color: #000000;
}

.retail-discount-new .form-title {
    margin-bottom: 1.3em;
}

.retail-discount-new .form-extrainfo {
    margin-top: 15px;
}

.retail-discount-top-up .radio select,
.retail-discount-top-up .radio input[type=text] {
    width: 80%;
    margin-left: 30px;
}

.retail-discount-top-up .radio label {
    padding-left: 4px;
}

#top-up-fulldate-label {
    display: block;
}

.voucher-form-change {
    color: $colour-white;
    font-size: 0.8em;
    text-decoration: underline;
    position: absolute;
    top: 20px;
    right: 20px;
}

.voucher-form-change:hover {
    color: $colour-white;
    text-decoration: none;
}

@media (max-width:767px) {
    .voucher-form-change {
        top: 44px;
    }

}

.error-page {
    padding: 3.125em 0;
}

.error-page h2 {
    font-size: 1.825em;
    font-weight: 300;
    margin-top: 0.33em;
    margin-bottom: 2em;
}

.error-page a {
    color: #006abb;
}

.icon-64 {
    font-size: 80px;
    padding-bottom: 20px;
    padding-top: 20px;
}

.svg-icon-fa .fa {
    font-size: 30px;
}

// .alert.alert-info,  .alert.alert-danger,  .alert.alert-success {
//     margin: 0;
// }

.active-like-button {
    background-color: $colour-light-yellow;
    border-color: $colour-light-yellow;
    min-width: 0;
    padding: 0.4em 0.8em;
    margin-right: 10px;
    color: #333333;
    font-size: 12px;
    text-decoration: none;
}

.contact-details-page .form-group--name {
    margin-left: 0;
}

.contact-details-page .form-group--name.form-control,
.contact-number-group .form-control {
    margin-bottom: 10px;
}

.gap {
    margin-top: 20px;
}

.pagination > li:first-child a,
.pagination > li:last-child a {
    color: #333333;
}

.pagination .next,
.pagination .prev,
.pagination .next,
.pagination .prev {
    background-color: $colour-white;
    border-color: #bdbdbd;
    color: #333333;
}

.pagination>li>a.active,
.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
    background-color: #eeeeee;
    border-color: #dddddd;
    color: #23527c;
}

.custom-form-control-label-margin {
    margin-top: 26px;
}

.custom-form-control {
    width: 100%;
    border: solid 1px #e5e5e5;
    border-bottom: 0;
}

.activity-headers {
    font-size: 24px;
    margin: 0 0 16px;
    font-family: 'Open Sans',  sans-serif;
}

#top-info-alert {
    margin: 0 0 30px;
}

.error-message {
    color: #a94442;
    font-size: 14px;
}

.success-message {
    color: #1bab1e;
    font-size: 14px;
}

.panel-discount .discount-off {
    font-size: 12px;
}

.landing-jumbotron-logo img,
.jumbotron-logo-circle img {
    max-width: 133px;
}

.large-icon {
    font-size: 60px;
}

.shopping-voucher-input {
    line-height: 1.2em;
    height: auto;
    width: 100%;
    text-align: center;
    padding: 3px 0;
    border: none;
    border-top: 1px solid #d0d0d0;
    background-clip: padding-box;
    border-radius: 0;
}

#voucher-form-tag .total-rrp {
    margin-top: 8px;
}

.context-panel-form form {
    padding: 1px 8% 15px;
}

.navbar .label-info {
    margin-left: 8px;
    padding-left: 4px;
    padding-right: 4px;
}

.search-wrapper .btn {
    width: 85px;
}

.reloadable-border-bottom {
    border-bottom: 1px solid rgb(215, 215, 215) !important;
}

.contact-us-body .alert {
    margin-bottom: 20px;
}

.error-list {
    margin-top: 10px;
}

.contact-us-body ul li {
    margin-bottom: 0;
}

.contact-form-wrap .form-control {
    margin-bottom: 14px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    opacity: 1;
}

.delete-discount {
    top: 38px;
    left: 10px;
    font-size: 22px;
    z-index: 10000;
}

.header-back-btn a,
.btn-transparent.btn-blue {
    color: white;
}

.image-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.container {
    position: relative;
    z-index: 2;
}

.container-top {
    z-index: 3;
}

.carousel-control {
    z-index: 800;
}

.carousel-caption {
    text-shadow: 0 2px 6px rgba(0, 0, 0, 0.8);
}

.jumbotron .h1,
.jumbotron h1 {
    text-shadow: 0 2px 6px rgba(0, 0, 0, 0.8);
}

.jumbotron p {
    text-shadow: 0 2px 6px rgba(0, 0, 0, 0.8);
}

.text {
    font-size: 1em !important;
}

.search23 {
    display: none;
}

.products-index-shadow-panel {
    text-shadow: 0 2px 6px rgba(0, 0, 0, 0.8);
}

@media only screen and (min-width:768px) {
    .benefit-center {
        display: -webkit-box !important;
        display: -moz-box !important;
        display: flexbox !important;
        display: flex !important;
        box-pack: center !important;
        box-pack: center !important;
        flex-pack: center !important;
        justify-content: center !important;
        justify-content: center !important;
    }

}

#inline-search {
    line-height: normal !important;
}

.my-wallet-page .headerWallet {
    background-image: url(http://loremflickr.com/g/2020/280/paris);
    background-position: right bottom,  left top;
    background-repeat: no-repeat,  repeat;
}

.my-wallet-page .ewallet-header {
    min-height: 180px;
}

.my-wallet-page .container-tabs {
    width: 90%;
    margin: auto;
}

.my-wallet-page .nav-tabs {
    padding: 20px 0;
}

.my-wallet-page .tab-content {
    background-color: #414346;
    border-radius: 0 0 0 20px;
    color: white;
    margin: -20px 0;
    padding: 30px;
}

.my-wallet-page .back {
    float: right;
    margin: -50px 50px;
    border-radius: 2px 4px 0 0;
    border-color: #3b4044;
    padding: 7px 20px;
}

.my-wallet-page .back a {
    color: $colour-white;
}

.my-wallet-page .nav-tabs>li>a {
    background-color: #7b7a7a;
    color: $colour-white;
}

.my-wallet-page .nav-tabs>li.active>a,
.my-wallet-page .nav-tabs>li.active>a:focus,
.my-wallet-page .nav-tabs>li.active>a:hover,
.my-wallet-page .nav-tabs>li>a:hover {
    background-color: #000000 !important;
    color: $colour-white;
}

.my-wallet-page .nav-tabs>li {
    float: left;
    margin-bottom: -1px;
    margin-right: -2px;
}

.my-wallet-page .nav-tabs>li>a {
    border-radius: 12px 14px 0 0 !important;
}

.my-wallet-page .nav-tabs>li>a:hover {
    border-color: transparent !important;
}

.my-wallet-page .count {
    padding: 0 20px;
}

.my-wallet-page .list-group-item {
    color: #000000;
}

.my-wallet-page .tab-content>.tab-pane {
    width: 60%;
    text-align: justify;
}

h1.headerTlt {
    text-transform: lowercase;
}

h1.headerTlt::first-letter {
    text-transform: capitalize;
}

.readers {
    text-indent: -10000px;
}

.my-wallet-page .container {
    float: left;
}

.my-wallet-page .container DIV {
    float: left;
}

.my-wallet-page .container DIV.details {
    width: 40%;
    padding: 0 20px;
    margin: -15px 0;
    text-align: justify;
}

.my-wallet-page .container .box {
    border: 1px solid;
    margin: 20px 0;
    float: left;
}

.my-wallet-page .container .totalSavings {
    float: right;
}

.my-wallet-page .container .total {
    background: #cccccc;
    border: 1px solid;
    padding: 8px 50px;
}

.readers .alert {
    background: none !important;
    padding: 0;
    border: 0;
    height: 0;
}

.avatar-left {
    position: relative;
    margin-top: -410px;
    padding-left: 70px;
}

.avatar-right {
    position: relative;
    margin-top: -410px;
    padding-right: 70px;
}

.benefit-left {
    position: relative;
    margin-top: -25px;
    margin-left: -25px;
}

.benefit-left1 {
    position: relative;
}

.benefit-left2 {
    position: relative;
    margin-top: -410px;
    padding-left: 70px;
}

.benefit-left3 {
    position: relative;
    padding-left: 70px;
    background-position: center top;
}

.login-form #UserJoinForm h1,
.login-form #UserJoinForm p,
.login-form #UserLoginForm h1,
.login-form #UserLoginForm p,
#UserForgotPasswordForm h1,
#UserForgotPasswordForm p {
    text-align: left;
}

.login-form #UserJoinForm .form-col-container,
.login-form #UserJoinForm .col-sm-12,
.login-form #UserJoinForm col-md-offset-2,
.login-form #UserJoinForm col-md-5,
.login-form #UserLoginForm .col-lg-offset-4 {
    float: left;
}

.login-form #UserJoinForm .col-sm-12 {
    margin-left: 0;
}

.login-form {
    width: 50%;
    margin: 0 auto;
}

.forgotten-password {
    text-align: center !important;
}

h1.error {
    font-size: 16px;
}

.text-danger {
    font-size: 20px;
}

.company-header-avatar {
    //width: 100px;
    //height: 100px;
    border-radius: 60px;
    background-clip: padding-box;
    border-radius: 60px;
    background-clip: padding;
    border-radius: 60px;
    background-clip: padding-box;
    margin: 7px 0 0 5px;
    background-size: cover;
    background-position: center center;
}

.carsol-jumbotron-inner {
    background-color: $colour-white;
    line-height: 135px;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    margin: auto;
}

.carsol-jumbotron-inner img {
    max-width: 110px !important;
    max-height: 90px;
}

.carsol-jumbotron-indicator {
    background-color: $colour-white;
    line-height: 65px;
    width: 68px;
    height: 69px;
    border-radius: 50%;
    margin: auto;
}

.carsol-jumbotron-indicator img {
    max-width: 68px !important;
    max-height: 80px;
}
