//
// Cashback Enquiry Summary Layout
// ==========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.c-ces-layout {
    display: flex;
    flex-direction: row;

    @include breakpoint(sm-plus) {
        justify-content: space-between;
    }
}

// == Elements
//

.c-ces-layout__content:nth-of-type(2) {
    @include breakpoint(sm-plus) {
        order: 3;
    }
}

.c-ces-layout__content:nth-of-type(3) {
    @include breakpoint(sm-plus) {
        justify-self: flex-end;

        max-width: calc(410px + 2rem);
    }
}
