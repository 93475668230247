/**
 * Url friendly colour
 *
 * To be used as follows:
 * fill='#{url-friendly-colour(varcolour)}'
 *
 * Function to convert an hexcode into a url friendly colour code to be use in
 *
 */

 @function url-friendly-colour($colour) {
    @return '%23' + str-slice('#{$colour}', 2, -1);
}
