
/**
 * headings
 *
 * To be used as follows:
 * @include u-heading-small;
 *
 */

@use 'config' as *;
@use 'breakpoint' as *;

@mixin container {
    margin: 0 auto;
    padding-left: $site-gutter;
    padding-right: $site-gutter;
    max-width: $site-max-width;
}
