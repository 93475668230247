//
// Pill Navigation Menu
// ==========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.c-pnm {
    padding: 0.1rem 0 1rem;

    @include breakpoint(sm-plus) {
        padding-top: 2.8rem;
        margin: 0 auto;

        width: 45rem;
    }

    @include breakpoint(md-plus) {
        width: 58rem;
    }

    @include breakpoint(lg-plus) {
        width: 71rem;
    }
}

//== Elements
//

.c-pnm__header {
    display: flex;
    align-items: stretch;

    margin: 0;
    padding: 0;

    list-style: none;
}

.c-pnm__content {
    margin: 1rem;

    @include breakpoint(sm-plus) {
        margin: 1rem 0 0;
    }
}

.c-pnm__header-tab {
    display: flex;
    flex: 1 1 auto;

    margin: 0;
    padding: 0;

    text-align: center;

    @include breakpoint(sm-plus) {
        flex: 0 0 auto;
    }
}

.c-pnm__header-link {
    display: block;

    width: 100%;
    padding: 0.5rem;

    font-size: $line-height-base;
    font-family: $font-open-sans;

    @include breakpoint(sm-plus) {
        padding: 0.2rem 3rem;
    }

    &:hover,
    &:active {
        color: inherit;
        text-decoration: none;
    }
}

.c-pnm__header-link-not-active {
    color: $link-color
}

//== Modifiers
//
