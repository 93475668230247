//
// Recaptcha Button.
// ==========================================

@use 'config' as *;
@use 'utility' as *;

.recaptcha-button {
    min-width: auto;
}

@media screen and (max-width:768px) {
    /*
        Default behaviour (specified by Google) is the following:

            transform: scale(.875);
            transform-origin: 0 0;

        However, performing the scale transform appears to break the fixed positioning of the badge on smaller screen
        sizes. Override this behaviour - a slightly larger badge is better than a smaller badge that appears covering up
        content in the middle of the page.
    */
    .g-recaptcha {
        transform: none;
    }

}
