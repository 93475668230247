//
// Product Carousel
// ==========================================

@use 'config' as *;
@use 'utility' as *;

.product-carousel__control {
  z-index: 10000;
  cursor: pointer;
  bottom: 0.5em;
  right: 0.5em;
  height: 44px;
  width: 44px;
  color: $colour-white;
  background-color: $colour-black;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

.product-carousel__control:hover {
  background-color: $colour-dark-chambray;
}

.product-carousel__control > span {
  top: -1px;
}

.sr-only-carousel-button-text {
  color: $colour-darker-grey;
}
