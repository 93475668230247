@use 'config' as *;
@use 'utility' as *;

/**
    Additional custom css for the Splide slider for customisation
 */

$_arrow-colour: #F2F2F2;

.slider__slide {
    display: flex;
}

.slider__arrow {
    border-radius: $button-border-radius;
    height: 52px;
    width: 52px;
    opacity: 1;
    background-color: $_arrow-colour;

    &:hover {
        &:not(:disabled) {
            opacity: 1;
            background-color: $colour-dark-chambray;

            path {
                fill: $colour-white;
            }
        }

    }

    .slider__svg-arrow {
        height: 30px;
        width: 30px;
    }
}

.slider__arrow--left {
    @include breakpoint(xl-plus) {
        left: -60px;
    }
}

.slider__arrow--right {
    @include breakpoint(xl-plus) {
       right: -60px;
    }
}

.slider__toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    border-radius: $button-border-radius;
    width: 52px;
    height: 52px;
    background-color: $_arrow-colour;

    &:hover {
        background-color: $colour-dark-chambray;
        fill: $colour-white;

        svg {
          fill: $colour-white;
        }
    }
}
