//
// Cashback Enquiry Activity
// ==========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

// == Elements
//

.c-cebd__button {
    width: 100%;

    margin-bottom: 0.5rem;

    @include breakpoint(sm-plus) {
        width: auto;
    }
}

.c-cebd__sort-code {
    margin-bottom: 10px !important;
}

.c-cebd .o-input__subtext {
    display: inline-block;
    margin-bottom: 20px;
}
