//
// Product panel list
// =========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.c-product-panel-list__result-count-section {
    background-color: #ececec;
    margin-bottom: 1.5rem;
    padding: 0.5rem;
}

.c-product-panel-list__result-count-query--alternative {
    font-style: oblique;
}

.c-product-panel-list__result-count-query {
    font-weight: bold;
}

.c-product-panel-list__perform-original-search {
    font-size: 0.9em;
    margin-top: 0.5rem;
}

.c-product-panel-list__result-list {
    padding: 0;
}
