//
// 2fa Progress Indicator
// ==========================================

@use 'config' as *;
@use 'utility' as *;

.c-2fa-workflow {
    height: 90px;

    &__body {
        display: flex;
        align-items: center;
        padding: 2rem;
    }

    &__circle {
        display: flex;
        height: 30px;
        width: 30px;
        justify-content: center;
        border: 2px solid $colour-dark-grey;
        border-radius: 100px;
    }

    &__circle--reached {
        background-color: $colour-dark-grey;
    }

    &__line {
        display: flex;
        height: 0;
        width: 31%;
        margin-right: 10px;
        border: 1px solid $colour-dark-grey;
    }

    &__step-number {
        margin: 4px 0 0;
        font-size: 13px;
    }

    &__step-number--reached {
        color: $colour-white;
    }

    &__label {
        position: relative;
        text-align: center;
        font-size: 14px;
        padding: 0 10px;
    }

    &__mobile-step-label {
        display: none;
    }

    @media screen and (max-width: $screen-lg) {
        &__line {
            display: none;
        }

        &__body {
            margin: 0 auto;
            width: auto;
            align-items: center;
            justify-content: space-between;
        }
    }

    @media (max-width: $screen-sm) {
        &__label {
            display: none;
        }

        &__body {
            margin: 0 auto;
            width: auto;
            align-items: center;
            justify-content: space-between;
        }

        &__line {
            display: flex;
            height: 0;
            width: 26%;
            margin-right: 0;
            border: 1px solid $colour-dark-grey;
        }

        &__circle {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }

        &__step-number {
        }

        &__mobile-step-label {
            display: block;
            width: 48px;
            font-size: 16px;
            margin-top: 10px;
        }
    }
}
