//
// Product widget costs summary
// ==========================================

@use 'config' as *;
@use 'utility' as *;

.c-product-widget-costs-summary__heading {
    font-size: 1em;
    font-weight: normal;
    display: none;
}

.c-product-widget-costs-summary__list {
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: $spacing-s;
    align-items: end;
}

.c-product-widget-costs-summary__label {
    @include body-text-large;
}

.c-product-widget-costs-summary__value {
    @include body-text-large;

    text-align: right;
}

.c-product-widget-costs-summary__value--total {
    @include heading-large;
}

.c-product-widget-costs-summary__value--rrp {
    text-decoration: line-through;
}
