//
// Typography utilities
// ==========================================

@use 'config' as *;
@use 'utility' as *;


//== Block
//

.u-text {}

//== Modifiers
//

.u-text--large {
    font-size: pixel-converter(18px);

    @include breakpoint(sm-plus) {
        margin-bottom: 1.5rem;
        font-size: $font-size-large;
        line-height: 1.4;
    }
}

.u-text--icon {
    position: relative;
    padding-left: 2rem;

    &::before {
        position: absolute;
        left: 0;
        top: 0;

        font-family: 'Material Icons';
        font-style: normal;
        font-weight: 400;
        font-size: pixel-converter(18px);
        content: attr(data-icon);
    }
}

.u-text--right {
    text-align: right;
}

.checkout-title {
    margin: 2rem;
}

.c-search-title {
    color: $colour-white;
    font-family: 'Open Sans', sans-serif;
    font-weight: 200;
    margin: 0 0 2rem;
}
