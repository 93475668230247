
@use 'config' as *;
@use 'utility' as *;

.product-hero {}

.product-hero__picture {
    object-fit: cover;
    width: 100%;
    height: 150px;

    @include breakpoint(sm-plus) {
        height: 350px;
    }

    @include breakpoint(md-plus) {
        height: 500px;
    }
}
