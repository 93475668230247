@use 'config' as *;
@use 'utility' as *;

.c-pagination {
    clear: both;

    &__item {
        border: 1px solid $colour-grey;
        display: inline-block;
        margin-right: 1px;

        &:first-child {
            border-radius: 5px 0 0 5px;
        }

        &:last-child {
            border-radius: 0 5px 5px 0;
        }
    }

    &__item:hover {
        background-color: $colour-blue;

        & a {
            color: $colour-white;
        }

    }

    &__link {
        background-color: $colour-white;
        display: inline-block;
        padding: 6px 12px;

        &:hover,
        &--active {
            background-color: $colour-blue;
            color: $colour-white;
            text-decoration: none;
        }
    }
}
