//
// Product list sort
// =========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//

.c-product-list-sort {
    display: flex;
    justify-content: flex-end;
}
