@use 'config' as *;
@use 'utility' as *;

.e-wallet-page-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;

    display: none;

    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.e-wallet-modal {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: 999;

    background-color: $colour-white;

    @media (min-width: $screen-sm-min) {
        position: absolute;
        top: 0;
        left: auto;
        right: 0;

        width: 500px;
    }
}

.e-wallet-modal__header {
    display: flex;
    justify-content: space-between;
    padding-top: 1.5rem;
    padding-right: 2.5rem;
    padding-bottom: 1.5rem;
    padding-left: 2.5rem;

    background-color: $colour-turquoise;
}

.e-wallet-modal__header-text {
    margin: 0;

    font-size: 1.5rem;
    font-weight: 600;
    color: $colour-white;
}

.e-wallet-modal__header-icon {
    font-size: 1.5rem;

    color: $colour-white;
    cursor: pointer;
}

.e-wallet-modal__content {
    padding: 2.5rem;
    height: 100vh;
}

.e-wallet-modal-hero {
    position: relative;

    margin-bottom: 1.5rem;
}

.e-wallet-modal-hero__no-image {
    position: relative;

    width: 100%;
    height: 15rem;

    background-color: lightgray;
}

.e-wallet-modal-hero__image {
    width: 100%;
    object-fit: cover;
}

.e-wallet-modal-hero__thumbnail {
    position: absolute;
    left: 1rem;
    bottom: 1rem;

    height: 3.75rem;

    object-fit: cover;
    border-radius: 1px;
    border: 2px solid $colour-white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.e-wallet-modal-voucher-details {
    width: 26.25rem;
    margin-bottom: 1.5rem;
}

.e-wallet-modal-voucher-details__retailer {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0.5rem;
    margin-left: 0;

    font-size: 1.5rem;
    font-weight: bold;
}

.e-wallet-modal-voucher-details__offer {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;

    font-size: 1.125rem;
    font-weight: normal;
}

.e-wallet-modal-voucher-details__price {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;

    font-size: 1rem;
    font-weight: normal;
}

.e-wallet-modal-voucher-details__date-added {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;

    font-size: 1rem;
    font-weight: normal;
}

.e-wallet-modal__add-card-details-link {
    display: inline-block;
    margin-bottom: 1rem;
}

.e-wallet-modal__information-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 1rem;
}

.e-wallet-modal-buttons__button {
    width: 100%;
    margin-bottom: 0.5rem;
}

.e-wallet-modal-form__select {
    padding: 0 16px;
    height: 50px;

    font-size: 1rem;
    line-height: 50px;
}

.e-wallet-modal__button {
    width: 100%;
}

.e-wallet-modal__form-title {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0.5rem;
    margin-left: 0;

    font-size: 1.125rem;
    font-weight: bold;
}

.e-wallet-modal__form-subtitle {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 2.5rem;
    margin-left: 0;

    font-size: 1rem;
    font-weight: normal;
}

.e-wallet-modal__form-retailer-select {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 1.5rem;
    margin-left: 0;
}

.e-wallet-modal__form-card-number-input {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 1.5rem;
    margin-left: 0;

    & ul {
        padding-left: 0;
    }

    & li {
        list-style: none;
        color: $colour-punch;
        font-size: 0.85rem;
        line-height: 1;
    }
}

.e-wallet-modal__form-memorable-date-input {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0.5rem;
    margin-left: 0;
}

.e-wallet-modal__form-memorable-subtext {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 2.5rem;
    margin-left: 0;

    font-size: 16px;
    font-weight: normal;
}

.e-wallet-modal__no-active-card-message {
    color: $colour-punch;
    margin-bottom: 1rem;
}

.is-active {
    display: block;
}
