//
// Checkout Complete Header
// ==========================================

@use 'config' as *;
@use 'utility' as *;

.c-checkout-complete-header {
    display: inline-block;
    width: 100%;
    margin-bottom: 40px;

    &__heading {
        width: 50%;
        float: left;
        padding-left: 2rem;
    }

    &__heading-title {
        margin-top: 0;
    }

    &__button {
        margin-top: 20px;
        font-weight: 600;
    }

    &__heading-image {
        width: 50%;
        float: left;
    }

    &__image {
        max-width: 300px;
    }
}

@media screen and (max-width: 1200px) {
    .c-checkout-complete-header__heading-image {
        text-align: center;
    }

    .c-checkout-complete-header__image {
        width: 200px;
    }
}

@media screen and (max-width: 768px) {
    .c-checkout-complete-header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &__heading {
            order: 2;
            width: 100%;
            padding-left: 0;
            margin: 0 2rem;
            text-align: center;
        }

        &__button {
            width: 100%;
        }

        &__heading-image {
            order: 1;
            margin: 0 2rem 2rem;
            text-align: center;
            width: 100%;
        }

        &__image {
            max-width: 200px;
        }
    }
}
