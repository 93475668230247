
@use 'config' as *;
@use 'utility' as *;

.where-to-spend__item {
    border-radius: $border-radius;
    padding: $spacing-xs;
    font-size: $font-size-base;

    background-color: $colour-light-grey;
    color: $colour-black;

    @include breakpoint(md-plus) {
        font-size: $font-size-large;
        padding: $spacing-xs $spacing-s;
    }

    &--enabled {
        background-color: $colour-peppermint;
        color: $colour-mine-shaft;
    }

    &-icon {
        margin-right: 5px;
        color: $colour-black;
    }
}

