//
// Flash message
// ==========================================

@use 'config' as *;
@use 'utility' as *;

// == Modifiers
//

.alert--no-margin {
    margin: 0;
}
