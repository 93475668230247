@use 'config' as *;
@use 'utility' as *;

$_svg-height: 30px;
$_svg-width: 30px;
$_search-input-height: 72px;

.search-bar__search-input {
    border: 2px solid $colour-light-grey;
    border-radius: 8px 8px;
    width: 100%;
}

.search-bar__btn-container {
    position: relative;
}

input[type=text].search-bar__search-input {
    border: 1px solid $colour-dark-grey;
    height: $_search-input-height;
    outline: none;

    &::placeholder {
        color: $colour-black;
        font-family: $font-open-sans;
        font-size: $font-size-medium;
        font-weight: 700;
    }
}

.search-bar__search-btn {
    background-color: $colour-sodexo-dark-blue;
    border: none;
    border-bottom-right-radius: $spacing-xs;
    border-top-right-radius: $spacing-xs;
    bottom: 0;
    height: $_search-input-height;
    position: absolute;
    right: 0;
    width: $_search-input-height;

    svg {
        fill: $colour-white;
        height: $_svg-height;
        margin-top: $spacing-xs;
        padding: 0;
        width: $_svg-width;
    }
}

.c-autocomplete__list {
    margin-top: 24px;
    border-radius: 8px;
}