@use 'config' as *;
@use 'utility' as *;

$image-height: 184px;
$logo-size-large: 80px;
$logo-size-small: 64px;

.hot-offers__container {
    padding-top: $spacing-l;
    padding-bottom: $spacing-l;
}

.hot-offers__top-section {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: $spacing-s;
}

.hot-offer-carousel {
    //-17px is used so that the drop shadow does not get cut
    margin-left: -17px;
    margin-right: -17px;

    @include breakpoint(sm-neg) {
      margin-left: 0;
      margin-right: -24px;
    }
}

.hot-offers-card {
    @include card;

    display: flex;
    flex-direction: column;
    margin-bottom: $spacing-l;

    &:hover, &:focus {
        color: $colour-black;
    }
}

.hot-offers-card__panel-image {
    height: $image-height;
    width: 100%;
    object-fit: cover;
}

h2.hot-offers__sub-heading {
    margin-bottom: 0;
    color: $colour-black;
    font-family: $font-open-sans;
    font-style: normal;
    font-weight: $semi-bold-font-weight;
}

.hot-offers__view-all {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: $base-font-weight;
    min-height: $button-minimum-height;
    min-width: $button-minimum-width;

    @include breakpoint(sm-neg) {
        margin-top: $spacing-s;
    }
}

.hot-offers__view-all--icon {
    padding: 0 10px;
    height: 12.5px;
 }

.hot-offers__view-all:hover, .hot-offers__view-all:focus, .hot-offers__view-all-link:hover {
    color: $colour-black;
}

.hot-offers__retailer-logo-box {
    display: flex;
    height: $logo-size-small;
    width: $logo-size-small;
    position: absolute;
    right: 20px;
    top: $image-height - $logo-size-small * .5;
    border-radius: $button-border-radius;
    background-color: $colour-white;
    box-shadow: 0 2px 8px 0 $colour-grey-drop-shadow;
    overflow: hidden;

    @include breakpoint(lg-plus) {
        height: $logo-size-large;
        width: $logo-size-large;
        top: $image-height - $logo-size-large * .5;
    }
}

.hot-offers__retailer-logo {
    aspect-ratio: 1/1;
    object-fit: contain;
}

.hot-offers__card-description {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: $spacing-s;

    @include breakpoint(sm-neg) {
        margin-top: $spacing-m;
    }
}

.hot-offers__retailer-name {
    font-size: $font-size-small;
    font-family: $font-open-sans;
    font-weight: $headings-font-weight;
    line-height: 20px;
    padding-right: $logo-size-large;
}

.hot-offers__offer-title {
    text-overflow: ellipsis;
    font-family: $font-open-sans;
    font-size: $font-size-xxl;
    font-style: normal;
    font-weight: $semi-bold-font-weight;
    line-height: 32px;
}

.hot-offers__offer-description {
    @include lineClamp(2);
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: $font-open-sans;
    font-size: $font-size-medium;
    font-style: normal;
    font-weight: $base-font-weight;
    line-height: 28px;
    margin-bottom: $spacing-m;

    @include breakpoint(md-neg) {
        margin-bottom: $spacing-xs;
    }
}

.hot-offers__view-offer {
    margin-top: auto;
    color: $colour-black;
    font-family: $font-open-sans;
    font-size: $font-size-medium;
    font-style: normal;
    font-weight: $semi-bold-font-weight;
    line-height: 100%;
    display: flex;
    align-items: center;

    @include breakpoint(md-neg) {
        display: none;
    }
}

.hot-offers__view-offer-icon {
    padding: 0 10px;
    height: 12.5px;
}
