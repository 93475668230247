//
// File upload widget.
// ==========================================

@use 'config' as *;
@use 'utility' as *;

.c-file-upload-widget {
    border-radius: 5px;
    border: 1px solid #dddddd;
    width: 50%;
}

.c-file-upload-widget__form {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 1em;
}

.c-file-upload-widget__submit-button {
    border-radius: 5px;
    border-width: 0;
    font-size: 1rem;
    padding: 0.5em;
}

@media screen and (max-width:768px) {
    .c-file-upload-widget {
        width: 100%;
    }
}
