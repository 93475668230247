/**
 * This file is read by the styleguide to automatically produce the list of colours names and variables
 * in the General/Colours page.
 *
 * It will assume any line beginning with an "$" is a colour definition line, and will ignore all others.
 */

//
// Main Colours
// ==========================================

// == Basic Colours
//
$colour-white: #ffffff;
$colour-concrete: #f3f3f3;
$colour-lighter-grey: #f8f9fa;
$colour-light-grey: #eeeeee;
$colour-grey: #dbdbdb;
$colour-dark-grey: #797979;
$colour-alternative-gray: #757373;
$colour-darker-grey: #444444;
$colour-black: #000000;
$colour-dark-blue: #1b2149;
$colour-dark-chambray: #3a3a8e;
$colour-light-blue: #00a6d4;
$colour-lighter-blue: #59b7ff;
$colour-sky: #87ceeb;
$colour-light-green: #49b749;
$colour-lighter-green: #51f18f;
$colour-light-yellow: #fad500;
$colour-orange: #ffa500;
$colour-punch: #bd4147;
$colour-endeavour: #005aa9; //sodexo blue
$colour-mine-shaft: #333333; //sodexo text colour
$colour-peppermint: #dff0d8;
$colour-blue: #337ab7;
$colour-deep-blue: #373E8A;

// new colours
$colour-sodexo-black: #221c46;
$colour-sodexo-dark-blue: #2A295C;
$colour-sodexo-blue: #3A3A8E;
$colour-sodexo-green: #00eb5e;
$colour-sodexo-light-blue: #17ccf9;
$colour-sodexo-yellow: #ffdc37;
$colour-sodexo-pink: #ff7375;

$colour-primary: $colour-sodexo-blue;
$colour-secondary: $colour-sodexo-green;

$colour-success-text: #155724;
$colour-success-button: #2e8540;
$colour-success-button-border: #4cae4c;
$colour-success-button-hover: #2e612e;
$colour-success-button-hover-border: #2e612e;
$colour-success-background: #d4edda;
$colour-success-border: #c3e6cb;

$colour-text-colour: $colour-mine-shaft;
$colour-warning-text: #8a6d3b;

$colour-error-text: #A94442;
$colour-error-background: #e4b9b9;

// TODO Rename to follow convention from above
$colour-turquoise: #007399;
$colour-maroon: #a6113f;
$colour-pending: #A24F00;

$colour-sub-link: #015aa9;

// == Component Colours
//
$colour-typography-text: $colour-black;
$link-color: $colour-sub-link;

//saved payment cards colours
$colour-default-card: #3C763D;

//hot-offers
$colour-grey-drop-shadow: #BFBFB8;
$colour-grey-card-background: #f5f5f5;

// forms/input
$input-background-colour: $colour-white;
$input-focus-colour: $colour-endeavour;

// Clients specific colours
// ==========================================
