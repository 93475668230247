@use 'config' as *;
@use 'utility' as *;

.c-user-saved-payment-cards__container {
  margin: 0 auto;

  @include breakpoint(sm-plus) {
    width: $container-sm;
  }

  @include breakpoint(md-plus) {
    width: $container-md;
  }

  @include breakpoint(lg-plus) {
    width: $container-lg;
  }
}

.c-user-saved-payment-card__no-saved-cards {
  margin-top: $spacing-l;
  margin-bottom: $spacing-m;
  min-height: 1px;
  padding: $spacing-m $spacing-l;
  align-items: center;
  align-self: stretch;
  border: 1px solid $colour-light-grey;
}

.c-user-saved-payment-cards__sub-title {
  margin-top: $spacing-l;

    @include breakpoint(sm-neg) {
      margin-left: 10px;
    }
}

.c-user-saved-payment-cards__sub-title-paragraph {
  margin: 10px 0 25px 0;

  @include breakpoint(sm-neg) {
    margin-left: 10px;
  }
}

.c-user-saved-payment-cards__list {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding:0;
  margin-bottom: $spacing-l;
}

.c-user-saved-payment-card__display {
  border: 1px solid $colour-light-grey;
  list-style-type: none;
  padding: 0.8rem;
  margin: 0;

  @include breakpoint(md-plus) {
    padding-left: 20px;
  }
}

.c-user-saved-payment-card__display {
  display: flex;
}

.c-user-saved-payment-card__card-image-section {
  flex: 0 0 100px;
}

.c-user-saved-payment-card__content-section {
  gap: $spacing-xs;

  @include breakpoint(sm-plus) {

      display: flex;
      gap: $spacing-xs;
      flex: 1 0 auto;
      justify-content: space-between;
  }
}

.c-user-saved-payment-card__default-card {
  display: flex;
  flex-direction: row;

  @include breakpoint(xs-neg) {
    margin-bottom: 10px;
  }
}

.c-user-saved-payment-card__card-image {
  width: 82px;
  height: 52px;
}

.c-user-saved-payment-card__default-card-img {
  height: $spacing-s;
  width: $spacing-s;
  margin-top: 4px;
}

.c-user-saved-payment-card__default-card-text {
  color: $colour-default-card;
  margin-left: $spacing-xs;
  font-family: $font-open-sans;
  font-weight: 600;
  font-style: normal;
  line-height: $spacing-m;
}

.c-user-saved-payment-card__remove-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.c-user-saved-payment-card__remove-card-text {
  color: $colour-endeavour;
  background: none;
  border: none;
  outline: none;
  font-weight: 600;
  line-height: $spacing-m;
  font-family: $font-open-sans;
}

.c-user-saved-payment-card__card-expiration-date {
  font-weight: 400;
  font-family: $font-open-sans;
  font-size: $font-size-base;
}

.c-user-saved-payment-card__card-expired {
  color: $colour-punch;
  font-family: $font-open-sans;
  font-size: $font-size-base;
  font-weight: 400;
  line-height: 20px;
}

.c-user-payment-card__make-default {
  color: $colour-black;
  text-decoration: underline;
  font-family: $font-open-sans;
  font-size: $font-size-small;
  font-weight: 600;
}
