//
// Terms and Conditions box.
// ==========================================

@use 'config' as *;
@use 'utility' as *;

//== Block
//
.c-marketing-preferences-form {
    display: flex;
    flex-flow: row wrap;
    flex-direction: column;
    align-items: center;
    padding: 15px;

    &__actions,
    &__form {
        margin-top: 20px;
    }

    @media (max-width: $screen-xs) {
        &__actions {
            display: flex;
            flex-direction: column;
            margin-top: 30px;

            button[type='submit'] {
                margin: 5px 0;
            }
        }

        &__checkbox {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row-reverse;
        }
    }

    @media (min-width: $screen-md) {
        &__container {
            max-width: calc(50% - 1rem);
        }
    }
}
