@use 'config' as *;
@use 'sass:math';

/**
 * Pixel converter function
 *
 * @param {Number} $number with our without unit
 * @return {Number} converted to rem
 *
 * To be used as follows:
 * font-size: pixel-converter(20px);
 */

@function pixel-converter($sizeValue) {
    @return (strip-unit(math.div($sizeValue, $font-size-base))) + rem;
}

/**
 * Remove the unit function
 *
 * @param {Number} $number
 * @return {Number} Unitless number
 *
 * To be used as follows:
 * $value: strip-unit(20px);
 */

@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        //to have 1 of the unit of $number, multiply $number by 0 and then add 1.
        @return math.div($number,($number * 0 + 1));
    }

    @return $number;
}
