/**
 * Displaying
 */

@use 'config' as *;
@use 'utility' as *;

.u-hide {
    display: none;
}

.u-block {
    display: block;
}

.u-flex {
    display: flex;
}

.u-inline-block {
    display: inline-block;
}

.u-inline {
    display: inline;
}

.u-hidden-md-down {
    @include breakpoint(md-neg) {
        display: none;
    }
}

.u-hidden-sm-down {
    @include breakpoint(sm-neg) {
        display: none;
    }
}

.u-hidden-xs-down {
    @include breakpoint(xs-neg) {
        display: none;
    }
}
